import React from 'react'

function Loader() {
  return (
    <div className='fp-container'>
       {/* <img className="fp-loader"
                      alt="..."
                      src={require("../assets/images/loading.gif")}
                    /> */}
      <div className="spinner-border text-primary fp-loader" role="status">
  <span className="sr-only">Loading...</span>
</div>
    </div>
  )
}

export default Loader
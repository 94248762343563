import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardImg,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Input,
  Label,
} from "reactstrap";
import ImageModal from "utils/ImageModal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import { deleteLightNovelComment, getLightNovelById } from "utils/endpoint";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal } from "antd";
import { getAuthorSummaryById } from "utils/endpoint";
import { getUniverseSummaryById } from "utils/endpoint";
import { getIllustratorSummaryById } from "utils/endpoint";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import UniverseModal from "utils/universeModal";
import Checkbox from "@mui/material/Checkbox";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { handleLightNovelBookMark } from "./LightNovelList";
import isPageReload from "utils/enums";
import RatingUserListModal from "utils/ratingUserListModal";
import { useReactToPrint } from "react-to-print";
import LightNovelPreview from "utils/lightNovelPreviewModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function ViewLightNovelPrintSelection() {
  const { state } = useLocation();
  const [lightNovel, setLightNovel] = useState(state.row);
  const [show, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState({});
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState("");
  const [ratingInfo, setRatingInfo] = useState([]);
  const [lightNovelPrintSelections, setLightNovelPrintSelections] = useState({
    en_title: false,
    universe_name: false,
    jp_title: false,
    status_name: false,
    publisher_name: false,
    pre_publication_name: false,
    no_of_volumes: false,
    ongoing: false,
    type_name: false,
    print_rights_in_france_publisher_name: false,
    digital_rights_in_france_publisher_name: false,
    print_rights_in_us_publisher_name: false,
    digital_rights_in_us_publisher_name: false,
    print_rights_in_de_publisher_name: false,
    digital_rights_in_de_publisher_name: false,
    jp_print_run_1: false,
    jp_print_run_2: false,
    jp_print_run_3: false,
    jp_print_run_4: false,
    jp_wikipedia_link: false,
    nautiljon: false,
    jp_amazon_link: false,
    jp_bookwalker_link: false,
    scantrad: false,
    low_sales: false,
    medium_sales: false,
    high_sales: false,
    fr_consumer_price: false,
    summary: false,
    product_pl: false,
    cover_image: false,
    created_at: false,
    authors: false,
    genres: false,
    publics: false,
    illustrators: false,
    rating: false,
    comment: false,
    all_comments: false,
    user_first_name: false,
    user_last_name: false,
  });

  const [lightNovelPreviewData, setLightNovelPreviewData] = useState({});

  const [selectLightNovelkeys,setSelectLightNovelKeys] = useState([
    'en_title','universe_name','jp_title',
    'status_name','publisher_name','pre_publication_name',
    'no_of_volumes','ongoing','type_name','print_rights_in_france_publisher_name',
    'digital_rights_in_france_publisher_name',
    'print_rights_in_us_publisher_name',
    'digital_rights_in_us_publisher_name',
    'print_rights_in_de_publisher_name',
    'digital_rights_in_de_publisher_name',
    'jp_print_run_1','jp_print_run_2','jp_print_run_3','jp_print_run_4',
    'jp_wikipedia_link','nautiljon','jp_amazon_link','jp_bookwalker_link',
    'scantrad','low_sales','medium_sales','high_sales','fr_consumer_price',
    'summary','product_pl','cover_image','created_at',
    'authors','genres','publics','illustrators','rating','comment'
  ])

  const componentRef = useRef();
  const navigate = useNavigate();

  const { confirm } = Modal;
  const lightNovelId = state.row.id;

  const handleDeleteLightNovelComment = async () => {
    try {
      setCurrentComment("");
      const response = await deleteLightNovelComment(lightNovelId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      setCurrentComment(state.row.comment);
      toast.error(`${error.message}`);
    }
  };

  const fetchUniverseDetail = async (unvierseId) => {
    setOpenUniverseModel(true);
    const res = await getUniverseSummaryById(unvierseId);
    if (res && res.isSuccess) {
      setUniverseSummaryData(res.data);
    }
  };

  const fetchAuthorsDetail = async (authorId) => {
    setOpenAuthorModel(true);
    const res = await getAuthorSummaryById(authorId);
    if (res && res.isSuccess) {
      setAuthorSummaryData(res.data);
    }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
    setOpenIllustratorModel(true);
    const res = await getIllustratorSummaryById(illustratorId);
    if (res && res.isSuccess) {
      setIllustratorSummaryData(res.data);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteLightNovelComment();
      },
    });
  };

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
      setCurrentLoggedInUser(loginData.user);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getLightNovelById({
          lightNovel_id: lightNovelId,
        });
        if (response && response.isSuccess) {
          setCurrentComment(response.data.comment);
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  useEffect(() => {
    setLightNovel(state.row);
  }, [state.row]);

  const handleOnChangePrintSelections = async (e, key) => {
    const isChecked = e.target.checked;

    if (isChecked && key) {
      lightNovelPrintSelections[key] = isChecked;
    }

    if (!isChecked && key) {
      lightNovelPrintSelections[key] = isChecked;
    }
  };

  const handlePrint = useReactToPrint({
    documentTitle:
      lightNovel.en_title.toLowerCase() +
      moment().format("YYYY-MM-DD hh:mm:ss"),
    content: () => componentRef.current,
  });

  const handleLightNovelPrintSelectionalPreview = async () => {
    let lightNovelSelectedData = {};
    for (const ln in lightNovel) {
      if (lightNovelPrintSelections[ln]) {
        lightNovelSelectedData[ln] = lightNovel[ln];
      }
    }

    if (lightNovelPrintSelections.comment) {
      lightNovelSelectedData.all_comments = lightNovel["all_comments"];
      lightNovelSelectedData.user_first_name = currentLoggedInUser.first_name;
      lightNovelSelectedData.user_last_name = currentLoggedInUser.last_name;
    }
    if (lightNovelPrintSelections.rating) {
      lightNovelSelectedData.rating_one_counts =
        lightNovel["rating_one_counts"];
      lightNovelSelectedData.rating_two_counts =
        lightNovel["rating_two_counts"];
      lightNovelSelectedData.rating_three_counts =
        lightNovel["rating_three_counts"];
    }

    if (Object.keys(lightNovelSelectedData).length < 1) {
      toast.error("Please select the content you want to print.");
      return;
    }

    setLightNovelPreviewData(lightNovelSelectedData);
  };

  const handleSelectAllCheckBox = async() => {
    for (const key of selectLightNovelkeys) {
      const targetCheckBox = document.getElementById(`${key}`);
      if(targetCheckBox && targetCheckBox !== null || undefined) targetCheckBox.checked = true;
      lightNovelPrintSelections[key] = true;
    }

  }

  const handleUnselectAllCheckBox = async() => {
    for (const key of selectLightNovelkeys) {
      const targetCheckBox = document.getElementById(`${key}`);
    if(targetCheckBox && targetCheckBox !== null || undefined) targetCheckBox.checked = false;
      lightNovelPrintSelections[key] = false;
    }
  }

  useEffect(() => {
    const isLength = Object.keys(lightNovelPreviewData).length;
    if (isLength > 0) {
      handlePrint();
    }
  }, [lightNovelPreviewData]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="5">
                      <h1 className="mb-0">
                        {" "}
                        Light Novel Detail{" "}
                        <Checkbox
                          {...label}
                          icon={<BookmarkBorderIcon />}
                          checkedIcon={<BookmarkIcon />}
                          defaultChecked={
                            lightNovel.bookmark_id !== null ? true : false
                          }
                          onClick={(e) =>
                            handleLightNovelBookMark({
                              isChecked: e.target.checked,
                              lightNovelId: lightNovel?.id,
                            })
                          }
                        />
                      </h1>
                    </Col>
                    <Col lg="7" className="add_btn text-right">
                      <Button
                        color="primary"
                        onClick={handleSelectAllCheckBox}
                      >
                        Select All
                      </Button>

                      <Button
                        color="primary"
                        onClick={handleUnselectAllCheckBox}
                      >
                        Deselect All
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleLightNovelPrintSelectionalPreview}
                      >
                        Print Preview
                      </Button>
                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <span className="me-2">Back</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row className="mb-3">
                    {lightNovel?.en_title && (
                      <>
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="en_title"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "en_title")
                            }
                          />
                          <Label>EN Title</Label>
                        </Col>
                        <Col lg="8">
                          <div className="text-dark h2">
                            {lightNovel.en_title}
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className="mt-1">
                    {lightNovel?.universe_name ? (
                      <>
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="universe_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "universe_name")
                            }
                          />
                          <Label>Universe Name</Label>
                        </Col>
                        <Col lg="8">
                          <span
                            className="primary-text-color"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              fetchUniverseDetail(lightNovel.universe_id)
                            }
                          >
                            {lightNovel?.universe_name}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {lightNovel?.jp_title ? (
                      <>
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_title"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_title")
                            }
                          />
                          <Label>JP Title</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_title}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {lightNovel?.status_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="status_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "status_name")
                            }
                          />
                          <Label>Status</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.status_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.publisher_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "publisher_name")
                            }
                          />
                          <Label>JP publisher</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  {lightNovel?.pre_publication_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="pre_publication_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "pre_publication_name"
                              )
                            }
                          />
                          <Label>Pre Publication</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.pre_publication_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.no_of_volumes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="no_of_volumes"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "no_of_volumes")
                            }
                          />
                          <Label>No. of vol</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.no_of_volumes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.ongoing ||
                  (!lightNovel?.ongoing && lightNovel?.ongoing !== null) ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="ongoing"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "ongoing")
                            }
                          />
                          <Label>Ongoing</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel.ongoing === true ? "Yes" : "No"}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.type_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="type_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "type_name")
                            }
                          />
                          <Label>Type</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.type_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="print_rights_in_france_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "print_rights_in_france_publisher_name"
                              )
                            }
                          />
                          <Label>Print Rights In France</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_france_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_france ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="digital_rights_in_france_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "digital_rights_in_france_publisher_name"
                              )
                            }
                          />
                          <Label>Digital Rights In France</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {
                              lightNovel?.digital_rights_in_france_publisher_name
                            }
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="print_rights_in_us_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "print_rights_in_us_publisher_name"
                              )
                            }
                          />
                          <Label>Print Rights In US</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_us ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="digital_rights_in_us_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "digital_rights_in_us_publisher_name"
                              )
                            }
                          />
                          <Label>Digital Rights In US</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.digital_rights_in_us_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.print_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="print_rights_in_de_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "print_rights_in_de_publisher_name"
                              )
                            }
                          />
                          <Label>Print Rights In DE</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.print_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.digital_rights_in_de ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="digital_rights_in_de_publisher_name"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "digital_rights_in_de_publisher_name"
                              )
                            }
                          />
                          <Label>Digital Rights In DE</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.digital_rights_in_de_publisher_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_1 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_print_run_1"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_print_run_1")
                            }
                          />
                          <Label>JP Print Run 1</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_1}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_2 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_print_run_2"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_print_run_2")
                            }
                          />
                          <Label>JP Print Run 2</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_2}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_3 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_print_run_3"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_print_run_3")
                            }
                          />
                          <Label>JP Print Run 3</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_3}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_print_run_4 ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_print_run_4"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_print_run_4")
                            }
                          />
                          <Label>JP Print Run 4</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.jp_print_run_4}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_wikipedia_link"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "jp_wikipedia_link"
                              )
                            }
                          />
                          <Label>JP Wikipedia</Label>
                        </Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_wikipedia_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_wikipedia_link?.length > 50
                                ? `${lightNovel?.jp_wikipedia_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_wikipedia_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.nautiljon ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="nautiljon"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "nautiljon")
                            }
                          />
                          <Label>Nautiljon</Label>
                        </Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.nautiljon}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.nautiljon?.length > 50
                                ? `${lightNovel?.nautiljon.slice(0, 40)}...`
                                : lightNovel?.nautiljon}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_amazon_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_amazon_link"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "jp_amazon_link")
                            }
                          />
                          <Label>JP Amazon</Label>
                        </Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_amazon_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_amazon_link?.length > 50
                                ? `${lightNovel?.jp_amazon_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_amazon_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.jp_bookwalker_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="jp_bookwalker_link"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "jp_bookwalker_link"
                              )
                            }
                          />
                          <Label>JP BookWalker</Label>
                        </Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.jp_bookwalker_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.jp_bookwalker_link?.length > 50
                                ? `${lightNovel?.jp_bookwalker_link.slice(
                                    0,
                                    40
                                  )}...`
                                : lightNovel?.jp_bookwalker_link}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.scantrad ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="scantrad"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "scantrad")
                            }
                          />
                          <Label>ScanTrad</Label>
                        </Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={lightNovel?.scantrad}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {lightNovel?.scantrad?.length > 50
                                ? `${lightNovel?.scantrad.slice(0, 40)}...`
                                : lightNovel?.scantrad}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-light-novel-low-sale" ? (
                        <>
                          {lightNovel?.low_sales ? (
                            <Row className="mt-1">
                              <Col
                                lg="4"
                                className="d-flex align-items-center px-5"
                              >
                                <Input
                                  id="low_sales"
                                  type="checkbox"
                                  className="mb-3"
                                  onChange={(e) =>
                                    handleOnChangePrintSelections(
                                      e,
                                      "low_sales"
                                    )
                                  }
                                />
                                <Label>Low Sales</Label>
                              </Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovel?.low_sales}
                                </span>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}

                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-light-novel-medium-sale" ? (
                        <>
                          {lightNovel?.medium_sales ? (
                            <Row className="mt-1">
                              <Col
                                lg="4"
                                className="d-flex align-items-center px-5"
                              >
                                <Input
                                  id="medium_sales"
                                  type="checkbox"
                                  className="mb-3"
                                  onChange={(e) =>
                                    handleOnChangePrintSelections(
                                      e,
                                      "medium_sales"
                                    )
                                  }
                                />
                                <Label>Medium Sales</Label>
                              </Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovel?.medium_sales}
                                </span>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}

                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-light-novel-high-sale" ? (
                        <>
                          {lightNovel?.high_sales ? (
                            <Row className="mt-1">
                              <Col
                                lg="4"
                                className="d-flex align-items-center px-5"
                              >
                                <Input
                                  id="high_sales"
                                  type="checkbox"
                                  className="mb-3"
                                  onChange={(e) =>
                                    handleOnChangePrintSelections(
                                      e,
                                      "high_sales"
                                    )
                                  }
                                />
                                <Label>High Sales</Label>
                              </Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {lightNovel?.high_sales}
                                </span>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}

                  {lightNovel?.fr_consumer_price ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="fr_consumer_price"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(
                                e,
                                "fr_consumer_price"
                              )
                            }
                          />
                          <Label>FR Consumer Price</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel?.fr_consumer_price}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {lightNovel?.summary ? (
                    <>
                      <Row className="mt-21">
                        {lightNovel?.summary ? (
                          <>
                            <Col
                              lg="4"
                              className="d-flex align-items-center px-5"
                            >
                              <Input
                                id="summary"
                                type="checkbox"
                                className="mb-3"
                                onChange={(e) =>
                                  handleOnChangePrintSelections(e, "summary")
                                }
                              />
                              <Label>Summary</Label>
                            </Col>
                            <Col lg="8">
                              <span className="text-dark">
                                {lightNovel?.summary}
                              </span>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {permissions &&
                    permissions.map((permission) =>
                      permission === "view-light-novel-product-pl" ? (
                        <>
                          {lightNovel?.product_pl ? (
                            <>
                              <Row className="mt-1">
                                <Col
                                  lg="4"
                                  className="d-flex align-items-center px-5"
                                >
                                  <Input
                                    id="product_pl"
                                    type="checkbox"
                                    className="mb-3"
                                    onChange={(e) =>
                                      handleOnChangePrintSelections(
                                        e,
                                        "product_pl"
                                      )
                                    }
                                  />
                                  <Label>Product P&L</Label>
                                </Col>
                                <Col lg="8">
                                  <span className="link primary-text-color text-wrap">
                                    <a
                                      href={lightNovel?.product_pl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {lightNovel?.product_pl?.length > 50
                                        ? `${lightNovel?.product_pl.slice(
                                            0,
                                            40
                                          )}...`
                                        : lightNovel?.product_pl}
                                    </a>
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                    )}

                  {lightNovel?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4" className="d-flex align-items-center px-5">
                          <Input
                            id="created_at"
                            type="checkbox"
                            className="mb-3"
                            onChange={(e) =>
                              handleOnChangePrintSelections(e, "created_at")
                            }
                          />
                          <Label>CreatedAt</Label>
                        </Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {lightNovel.created_at
                              ? moment(lightNovel.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" className="columns"></Col>
            <Col lg="3">
              <Card className="text-right shadow m-4 view_cards">
                <CardBody>
                  {lightNovel?.cover_image ? (
                    <>
                      <Input
                        id="cover_image"
                        type="checkbox"
                        className="mb-5"
                        onChange={(e) =>
                          handleOnChangePrintSelections(e, "cover_image")
                        }
                      />
                      <CardImg
                        src={lightNovel?.cover_image}
                        alt="Upload"
                        className="overflow-hidden"
                        onClick={async () => {
                          setShowModal(true);
                          setImage(lightNovel.cover_image);
                        }}
                      />
                    </>
                  ) : (
                    <CardImg
                      src={require("../../../assets/images/no-image.png")}
                      alt="Upload"
                      className=" overflow-hidden"
                      onClick={async () => {
                        setShowModal(true);
                        setImage(
                          require("../../../assets/images/no-image.png")
                        );
                      }}
                    />
                  )}
                </CardBody>
              </Card>

              <Card className="text-left shadow m-4 view_cards">
                <CardBody>
                  <Input
                    id="rating"
                    type="checkbox"
                    className="mb-3"
                    onChange={(e) => handleOnChangePrintSelections(e, "rating")}
                  />
                  <h4>Ratings</h4>

                  <div className="d-inline-flex">
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`}
                        alt="rating-1"
                        style={{ width: "30%" }}
                      />
                      <span
                        className={`m-1 ${
                          lightNovel?.rating_one_counts !== "0"
                            ? "primary-text-color cursor-pointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (lightNovel?.rating_one_counts !== "0") {
                            setRatingType(1);
                            setRatingInfo(lightNovel);
                            setOpenUserRatingListModel(true);
                          }
                        }}
                      >
                        {lightNovel?.rating_one_counts || 0}
                      </span>
                    </div>

                    <div className="pe-auto">
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`}
                        alt="rating-2"
                        style={{ width: "30%" }}
                      />
                      <span
                        className={`m-1 ${
                          lightNovel?.rating_two_counts !== "0"
                            ? "primary-text-color cursor-pointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (lightNovel?.rating_two_counts !== "0") {
                            setRatingType(2);
                            setRatingInfo(lightNovel);
                            setOpenUserRatingListModel(true);
                          }
                        }}
                      >
                        {lightNovel?.rating_two_counts || 0}
                      </span>
                    </div>

                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`}
                        alt="rating-3"
                        style={{ width: "27%" }}
                      />
                      <span
                        className={`m-2 ${
                          lightNovel?.rating_three_counts !== "0"
                            ? "primary-text-color cursor-pointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (lightNovel?.rating_three_counts !== "0") {
                            setRatingType(3);
                            setRatingInfo(lightNovel);
                            setOpenUserRatingListModel(true);
                          }
                        }}
                      >
                        {lightNovel?.rating_three_counts || 0}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {lightNovel?.authors.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <Input
                      id="authors"
                      type="checkbox"
                      className="mb-3"
                      onChange={(e) =>
                        handleOnChangePrintSelections(e, "authors")
                      }
                    />
                    <h3 className="fw-bolder dot_border">Authors</h3>
                    <Col>
                      {lightNovel.authors.map((author, index) => (
                        <li
                          className="primary-text-color"
                          style={{ cursor: "pointer" }}
                          onClick={() => fetchAuthorsDetail(author.id)}
                          key={index}
                        >
                          {author.name}
                        </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.authors.length ? <Col lg="2"></Col> : ""}
            {lightNovel?.genres.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <Input
                      id="genres"
                      type="checkbox"
                      className="mb-3"
                      onChange={(e) =>
                        handleOnChangePrintSelections(e, "genres")
                      }
                    />
                    <h3 className="fw-bolder dot_border">Genre</h3>
                    <Col>
                      {lightNovel.genres.map((genre, index) => (
                        <li key={index}>{genre.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.illustrators.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <Input
                      id="illustrators"
                      type="checkbox"
                      className="mb-3"
                      onChange={(e) =>
                        handleOnChangePrintSelections(e, "illustrators")
                      }
                    />
                    <h3 className="fw-bolder dot_border">Illustrator</h3>
                    <Col>
                      {lightNovel.illustrators.map((illustrators, index) => (
                        <li
                          className="primary-text-color"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            fetchIllustratorDetail(illustrators.id)
                          }
                          key={index}
                        >
                          {illustrators.name}
                        </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {lightNovel?.illustrators.length ? <Col lg="2"></Col> : ""}

            {lightNovel?.publics.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <Input
                      id="publics"
                      type="checkbox"
                      className="mb-3"
                      onChange={(e) =>
                        handleOnChangePrintSelections(e, "publics")
                      }
                    />
                    <h3 className="fw-bolder dot_border">Public</h3>
                    <Col>
                      {lightNovel.publics.map((publicsD, index) => (
                        <li key={index}>{publicsD.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col lg="12">
              <Card className="me-2 shadow m-4 ">
                <CardBody>
                  {currentComment || lightNovel.all_comments.length > 0 ? (
                    <Input
                      id="comment"
                      type="checkbox"
                      className="mb-3"
                      onChange={(e) =>
                        handleOnChangePrintSelections(e, "comment")
                      }
                    />
                  ) : (
                    ""
                  )}

                  <h3 className="fw-bolder dot_border">Comments</h3>
                  {currentComment || lightNovel.all_comments ? (
                    <>
                      <Row>
                        {currentComment ? (
                          <Col lg="3">
                            <Card className=" shadow mt-2 ">
                              <CardHeader className="h4">
                                {currentLoggedInUser.first_name}{" "}
                                {currentLoggedInUser.last_name}
                                <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-7"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                  color="#e62a2e"
                                />
                              </CardHeader>
                              <CardBody>{currentComment}</CardBody>
                            </Card>
                          </Col>
                        ) : (
                          ""
                        )}
                        {lightNovel?.all_comments?.length > 0
                          ? lightNovel.all_comments.map((x) => (
                              <>
                                <Col lg="3">
                                  <Card className="shadow mt-2 ">
                                    <CardHeader className="h4">
                                      {x.first_name} {x.last_name}
                                    </CardHeader>
                                    <CardBody>{x.comment}</CardBody>
                                  </Card>
                                </Col>
                              </>
                            ))
                          : ""}
                      </Row>
                    </>
                  ) : (
                    "No Comments"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ImageModal
            show={show}
            image={image}
            onClose={() => {
              setShowModal(false);
            }}
          />
          <UniverseModal
            openUniverseModel={openUniverseModel}
            setOpenUniverseModel={setOpenUniverseModel}
            universeSummaryData={universeSummaryData}
            isPageReload={isPageReload.LIGHT_NOVEL}
            currentLightNovelId={lightNovelId}
            isLinkFunctionalityActive={true}
          />
          <AuthorModal
            openAuthorModel={openAuthorModel}
            setOpenAuthorModel={setOpenAuthorModel}
            authorSummaryData={authorSummaryData}
            isPageReload={isPageReload.LIGHT_NOVEL}
            currentLightNovelId={lightNovelId}
            isLinkFunctionalityActive={true}
          />
          <IllustratorModal
            openIllustratorModel={openIllustratorModel}
            setOpenIllustratorModel={setOpenIllustratorModel}
            illustratorSummaryData={illustratorSummaryData}
            isPageReload={isPageReload.LIGHT_NOVEL}
            currentLightNovelId={lightNovelId}
            isLinkFunctionalityActive={true}
          />
          <RatingUserListModal
            openUserRatingListModel={openUserRatingListModel}
            setOpenUserRatingListModel={setOpenUserRatingListModel}
            ratingInfo={ratingInfo}
            ratingType={ratingType}
            title="Light Novel Rating Details"
          />
          <div style={{ display: "none" }}>
            <LightNovelPreview
              ref={componentRef}
              lightNovelPreviewData={lightNovelPreviewData}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

export default ViewLightNovelPrintSelection;

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: JSON.parse(localStorage.getItem('User')),
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('User', JSON.stringify(action.payload));
        },
    },
})
export const { setAuth } = authSlice.actions
export default authSlice.reducer
import Header from "components/Headers/Header";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { get_universes_list } from "utils/Commen";
import { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { updateAnime } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { deleteAnimeComment } from "utils/endpoint";
import { Modal } from "antd";
import { getAnimeById } from "utils/endpoint";
import { RiDeleteBin6Line } from "react-icons/ri";
import CreateUniverses from "utils/CreateUniverses";
import useSearchParams from "utils/useSearchParams";
import { CSVLink } from "react-csv";
import moment from "moment";
import { genrateCsvFileName } from "utils/Commen";


const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

export const headers = [
  { label: "EN Title", key: "en_title" },
  { label: "Universe Name", key: "universe_name" },
  { label: "Licensor", key: "licensor" },
  { label: "No Of Episodes", key: "no_of_episodes" },
  { label: "Vod In France", key: "vod_in_france" },
  { label: "Vod Link", key: "vod_link" },
  { label: "CreatedAt", key: "created_at" },
];

export const getData = (data) => {
  const animeData = [{
    en_title:data?.en_title,
    universe_name:data?.universe_name,
    licensor:data?.licensor,
    no_of_episodes:data?.no_of_episodes,
    vod_in_france:data?.vod_in_france,
    vod_link:data?.vod_link,
    created_at:moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")
  }];

  return animeData
}

const UpdateAnime = () => {
  const { state } = useLocation();
  const [animeQueryParams ,setAnimeQueryParams] = useSearchParams();
  const currentPage = animeQueryParams.currentPage;
  const enTitle = animeQueryParams.enTitle;
  const fromDate = animeQueryParams.fromDate;
  const toDate = animeQueryParams.toDate;
  const userIdString = animeQueryParams.userIdString;
  const universeIdString = animeQueryParams.universeIdString;
  const is_active = animeQueryParams.is_active;
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [show, setShowModal] = useState(false);
  const [anime] = useState(state.row);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [csvData,setCsvData] = useState([])

  const params = useParams();
  const { id } = params;
  const inputRef = useRef(null);

  const loginData = localStorage.getItem("User");
  const loginUser = JSON.parse(loginData);
  
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{id:'',name:'None'},...allUniverses]);
  };

  const { confirm } = Modal;
  const animeId = state.row.id;

  const handleDeleteAnimeComment = async () => {
    try {
      inputRef.current.value = '';
      const response = await deleteAnimeComment(animeId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteAnimeComment();
      },
    });
  };

  const handleSearchParams = (updatedObj) => {
    let url = `/admin/anime/anime-list?id=${id}`;
    if(currentPage) url+=`&page=${currentPage}`;
    if(enTitle) url+= `&en_title=${updatedObj?.en_title || enTitle}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(universeIdString) url += `&universe_id=${updatedObj?.universe_id || universeIdString}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    if(userIdString) url+= `&user_id=${userIdString}`    
    return url;
  }


  useEffect(() => {
    getUniverseList();
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAnimeById({
          anime_id: animeId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  useEffect(()=>{
    const data = getData(anime)
    setCsvData(data)
  },[])

 
  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: anime?.en_title,
        universe_id: anime?.universe_id ?? "",
        universe_name: anime?.universe_name ?? "None",
        licensor: anime?.licensor,
        no_of_episodes: anime?.no_of_episodes,
        vod_in_france: anime?.vod_in_france,
        vod_link: anime?.vod_link,
        comment: currentComment,
        is_active: anime?.is_active,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
          en_title: values.en_title,
          universe_id: values.universe_id ? values.universe_id :null,
          licensor: values.licensor,
          no_of_episodes: values.no_of_episodes,
          vod_in_france: values.vod_in_france,
          vod_link: values.vod_link,
          comment: values.comment,
          is_active: values.is_active,
        };
        const result = await updateAnime(id, updateData);
        if (result?.isSuccess) {
          toast.success("Anime Updated successfully");
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams(updateData)
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Anime</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                        <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`anime_${anime.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Export to CSV
                        </CSVLink>

                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams()
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title ({ anime?.update_history?.en_title})
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex justify-content-between">
                            <label className="form-control-label">
                              Universe ({anime?.update_history?.universe_id})
                            </label>
                            <Button
                              className="btn-sm ml-10 text-right"
                              color="primary"
                              onClick={async () => {
                                setShowModal(true);
                              }}>
                                +
                            </Button>
                            </div>
                            <Select
                              defaultValue={{
                                label: values.universe_name,
                                value: values.universe_id,
                              }}
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              classNamePrefix="my-react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };  
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />                        
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              Licensor ({ anime?.update_history?.licensor})
                            </label>
                            <Input
                              className="form-control"
                              id="licensor"
                              name="licensor"
                              placeholder="Licensor"
                              type="text"
                              value={values.licensor === null ? '' : values.licensor }
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              No. Off Episodes ({ anime?.update_history?.no_of_episodes})
                            </label>
                            <Input
                              className="form-control"
                              id="no_of_episodes"
                              name="no_of_episodes"
                              placeholder="No. Off Episodes"
                              type="number"
                              onChange={handleChange}
                              value={values.no_of_episodes === null ? '' : values.no_of_episodes }

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              VoD in France ({ anime?.update_history?.vod_in_france})
                            </label>
                            <Input
                              className="form-control"
                              id="vod_in_france"
                              name="vod_in_france"
                              placeholder="VoD in France"
                              type="text"
                              value={values.vod_in_france === null ? '' : values.vod_in_france }

                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              Link VoD ({ anime?.update_history?.vod_link})
                            </label>
                            <Input
                              className="form-control"
                              id="vod_link"
                              name="vod_link"
                              placeholder="Link VoD"
                              type="text"
                              value={values.vod_link === null ? '' : values.vod_link }

                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                          <label className="form-control-label">
                              Comments ({loginUser.user?.first_name}{" "}
                              {loginUser.user?.last_name})
                              {values.comment &&  <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-19"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                /> }
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={(event) => {
                                if(event.target.value.trim() !== ''){
                                  setFieldValue(
                                    "comment",
                                    event.target.value
                                  );
                                }else {
                                  setFieldValue('comment','');
                                }
                              }}
                              rows={2}
                              ref={inputRef}
                              cols={40}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <label className="form-control-label">
                            All Comments
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        {anime?.all_comments?.length > 0 ? (
                          anime.all_comments.map((x) => (
                            <>
                              <Col lg="3">
                                <li className="fs-5 text-dark">
                                  {x.first_name} {x.last_name}
                                </li>

                                <textarea
                                  className="form-control"
                                  type="text"
                                  name="all_comments"
                                  value={x.comment}
                                  onChange={handleChange}
                                  rows={2}
                                  disabled
                                  cols={40}></textarea>
                              </Col>
                            </>
                          ))
                        ) : (
                          <>
                            <div>No Comments</div>
                          </>
                        )}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Anime
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <CreateUniverses
                show={show}
                mediaType={"ANIME"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default UpdateAnime;

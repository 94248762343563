import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Suspense } from "react";
import './App.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
export const App = () => {
  const loginData = localStorage.getItem("User");
  return (
    <Suspense fallback={loading}>
      <BrowserRouter fallback={loading}>
        <Routes>
          {loginData ? (
            <>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route
                path="*"
                element={<Navigate to="/admin/dashboard" replace />}
              />
            </>
          ) : (
            <>
              <Route path="/auth/*" element={<AuthLayout />} />
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
              
            </>
          )}
        </Routes>
      </BrowserRouter>
    </Suspense>
   
  );
};

import Header from "components/Headers/Header";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { get_universes_list } from "utils/Commen";
import { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { createVideoGame } from "utils/endpoint";
import { get_publisher_list } from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import CreateUniverses from "utils/CreateUniverses";

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

const CreateVideoGame = () => {
  const [show, setShowModal] = useState(false);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [frPublisherList, setFrPublisherList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{ id: "", name: "None" }, ...allUniverses]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const jpPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'JP')
    const frPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'FR')
    setPublisherList([{ id: "", name: "None" }, ...jpPublisherData]);
    setFrPublisherList([{ id: "", name: "None" }, ...frPublisherData]);
  };
  useEffect(() => {
    getUniverseList();
    getPublisherList();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: "",
        universe_id: "",
        jp_publisher_id: "",
        jp_release_date: "",
        french_publisher_id: "",
        french_release_date: "",
        link: "",
        comment: "",
        is_active: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader();
        const $inputData = {
          en_title: values.en_title,
          universe_id: values.universe_id,
          jp_publisher_id: values.jp_publisher_id,
          jp_release_date: values.jp_release_date,
          french_publisher_id: values.french_publisher_id,
          french_release_date: values.french_release_date,
          link: values.link,
          comment: values.comment,
          is_active: values.is_active,
        };

        const finalObj = {};

        Object.keys($inputData).forEach(function (key) {
          if (typeof $inputData[key] === "string" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
          if (
            typeof $inputData[key] === "object" &&
            $inputData[key].length > 0
          ) {
            finalObj[key] = $inputData[key];
          }
          if (typeof $inputData[key] === "boolean" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
        });
        finalObj.universe_id = finalObj?.universe_id ? finalObj?.universe_id : null;
        finalObj.jp_publisher_id = finalObj?.jp_publisher_id  ? finalObj?.jp_publisher_id : null;
        finalObj.french_publisher_id = finalObj?.french_publisher_id ? finalObj?.french_publisher_id : null;
        
        const result = await createVideoGame(finalObj);
        if (result?.isSuccess) {
          toast.success("VideoGame created successfully");
          setLoading(false);
          hideLoader();
          resetForm();
          navigate("/admin/videoGame/videoGame-list");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader();

          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Create Video Game</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/videoGame/videoGame-list");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                          <Row className="form-control-label">
                              <Col lg="6">
                                <span className="">Universe</span>
                              </Col>

                              <Col lg="6" className="add_btn text-right">
                                <Button
                                  className="btn-sm ml-10 text-right"
                                  color="primary"
                                  onClick={async () => {
                                    setShowModal(true);
                                  }}>
                                  +
                                </Button>
                              </Col>
                            </Row>
                            <Select
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Publisher
                            </label>
                            <Select
                              name="jp_publisher_id"
                              id="jp_publisher_id"
                              className="react-select"
                              options={Array.from(publisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("jp_publisher_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Release Date
                            </label>
                            <Input
                              className="form-control"
                              id="jp_release_date"
                              name="jp_release_date"
                              type="date"
                              value={values.jp_release_date}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              French publisher
                            </label>
                            <Select
                              name="french_publisher_id"
                              id="french_publisher_id"
                              className="react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue(
                                  "french_publisher_id",
                                  event.value
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">
                              French Release Date
                            </label>
                            <Input
                              className="form-control"
                              id="french_release_date"
                              name="french_release_date"
                              type="date"
                              value={values.french_release_date}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">Link</label>
                            <Input
                              className="form-control"
                              id="link"
                              name="link"
                              placeholder="Link"
                              type="text"
                              value={values.link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className=""
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("is_active", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Comments
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={handleChange}
                              rows={2}
                              cols={40}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Create Video Game
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <CreateUniverses
                show={show}
                mediaType={"VIDEO_GAME"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default CreateVideoGame;

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  Button,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { ErrorMessage, Formik } from "formik";
import clsx from "clsx";
import * as yup from "yup";
import { changePassword } from "./endpoint";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  current_password: yup.string().required("Old password is required"),
  new_password: yup.string().required("New password is required"),
  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
function ChangePasswordModal({ show, onClose }) {
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <Modal isOpen={show} toggle={onClose} className="modal-dialog-centered ">
        <Formik
          initialValues={{
            
            current_password: "",
            new_password: "",
            confirm_password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const $inputData = {
              current_password: values.current_password,
              new_password: values.new_password,
              confirm_password: values.confirm_password,
            };
            const result = await changePassword( $inputData);
            if (result?.isSuccess) {
              toast.success("Password updated successfully");
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              toast.error(result?.message);
            }
          }}>
          {({ handleChange, handleSubmit, values, errors, touched }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent d-flex justify-content-between">
                  <div className="form-control-label">Change Password</div>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => onClose()}>
                    <span aria-hidden={true}>×</span>
                  </button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label required">
                        Current Password
                        </label>
                        <Input
                         className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.current_password) && touched.current_password,
                        })}
                          id="current_password"
                          name="current_password"
                          placeholder="Current Password"
                          type="password"
                          value={values.current_password}
                      onChange={handleChange}
                        />
                         <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="current_password" />
                    </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label required">
                          New Password
                        </label>
                        <Input
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.new_password) && touched.new_password,
                        })}
                          id="new_password"
                          name="new_password"
                          placeholder="New Password"
                          type="password"
                          value={values.new_password}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="new_password" />
                    </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label required">
                          Confirm Password
                        </label>
                        <Input
                         className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.confirm_password) &&
                            touched.confirm_password,
                        })}
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          type="password"
                          value={values.confirm_password}
                          onChange={handleChange}
                        />
                         <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="confirm_password" />
                    </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col lg="12">
                        <Button
                          type="submit"
                          className="my-3 w-100"
                          color="primary">
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}>
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!loading && (
                            <span className="indicator-label">
                             Change Password
                            </span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                </CardBody>
              </Card>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;

import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import Header from "components/Headers/Header";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const loginData = JSON.parse(localStorage.getItem('User'));

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    if(routes?.key !== null){
      return routes?.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
             <Route path={prop.path} element={prop.component} key={key}  />
          );
        } else {
          return null;
        }
      });
    }
    return;
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  const isHeaderVisible = location?.pathname !== "/admin/dashboard";
  const filteredRoutes = loginData ? routes.filter((x) => loginData?.permissions.includes(x?.slug) || x?.slug === "dashboard") : <Navigate to="/admin/login" replace />;
  return (
    <>
      <Sidebar
        {...props}
        routes={filteredRoutes}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
        />
         {isHeaderVisible ?
          <Header />
          : null}
        <Routes>
          {getRoutes(filteredRoutes)}
          <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
        </Routes>
        <Container fluid>
          {/* <AdminFooter /> */}
        </Container>
      </div>
    </>
  );
};

export default Admin;

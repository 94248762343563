import React from "react";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "App";
import { store } from './redux/store'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

// import AdminLayout from "layouts/Admin.js";
// import AuthLayout from "layouts/Auth.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
);

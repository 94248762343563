import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAuthorSummaryById, getLightNovelById, getMangaById, getUniverseSummaryById, getWebtoonById } from "./endpoint";
import { toast } from "react-toastify";
import pageReloadOptions from "./enums";

export default function UniverseModal({
  openUniverseModel,
  setOpenUniverseModel,
  universeSummaryData,
  currentMangaId,
  currentWebtoonId,
  currentLightNovelId,
  currentAuthorId,
  isPageReload,
  isLinkFunctionalityActive = false
}) {

  const navigate = useNavigate()

  const handleRedirectToViewMangaPage = async (manga_id) => {
    try {
      const res = await getMangaById({manga_id});
      let url = `/admin/manga/view-manga/${manga_id}?redirect=1`

      if(isPageReload === pageReloadOptions.MANGA){
        setOpenUniverseModel(false);
        localStorage.setItem('is_same_manga_view_page',true);
        url = `/admin/manga/view-manga/${manga_id}`
      }

      navigate(url,{
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  const handleRedirectToViewLightNovelPage = async (lightNovel_id) => {
    try {
      const res = await getLightNovelById({lightNovel_id});
      let url = `/admin/lightNovel/view-Light-Novel/${lightNovel_id}?redirect=1`

      if(isPageReload === pageReloadOptions.LIGHT_NOVEL){
        setOpenUniverseModel(false);
        localStorage.setItem('is_same_light_novel_view_page',true);
        url = `/admin/lightNovel/view-Light-Novel/${lightNovel_id}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }


  const handleRedirectToViewWebtoonPage = async (webtoon_id) => {
    try {
      const res = await getWebtoonById({webtoon_id});
      let url = `/admin/webtoon/view-webtoon/${webtoon_id}?redirect=1`

      if(isPageReload === pageReloadOptions.WEBTOON){
        setOpenUniverseModel(false);
        localStorage.setItem('is_same_webtoon_view_page',true);
        url = `/admin/webtoon/view-webtoon/${webtoon_id}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }


  const handleRedirectToViewAuthorPage = async (authorId) => {
    try {
      const res = await getAuthorSummaryById(authorId);
      let url = `/admin/author/view-author/${authorId}?redirect=1`

      if(isPageReload === pageReloadOptions.AUTHOR){
        setOpenUniverseModel(false);
        localStorage.setItem('is_same_author_view_page',true);
        url = `/admin/author/view-author/${authorId}?redirect=1`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  

  return (
    <Modal
      title="Universe Detail"
      centered
      open={openUniverseModel}
      onOk={() => setOpenUniverseModel(false)}
      onCancel={() => setOpenUniverseModel(false)}
      okButtonProps={{ className:"model-bg-color" }}
      cancelButtonProps={{ className:"model-border-color" }}  
    >
      <Card>
        <Row>
          <Col lg="12">
            <Card className="shadow  m-4 detail_card">
              <CardBody className="custom-margin-top">
                <Row>
                  {universeSummaryData?.name ? (
                    <>
                      <Col lg="4"> Name</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {universeSummaryData.name}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mt-1">
                  {universeSummaryData.original_medium ? (
                    <>
                      <Col lg="4">Original Medium</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {universeSummaryData?.original_medium}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {universeSummaryData?.jp_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <span className="text-dark text-wrap">
                          <a
                            href={universeSummaryData?.jp_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {universeSummaryData?.jp_wikipedia_link?.length > 50
                              ? `${universeSummaryData?.jp_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : universeSummaryData?.jp_wikipedia_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                <Row className="mt-1">
                  {universeSummaryData?.is_active ||
                  !universeSummaryData?.is_active ? (
                    <>
                      <Col lg="4">Is Active</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {universeSummaryData?.is_active ? "Yes" : "No"}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {universeSummaryData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {universeSummaryData.created_at
                            ? moment(universeSummaryData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          {universeSummaryData?.authors?.length ? (
            <Col>
              <Card className=" shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Authors</h3>
                  <Col>
                    {universeSummaryData?.authors?.map((author) => (
                      <ul>
                        {!isLinkFunctionalityActive ? 
                         <li key={author.id}>{author.name}</li> : currentAuthorId === author.author_id ?  <li key={author.author_id}>{author.name}</li> : <li className="primary-text-color" style={{cursor: "pointer"}} onClick={()=>handleRedirectToViewAuthorPage(author.author_id)} key={author.author_id}>{author.name}</li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {universeSummaryData?.anime?.length ? (
            <Col lg="12">
              <Card className="shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Anime</h3>
                  <Col>
                    {universeSummaryData.anime.map((anime, index) => (
                      <ul>
                        <li key={index}>{anime.en_title}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {universeSummaryData?.manga?.length ? (
            <Col lg="12">
              <Card className="shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Manga</h3>
                  <Col>
                    {universeSummaryData?.manga?.map((manga) => (
                      <ul>
                        {!isLinkFunctionalityActive ? 
                         <li key={manga.manga_id}>{manga.en_title}</li> : currentMangaId === manga.manga_id ?  <li key={manga.manga_id}>{manga.en_title}</li> :
                         <li className="primary-text-color" style={{cursor: "pointer"}} onClick={()=>handleRedirectToViewMangaPage(manga.manga_id)} key={manga.manga_id}>{manga.en_title}</li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {universeSummaryData?.videogame?.length ? (
            <Col lg="12">
              <Card className="shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">VideoGame</h3>
                  <Col>
                    {universeSummaryData.videogame.map((videogame) => (
                      <ul>
                        <li key={videogame.video_id}>{videogame.en_title}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {universeSummaryData?.light_novel?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Light Novel</h3>
                  <Col>
                    {universeSummaryData?.light_novel?.map((lightnovel) => (
                      <ul>
                        {!isLinkFunctionalityActive ? 
                         <li key={lightnovel.light_novel_id}>{lightnovel.en_title}</li>  : 
                         currentLightNovelId === lightnovel.light_novel_id ? <li key={lightnovel.light_novel_id}>{lightnovel.en_title}</li> : 
                         <li className="primary-text-color" style={{cursor: "pointer"}}  onClick={()=>handleRedirectToViewLightNovelPage(lightnovel.light_novel_id)}  key={lightnovel.light_novel_id}>
                            {lightnovel.en_title}
                         </li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {universeSummaryData?.webtoon?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Webtoon</h3>
                  <Col>
                    {universeSummaryData.webtoon.map((webtoon) => (
                      <ul>
                       {!isLinkFunctionalityActive ? 
                         <li key={webtoon.webtoon_id}>{webtoon.en_title}</li> : currentWebtoonId === webtoon.webtoon_id ? <li key={webtoon.webtoon_id}>{webtoon.en_title}</li> :
                         <li className="primary-text-color" style={{cursor: "pointer"}} onClick={()=>handleRedirectToViewWebtoonPage(webtoon.webtoon_id)} key={webtoon.webtoon_id}>{webtoon.en_title}</li> 
                       }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </Modal>
  );
}

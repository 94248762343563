import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";
import * as yup from "yup";
import clsx from "clsx";
import { UUIDv4 } from "uuid-v4-validator";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import { createManga } from "utils/endpoint";
import { get_PrePublication_list } from "utils/Commen";
import {
  get_universes_list,
  get_status_list,
  get_publisher_list,
  get_author_list,
  get_Illustrator_list,
  get_type_list,
  get_genre_list,
  get_public_list,
} from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { onGoing } from "utils/enums";
import { isActive } from "utils/enums";
import CreateUniverses from "utils/CreateUniverses";
import { get_User_list } from "utils/Commen";
const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

const CreateManga = () => {
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [universeList, setUniverseList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [frPublisherList, setFrPublisherList] = useState([]);
  const [usPublisherList, setUsPublisherList] = useState([]);
  const [dePublisherList, setDePublisherList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [illustratorList, setIllustratorList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [genreList, setGenreList] = useState([]);
  const [publicList, setPublicList] = useState([]);
  const [prePublicationList, setPrePublicationList] = useState([]);
  const [resultImages, setResultImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedAuthors, setSelectedAuthors] = React.useState([]);
  const [selectedIllustrator, setSelectedIllustrator] = React.useState([]);
  const [selectedGenre, setSelectedGenre] = React.useState([]);
  const [selectedPublicTargeted, setSelectedPublicTargeted] = React.useState([]);
  const [show, setShowModal] = useState(false);
  const [flagList, setFlagList] = useState([]);
  const [file, setFile] = useState();
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{ id: "", name: "None" }, ...allUniverses]);
  };
  const getStatusList = async () => {
    const allStatus = await get_status_list();
    setStatusList([{ id: "", name: "None" }, ...allStatus]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const jpPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'JP')
    const frPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'FR')
    const usPublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'US')
    const dePublisherData = allPublisher.filter((publisher) => publisher.publisher_type == 'DE')
    setPublisherList([{ id: "", name: "None" }, ...jpPublisherData]);
    setFrPublisherList([{ id: "", name: "None" }, ...frPublisherData]);
    setUsPublisherList([{ id: "", name: "None" }, ...usPublisherData]);
    setDePublisherList([{ id: "", name: "None" }, ...dePublisherData]);
  };
  const getAuthorList = async () => {
    const allAuthors = await get_author_list();
    setAuthorList(allAuthors);
  };
  const getIllustratorList = async () => {
    const illustrator = await get_Illustrator_list();
    setIllustratorList(illustrator);
  };
  const getTypeList = async () => {
    const allTYpe = await get_type_list();
    setTypeList([{ id: "", name: "None" }, ...allTYpe]);
  };
  const getGenreList = async () => {
    const allGenre = await get_genre_list();
    setGenreList(allGenre);
  };
  const getPublicList = async () => {
    const allPublic = await get_public_list();
    setPublicList(allPublic);
  };
  const getPrePublicationList = async () => {
    const allPrePublication = await get_PrePublication_list();
    setPrePublicationList([{ id: "", en_title: "None" }, ...allPrePublication]);
  };
  const getFlagList = async () => {
    const allFlag = await get_User_list();
    setFlagList(allFlag);
  };
  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
    getUniverseList();
    getStatusList();
    getPublisherList();
    getAuthorList();
    getIllustratorList();
    getTypeList();
    getGenreList();
    getPublicList();
    getPrePublicationList();
    getFlagList();
  }, []);
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    setFile(URL.createObjectURL(event.target.files[0]));
    const selectedFilesArray = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      getBase64(selectedFilesArray[i])
        .then((result) => {
          const imageArrays = { file_name: name, image_data: result };
          setResultImages(imageArrays);
        })
        .catch((err) => {});
    }
    event.target.value = "";
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: "",
        universe_id: "",
        status_id: "",
        jp_title: "",
        publisher_id: "",
        jp_label: "",
        pre_publication_id: "",
        image: [],
        no_of_volumes: "",
        ongoing: "",
        type_id: "",
        print_rights_in_france: "",
        digital_rights_in_france: "",
        print_rights_in_us: "",
        digital_rights_in_us: "",
        print_rights_in_de: "",
        digital_rights_in_de: "",
        jp_print_run_1: "",
        jp_print_run_2: "",
        jp_print_run_3: "",
        jp_print_run_4: "",
        jp_wikipedia_link: "",
        jp_amazon_link: "",
        nautiljon:"",
        jp_bookwalker_link: "",
        scantrad: "",
        low_sales: "",
        medium_sales: "",
        high_sales: "",
        fr_consumer_price: "",
        comment: "",
        is_active: "",
        summary: "",
        product_pl: "",
        author_ids: [],
        illustrator_ids: [],
        new_authors: [],
        new_illustrators: [],
        genre_ids: [],
        public_ids: [],
        flag_users: [],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader();
        const $inputData = {
          en_title: values.en_title,
          universe_id: values.universe_id,
          status_id: values.status_id,
          jp_title: values.jp_title,
          publisher_id: values.publisher_id,
          jp_label: values.jp_label,
          pre_publication_id: values.pre_publication_id,
          no_of_volumes: values.no_of_volumes,
          ongoing: values.ongoing,
          type_id: values.type_id,
          print_rights_in_france: values.print_rights_in_france,
          digital_rights_in_france: values.digital_rights_in_france,
          print_rights_in_us: values.print_rights_in_us,
          digital_rights_in_us: values.digital_rights_in_us,
          print_rights_in_de: values.print_rights_in_de,
          digital_rights_in_de: values.digital_rights_in_de,
          jp_print_run_1: values.jp_print_run_1,
          jp_print_run_2: values.jp_print_run_2,
          jp_print_run_3: values.jp_print_run_3,
          jp_print_run_4: values.jp_print_run_4,
          jp_wikipedia_link: values.jp_wikipedia_link,
          nautiljon:values.nautiljon,
          jp_amazon_link: values.jp_amazon_link,
          jp_bookwalker_link: values.jp_bookwalker_link,
          scantrad: values.scantrad,
          low_sales: values.low_sales,
          medium_sales: values.medium_sales,
          high_sales: values.high_sales,
          fr_consumer_price: values.fr_consumer_price,
          comment: values.comment,
          is_active: values.is_active,
          author_ids: values.author_ids,
          illustrator_ids: values.illustrator_ids,
          new_authors: values.new_authors,
          new_illustrators: values.new_illustrators,
          genre_ids: values.genre_ids,
          public_ids: values.public_ids,
          image: resultImages,
          summary: values.summary,
          flag_users: values.flag_users,
          product_pl: values.product_pl,
        };
        const finalObj = {};
        Object.keys($inputData).forEach(function (key) {
          if (typeof $inputData[key] === "string" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
          if (
            typeof $inputData[key] === "object" &&
            $inputData[key].length > 0
          ) {
            finalObj[key] = $inputData[key];
          }
          if (typeof $inputData[key] === "boolean" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
          if (typeof $inputData[key] === "number" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
        });
        if (resultImages) {
          finalObj.image = resultImages;
        }
      finalObj.universe_id = finalObj?.universe_id ? finalObj?.universe_id : null;
      finalObj.status_id = finalObj?.status_id ? finalObj?.status_id : null;
      finalObj.publisher_id = finalObj?.publisher_id  ? finalObj?.publisher_id : null;
      finalObj.pre_publication_id = finalObj?.pre_publication_id ? finalObj?.pre_publication_id : null;
      finalObj.type_id = finalObj?.type_id ? finalObj?.type_id : null;
      finalObj.print_rights_in_france = finalObj?.print_rights_in_france ? finalObj?.print_rights_in_france : null;
      finalObj.digital_rights_in_france = finalObj?.digital_rights_in_france ? finalObj?.digital_rights_in_france : null;
      finalObj.print_rights_in_us = finalObj?.print_rights_in_us ? finalObj?.print_rights_in_us : null;
      finalObj.digital_rights_in_us = finalObj?.digital_rights_in_us ? finalObj?.digital_rights_in_us : null;
      finalObj.print_rights_in_de = finalObj?.print_rights_in_de ? finalObj?.print_rights_in_de : null;
      finalObj.digital_rights_in_de = finalObj?.digital_rights_in_de ? finalObj?.digital_rights_in_de : null;
      finalObj.ongoing = finalObj?.ongoing !== "none" ? finalObj?.ongoing : null;

        const result = await createManga(finalObj);
        if (result?.isSuccess) {
          toast.success("Manga created successfully");
          setLoading(false);
          hideLoader();
          resetForm();
          navigate("/admin/manga/manga-list");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader();
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row>
                        <Col lg="6">
                          <h1 className="mb-0">Create Manga</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/manga/manga-list");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <Row className="form-control-label">
                              <Col lg="6">
                                <span className="">Universe</span>
                              </Col>

                              <Col lg="6" className="add_btn text-right">
                                <Button
                                  className="btn-sm ml-10 text-right"
                                  color="primary"
                                  onClick={async () => {
                                    setShowModal(true);
                                  }}>
                                  +
                                </Button>
                              </Col>
                            </Row>
                            <Select
                              name="universe_id"
                              id="universe_id"
                              className="react-select mt-1"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("universe_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Status</label>
                            <Select
                              name="status_id"
                              id="status_id"
                              className="react-select"
                              options={Array.from(statusList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("status_id", event.value);
                              }}
                              isSearchable={true}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Japanese Title
                            </label>
                            <Input
                              className="form-control"
                              id="jp_title"
                              name="jp_title"
                              placeholder="Japanese Title"
                              type="text"
                              value={values.jp_title}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Publisher
                            </label>

                            <Select
                              name="publisher_id"
                              id="publisher_id"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(publisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("publisher_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Label
                            </label>
                            <Input
                              className="form-control"
                              id="jp_label"
                              name="jp_label"
                              placeholder="JP Label"
                              type="text"
                              value={values.jp_label}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Pre-Publication
                            </label>
                            <Select
                              name="pre_publication_id"
                              id="pre_publication_id"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(prePublicationList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.en_title,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue(
                                  "pre_publication_id",
                                  event.value
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Cover</label>
                            <div className="form-control fs-1 w-100 m-0 ">
                              <label
                                className="fs-6 mb-0 text-muted"
                                style={{ cursor: "pointer" }}>
                                Click here to upload images{" "}
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"></i>
                                <Input
                                  id="upload"
                                  type="file"
                                  accept="image/png , image/jpeg, image/webp"
                                  name="uploadImageVideo"
                                  className="form-control d-none"
                                  onChange={onSelectFile}
                                />
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Author(s)
                            </label>
                            <CreatableSelect
                              isMulti
                              isValidNewOption={() => true}
                              id="author_ids"
                              name="author_ids"
                              options={Array.from(authorList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() =>
                                selectedAuthors.length >= 3
                              }
                              onChange={(event) => {
                                const selectedAuthors = event.map(
                                  (e) => e.value
                                );
                                const existingAuthors = [];
                                const newAuthors = [];
                                selectedAuthors.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingAuthors.push(x);
                                  } else {
                                    newAuthors.push(x);
                                  }
                                });
                                setFieldValue("author_ids", existingAuthors);
                                setFieldValue("new_authors", newAuthors);
                                setSelectedAuthors(event);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Illustrator
                            </label>
                            <CreatableSelect
                              isMulti
                              id="illustrator_ids"
                              name="illustrator_ids"
                              options={Array.from(illustratorList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              isOptionDisabled={() =>
                                selectedIllustrator.length >= 3
                              }
                              onChange={(event) => {
                                const selectedIllustrator = event.map(
                                  (e) => e.value
                                );
                                const existingIllustrator = [];
                                const newIllustrator = [];
                                selectedIllustrator.map((x) => {
                                  if (UUIDv4.validate(x)) {
                                    existingIllustrator.push(x);
                                  } else {
                                    newIllustrator.push(x);
                                  }
                                });
                                setFieldValue(
                                  "illustrator_ids",
                                  existingIllustrator
                                );
                                setFieldValue(
                                  "new_illustrators",
                                  newIllustrator
                                );
                                setSelectedIllustrator(event);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              No. of vol
                            </label>
                            <Input
                              className="form-control"
                              id="no_of_volumes"
                              name="no_of_volumes"
                              placeholder=" No. of vol"
                              type="number"
                              value={values.no_of_volumes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              OnGoing
                            </label>
                            <Select
                              name="ongoing"
                              id="ongoing"
                              className=""
                              options={Array.from(onGoing).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("ongoing", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("type_id", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Genre</label>
                            <Select
                              isMulti
                              name="genre_ids"
                              id="genre_ids"
                              className="select"
                              options={Array.from(genreList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedGenre.length >= 3}
                              onChange={(event) => {
                                setSelectedGenre(event);
                                setFieldValue(
                                  "genre_ids",
                                  event.map((e) => e.value)
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Public Targeted
                            </label>
                            <Select
                              isMulti
                              name="public_ids"
                              id="public_ids"
                              className="react-select"
                              options={Array.from(publicList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              isOptionDisabled={() => selectedPublicTargeted.length >= 3}
                              onChange={(event) => {
                                setSelectedPublicTargeted(event)
                                setFieldValue(
                                  "public_ids",
                                  event.map((e) => e.value)
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights In France
                            </label>
                            <Select
                              name="print_rights_in_france"
                              id="print_rights_in_france"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_france", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in France
                            </label>
                            <Select
                              id=" digital_rights_in_france"
                              name="digital_rights_in_france"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_france", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights In US
                            </label>
                            <Select
                              id="print_rights_in_us"
                              name="print_rights_in_us"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(usPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_us", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in US
                            </label>
                            <Select
                              id="digital_rights_in_us"
                              name="digital_rights_in_us"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(usPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_us", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Print Rights in DE
                            </label>
                            <Select
                              id="print_rights_in_de"
                              name="print_rights_in_de"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(dePublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("print_rights_in_de", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Digital Rights in DE
                            </label>
                            <Select
                              id="digital_rights_in_de"
                              name="digital_rights_in_de"
                              className="react-select"
                              classNamePrefix="my-react-select"
                              options={Array.from(dePublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              onChange={(event) => {
                                setFieldValue("digital_rights_in_de", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 1
                            </label>
                            <Input
                              className="form-control"
                              id=" jp_print_run_1"
                              name="jp_print_run_1"
                              placeholder=" JP print run 1"
                              type="number"
                              value={values.jp_print_run_1}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 2
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_2"
                              name="jp_print_run_2"
                              placeholder="JP print run 2"
                              type="number"
                              value={values.jp_print_run_2}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 3
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_3"
                              name="jp_print_run_3"
                              placeholder="JP print run 3"
                              type="number"
                              value={values.jp_print_run_3}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP print run 4
                            </label>
                            <Input
                              className="form-control"
                              id="jp_print_run_4"
                              name="jp_print_run_4"
                              placeholder="JP print run 4"
                              type="number"
                              value={values.jp_print_run_4}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "create-manga-product-pl" ? (
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Product P&L
                                </label>
                                <Input
                                  className="form-control"
                                  id="product_pl"
                                  name="product_pl"
                                  placeholder="Product P&L"
                                  type="text"
                                  value={values.product_pl}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )
                        )}

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP wikipedia
                            </label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia_link"
                              name="jp_wikipedia_link"
                              placeholder="JP wikipedia"
                              type="text"
                              value={values.jp_wikipedia_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                             Nautiljon
                            </label>
                            <Input
                              className="form-control"
                              id="nautiljon"
                              name="nautiljon"
                              placeholder="Nautiljon"
                              type="text"
                              value={values.nautiljon}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Amazon
                            </label>
                            <Input
                              className="form-control"
                              id="jp_amazon_link"
                              name="jp_amazon_link"
                              placeholder="JP Amazon"
                              type="text"
                              value={values.jp_amazon_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP BookWalker
                            </label>
                            <Input
                              className="form-control"
                              id="jp_bookwalker_link"
                              name="jp_bookwalker_link"
                              placeholder="JP BookWalker"
                              type="text"
                              value={values.jp_bookwalker_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              ScanTrad
                            </label>
                            <Input
                              className="form-control"
                              id="scantrad"
                              name="scantrad"
                              placeholder="ScanTrad"
                              type="text"
                              value={values.scantrad}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                        {permissions &&
                          permissions.map((permission) =>
                            permission === "create-manga-low-sale" ? (
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Low Sales
                                </label>
                                <Input
                                  className="form-control"
                                  id="low_sales"
                                  name="low_sales"
                                  placeholder="Low Sales"
                                  type="number"
                                  value={values.low_sales}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "create-manga-medium-sale" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    Medium Sales
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="medium_sales"
                                    name="medium_sales"
                                    placeholder="Medium Sales"
                                    type="number"
                                    value={values.medium_sales}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )
                        )}

                        {permissions &&
                          permissions.map((permission) =>
                            permission === "create-manga-high-sale" ? (
                              <Col lg="3">
                                <FormGroup>
                                  <label className="form-control-label">
                                    High Sales
                                  </label>
                                  <Input
                                    className="form-control"
                                    id="high_sales"
                                    name="high_sales"
                                    placeholder="High Sales"
                                    type="number"
                                    value={values.high_sales}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                            </Col>
                            ) : (
                              ""
                            )
                        )}
                        
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR consumer price
                            </label>
                            <Input
                              className="form-control"
                              id="fr_consumer_price"
                              name="fr_consumer_price"
                              placeholder="FR consumer price"
                              type="number"
                              value={values.fr_consumer_price}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className=""
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("is_active", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Flag</label>
                            <Select
                              isMulti
                              name="flag_users"
                              id="flag_users"
                              className="select"
                              options={Array.from(flagList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.first_name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue(
                                  "flag_users",
                                  event.map((e) => e.value)
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Summary
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Summary..."
                              type="text"
                              name="summary"
                              id="summary"
                              value={values.summary}
                              onChange={handleChange}
                              rows={2}
                              cols={40}></textarea>
                          </FormGroup>
                        </Col>
                        
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Comments
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="comments..."
                              type="text"
                              name="comment"
                              id="comment"
                              value={values.comment}
                              onChange={handleChange}
                              rows={2}
                              cols={40}></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Create Manga
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
              <CreateUniverses
                show={show}
                mediaType={"MANGA"}
                onClose={() => {
                  getUniverseList();
                  setShowModal(false);
                }}
              />
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default CreateManga;

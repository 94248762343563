import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AuthorModal from "utils/authorModal";
import { getWebtoonById } from "utils/endpoint";
import { getMangaById } from "utils/endpoint";
import { getLightNovelById } from "utils/endpoint";
import { getAuthorSummaryById } from "utils/endpoint";
import  isPageReload  from "utils/enums";
import LightNovelModal from "utils/lightNovelModal";
import MangaModal from "utils/mangaModal";
import useSearchParams from "utils/useSearchParams";
import WebtoonModal from "utils/webtoonModal";

function ViewUniverse() {
  const { state } = useLocation();
  const [universeQueryParams ,setUniverseQueryParams] = useSearchParams();
  const currentPage = universeQueryParams.currentPage;
  const fromDate = universeQueryParams.fromDate;
  const toDate = universeQueryParams.toDate;
  const name = universeQueryParams.name;
  const original_medium = universeQueryParams.original_medium;
  const noOfMediaCount = universeQueryParams.noOfMediaCount;
  const is_active = universeQueryParams.is_active;
  const redirect = universeQueryParams.redirect !== null || undefined && universeQueryParams.redirect;
  const [universe,setUniverse] = useState(state?.row);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [lightNovelSummaryData, setLightNovelSummaryData] = useState([]);
  const [openLightNovelModel, setOpenLightNovelModel] = useState(false);
  const [mangaSummaryData, setMangaSummaryData] = useState([]);
  const [openMangaModel, setOpenMangaModel] = useState(false);
  const [webtoonSummaryData, setWebtoonSummaryData] = useState([]);
  const [openWebtoonModel, setOpenWebtoonModel] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate()

  const universeId = universe?.id;
  const isSameUniverseViewPage = localStorage.getItem('is_same_universe_view_page');

  const fetchAuthorsDetail = async (authorId) => {
    setOpenAuthorModel(true);
    const res = await getAuthorSummaryById(authorId);
    if (res && res.isSuccess) {
      setAuthorSummaryData(res.data);
    }
  };

  const fetchLightNovelDetail = async (lightnovelId) => {
    setOpenLightNovelModel(true);
    const res = await getLightNovelById({
      lightNovel_id: lightnovelId,
    });
    if (res && res.isSuccess) {
      setLightNovelSummaryData(res.data);
    }
  };

  const fetchMangaDetail = async (mangaId) => {
    setOpenMangaModel(true);
    const res = await getMangaById({
      manga_id: mangaId,
    });
    if (res && res.isSuccess) {
      setMangaSummaryData(res.data);
    }
  };

  const fetchWebtoonDetail = async (webtoonId) => {
    setOpenWebtoonModel(true);
    const res = await getWebtoonById({
      webtoon_id: webtoonId,
    });
    if (res && res.isSuccess) {
      setWebtoonSummaryData(res.data);
    }
  };

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  const handleSearchParams = (baseurl) => {
    if(name) baseurl+= `&name=${name}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(original_medium) baseurl += `&original_medium=${original_medium}`
    if(noOfMediaCount) baseurl+= `&no_of_media_count=${noOfMediaCount}`
    if(is_active || is_active === "false") baseurl += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return baseurl;
  }

  useEffect(()=>{
    setUniverse(state.row);
  },[state.row])

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow  m-4 detail_card">
                <CardHeader className="border-0">
                <Row>
              <Col lg="6">
              <h1 className="mb-0">Universe Detail</h1>
              </Col>
            <Col lg="6" className="add_btn text-right">
              <Button
                color="primary"
                className="m-2"
                onClick={() => {

                  if(isSameUniverseViewPage == 'true'){
                    navigate(-1)
                    localStorage.setItem('is_same_universe_view_page',false);
                  }
            
                  if(redirect == 1){
                    navigate(-1)
                  }
                  
                  if(redirect == 2 && redirect !== 1 || !redirect){
                    let baseURL = `/admin/universe/universe-list?id=${state.row.id}&page=${currentPage}`;
                    const url = handleSearchParams(baseURL,true);
                    navigate(url);
                    localStorage.setItem('is_same_universe_view_page',false);
                  }
                }}>
                <span className="me-2">Back</span>
              </Button>
            </Col>
              </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row>
                    {universe?.name ? (
                      <>
                        <Col lg="4"> Name</Col>
                        <Col lg="8">
                          <div className="text-dark h2">{universe.name}</div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="mt-1">
                    {universe?.original_medium ? (
                      <>
                        <Col lg="4">Original Medium</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {universe?.original_medium}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {universe?.jp_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <td className="link primary-text-color">
                          <a href={universe?.jp_wikipedia_link} target="_blank" rel="noreferrer">
                            {universe?.jp_wikipedia_link?.length > 50 ? `${universe?.jp_wikipedia_link.slice(0, 40)}...` : universe?.jp_wikipedia_link}
                          </a>
                          </td>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  <Row className="mt-1">
                    {universe?.is_active || !universe?.is_active ? (
                      <>
                        <Col lg="4">Is Active</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {universe?.is_active ? "Yes" : "No"}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {universe?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {universe.created_at
                              ? moment(universe.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
              {universe?.authors?.length ? (
            <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Authors</h3>
                    <Col>
                      {universe.authors.map((author, index) => (
                          <li
                          className="primary-text-color" style={{cursor: "pointer"}}
                          onClick={() =>
                            fetchAuthorsDetail(author.author_id)
                          }
                          key={index}
                        >
                          {author.name}
                        </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
            </Col>
              ) : (
               ""
              )}
            
            {universe?.anime?.length ? (
            <Col lg="4">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Anime</h3>
                  <Col>
                    {universe.anime.map((anime, index) => (
                      <li key={index}>{anime.en_title}</li>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            ) : (
             ""
            )}
            {universe?.manga?.length ? (
          <Col lg="4">
              <Card className="shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Manga</h3>
                  <Col>
                    {universe?.manga?.map((manga) => (
                      <li className="primary-text-color" style={{cursor: "pointer"}}
                      onClick={() =>
                        fetchMangaDetail(manga.manga_id)
                      } key={manga.manga_id}>{manga.en_title}</li>
                    ))}
                  </Col>
                </CardBody>
              </Card>
             </Col>
            ) : (
             ""
            )}
            
            {universe?.videogame?.length ? (
            <Col lg="4">
              <Card
                className="shadow  m-4 view_cards"
               >
                <CardBody>
                  <h3 className="fw-bolder dot_border">VideoGame</h3>
                  <Col>
                    {universe.videogame.map((videogame) => (
                      <li key={videogame.video_id}>{videogame.en_title}</li>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            ) : (
             ""
            )}
            {universe?.light_novel?.length ? (
            <Col lg="4">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Light Novel</h3>
                  <Col>
                    {universe?.light_novel?.map((lightnovel) => (
                       <li
                       className="primary-text-color" style={{cursor: "pointer"}}
                       onClick={() =>
                         fetchLightNovelDetail(lightnovel.light_novel_id)
                       }
                       key={lightnovel.light_novel_id}
                     >
                       {lightnovel.en_title}
                     </li>
                    ))}
                  </Col>
                </CardBody>
              </Card>
              </Col>
            ) : (
             ""
            )}
            
            {universe?.webtoon?.length ? (
            <Col lg="4">
              <Card
                className="me-2 shadow  m-4 view_cards"
               >
                <CardBody>
                  <h3 className="fw-bolder dot_border">Webtoon</h3>
                  <Col>
                    {universe.webtoon.map((webtoon) => (
                      <li 
                      className="primary-text-color" style={{cursor: "pointer"}}
                      onClick={() =>
                        fetchWebtoonDetail(webtoon.webtoon_id)
                      }
                      key={webtoon.webtoon_id}>{webtoon.en_title}</li>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
            ) : (
             ""
            )}
          </Row>
        </Card>
        <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData } isLinkFunctionalityActive={true}/>
        <LightNovelModal openLightNovelModel = {openLightNovelModel} setOpenLightNovelModel = {setOpenLightNovelModel} lightNovelSummaryData={lightNovelSummaryData} permissions={permissions} isLinkFunctionalityActive = {true} currentUniverseId={universeId}/>
        <MangaModal openMangaModel = {openMangaModel} setOpenMangaModel={setOpenMangaModel} mangaSummaryData={mangaSummaryData} permissions={permissions} isLinkFunctionalityActive = {true} currentUniverseId={universeId}/>
        <WebtoonModal openWebtoonModel = {openWebtoonModel} setOpenWebtoonModel={setOpenWebtoonModel} webtoonSummaryData={webtoonSummaryData} permissions={permissions} isLinkFunctionalityActive = {true} currentUniverseId={universeId}/>
      </Container>
    </>
  );
}

export default ViewUniverse;

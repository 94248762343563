import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  Button,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import clsx from "clsx";
import { ErrorMessage, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import { createUniverse } from "utils/endpoint";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
});
function CreateUniverses({ show, onClose, mediaType}) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal
        className="modal-dialog-centered "
        isOpen={show}
        toggle={onClose}>
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            original_medium: mediaType,
            jp_wikipedia_link: "",
            is_active: true,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            const $inputData = {
              name: values.name,
              original_medium: values.original_medium,
              jp_wikipedia_link: values.jp_wikipedia_link,
              is_active: values.is_active,
            };
            const result = await createUniverse($inputData);
            if (result?.isSuccess) {
              toast.success(`${result.message}`);
              setLoading(false);
              resetForm();
              onClose()
            } else {
              setSubmitting(false);
              setLoading(false);
              return toast.error(
                result.message ||
                  `Something went wrong, please try again letter!`
              );
            }
          }}>
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent d-flex justify-content-between">
                    <div className="form-control-label">Create Universe</div>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => onClose()}>
                      <span aria-hidden={true}>×</span>
                    </button>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label required">
                            Name
                          </label>
                          <Input
                            className={clsx("form-control", {
                              "is-invalid":
                                Boolean(errors.name) && touched.name,
                            })}
                            id="name"
                            name="name"
                            placeholder="Name"
                            type="text"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <div className="text-danger text-align-top-center mt-1">
                            <ErrorMessage name="name" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label ">
                            JP Wikipedia Link
                          </label>
                          <Input
                            className="form-control"
                            id="jp_wikipedia_link"
                            name="jp_wikipedia_link"
                            placeholder="jp_wikipedia_link"
                            type="text"
                            value={values.jp_wikipedia_link}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Button
                          type="submit"
                          className="my-3 w-100"
                          color="primary">
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}>
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!loading && (
                            <span className="indicator-label">
                              Create Universe
                            </span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CreateUniverses;

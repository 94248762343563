import Header from "components/Headers/Header";
import { ErrorMessage, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { createRole } from "utils/endpoint";
import { get_Permission_list } from "utils/Commen";
import $ from "jquery";
import * as yup from "yup";
import clsx from "clsx";
import UseFullPageLoader from "utils/UseFullPageLoader";
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  permissions: yup.array().min(1, "Permission is required"),
});
function CreateRole() {
  const [loading, setLoading] = useState(false);
  const [permissionDB, setPermissionDB] = useState([]);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const navigate = useNavigate();
  
  const getPermissionList = async () => {
    const allPermission = await get_Permission_list();
    setPermissionDB(allPermission);
  };
  useEffect(() => {
    getPermissionList();
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        permissions: [],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const $inputData = {
          name: values.name,
          permissions: values.permissions,
        };
        const result = await createRole($inputData);
        if (result?.isSuccess) {
          $("input[type=checkbox]").prop("checked", function () {
            $(this).prop("checked", false);
          });
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          setSubmitting(false);
          navigate("/admin/role/role-list");
          resetForm();
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Create Role</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/role/role-list");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="9">
                          <FormGroup>
                            <label className="form-control-label required ">
                              Permission
                            </label>

                            <FieldArray name="permissions">
                              {(arrayHelpers) => {
                                return (
                                  <>
                                    <div className="row">
                                      {Array.from(permissionDB).map(
                                        (value, index) => {
                                          return (
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                              <label
                                                className="ms-2 fs-5 form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                                key={index}>
                                                <input
                                                  id="permissions"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="permissions"
                                                  value={value.id}
                                                  onChange={(e) => {
                                                    if (e.target.checked)
                                                      arrayHelpers.push(
                                                        e.target.value
                                                      );
                                                    else {
                                                      const idx =
                                                        values.permissions.findIndex(
                                                          (e) =>
                                                            e.slug ===
                                                            value.slug
                                                        );
                                                      arrayHelpers.remove(idx);
                                                    }
                                                  }}
                                                />
                                                <span className="form-check-label fs-5 mb-0 ms-2">
                                                  {value.name}
                                                </span>
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                );
                              }}
                            </FieldArray>
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="permissions" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Create Role
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default CreateRole;


import {Container } from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info-new pb-8 pt-0">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>

    </>
  );
};

export default Header;

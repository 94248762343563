import Header from "components/Headers/Header";
import { Formik, ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { createUser, getRoleList } from "utils/endpoint";
import Select from "react-select";
import * as Yup from "yup";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import clsx from "clsx";

const mobileNoRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile_no: Yup.string()
    .required("Mobile number is required")
    .matches(mobileNoRegExp, "Mobile number is not valid"),
  password: Yup.string().required("Password is required"),
  is_active: Yup.string().required("Is active is required"),
  role_id: Yup.string().required("Role id is required"),
});
function CreateUser() {
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] =UseFullPageLoader();

  useEffect(() => {
    (async () => {
      try {
        const response = await getRoleList();
        if (response && response.isSuccess === true) {
          setRoleList(response.data);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
        password: "",
        is_active: "",
        role_id: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const $inputData = {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          mobile_no: values.mobile_no,
          password: values.password,
          is_active: values.is_active,
          role_id: values.role_id,
        };
        const finalObj = {};

        Object.keys($inputData).forEach(function (key) {
          if (typeof $inputData[key] === "string" && $inputData[key]) {
            finalObj[key] = $inputData[key];
          }
          if (typeof $inputData[key] === "boolean" && $inputData[key] | $inputData[key] === false) {
            finalObj[key] = $inputData[key];
          }
          if (
            typeof $inputData[key] === "object" &&
            $inputData[key].length > 0
          ) {
            finalObj[key] = $inputData[key];
          }
        });
        const result = await createUser(finalObj);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          navigate("/admin/user/UserList");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Create User</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/user/UserList");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              First Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.first_name) && touched.first_name,
                              })}
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="first_name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Last Name
                            </label>
                            <Input
                               className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.last_name) && touched.last_name,
                              })}
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="last_name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">Email</label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.email) && touched.email,
                              })}
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="text"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="email" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Mobile Number
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.mobile_no) && touched.mobile_no,
                              })}
                              id="mobile_no"
                              name="mobile_no"
                              placeholder="Mobile Number"
                              type="text"
                              value={values.mobile_no}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="mobile_no" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Password
                            </label>
                            <Input
                               className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.password) && touched.password,
                              })}
                              id="password"
                              name="password"
                              placeholder="Password"
                              type="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="password" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className={clsx("react-select", {
                                "is-invalid":
                                  Boolean(errors.is_active) && touched.is_active,
                              })}
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("is_active", event.value);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="is_active" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">Role</label>
                            <Select
                              name="role_id"
                              id="role_id"
                              className={clsx("react-select", {
                                "is-invalid":
                                  Boolean(errors.role_id) && touched.role_id,
                              })}
                              options={Array.from(roleList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("role_id", event.value);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="role_id" />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Create User
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default CreateUser;

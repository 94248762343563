import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAuthorSummaryById, getUniverseSummaryById } from "./endpoint";
import { toast } from "react-toastify";
import pageReloadOptions from "./enums";

export default function MangaModal({
  openMangaModel,
  setOpenMangaModel,
  mangaSummaryData,
  permissions,
  currentAuthorId,
  currentUniverseId,
  isPageReload,
  isLinkFunctionalityActive = false,
}) {
  const navigate = useNavigate();

  const handleRedirectToViewAuthorPage = async (authorId) => {
    try {
      const res = await getAuthorSummaryById(authorId);
      let url = `/admin/author/view-author/${authorId}?redirect=1`

      if(isPageReload === pageReloadOptions.AUTHOR){
        setOpenMangaModel(false);
        localStorage.setItem('is_same_author_view_page',true);
        url = `/admin/author/view-author/${authorId}?redirect=1`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const handleRedirectToViewUniversePage = async (unvierseId) => {
    try {
      const res = await getUniverseSummaryById(unvierseId);
      let url = `/admin/universe/view-universe/${unvierseId}?redirect=1`

      if(isPageReload === pageReloadOptions.UNIVERSE){
        setOpenMangaModel(false);
        localStorage.setItem('is_same_universe_view_page',true);
        url = `/admin/universe/view-universe/${unvierseId}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  return (
    <Modal
      title="Manga Detail"
      centered
      open={openMangaModel}
      onOk={() => setOpenMangaModel(false)}
      onCancel={() => setOpenMangaModel(false)}
      okButtonProps={{ className:"model-bg-color" }}
      cancelButtonProps={{ className:"model-border-color" }}  
    >
      <Card>
        <Row>
          <Col lg="12">
            <Card className="shadow m-4 detail_card ">
              <CardBody className="custom-margin-top">
                <Row>
                  {mangaSummaryData?.en_title ? (
                    <>
                      <Col lg="4"> EN Title</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {mangaSummaryData.en_title}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mt-1">
                  {mangaSummaryData?.universe_name ? (
                    <>
                      <Col lg="4">Universe Name</Col>
                      <Col lg="8">
                        {!isLinkFunctionalityActive ? (
                          <span className="text-dark">
                            {mangaSummaryData?.universe_name}
                          </span>
                        ) : currentUniverseId ===
                          mangaSummaryData.universe_id ? (
                          <span className="text-dark">
                            {mangaSummaryData?.universe_name}
                          </span>
                        ) : (
                          <span
                            style={{ cursor: "pointer", color: "#5e72e4" }}
                            onClick={() =>
                              handleRedirectToViewUniversePage(
                                mangaSummaryData.universe_id
                              )
                            }
                          >
                            {mangaSummaryData?.universe_name}
                          </span>
                        )}
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mt-1">
                  {mangaSummaryData?.jp_title ? (
                    <>
                      <Col lg="4">JP Title</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.jp_title}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {mangaSummaryData?.status_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Status</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.status_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.publisher_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP publisher</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.publisher_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {mangaSummaryData?.pre_publication_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Pre Publication</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.pre_publication_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.no_of_volumes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">No. of vol</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.no_of_volumes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.ongoing ||
                (!mangaSummaryData?.ongoing &&
                  mangaSummaryData?.ongoing !== null) ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Ongoing</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData.ongoing === true ? "Yes" : "No"}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.type_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Type</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.type_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.print_rights_in_france ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            mangaSummaryData?.print_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.digital_rights_in_france ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In France</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            mangaSummaryData?.digital_rights_in_france_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.print_rights_in_us ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.print_rights_in_us_publisher_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.digital_rights_in_us ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In US</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            mangaSummaryData?.digital_rights_in_us_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.print_rights_in_de ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Print Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.print_rights_in_de_publisher_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.digital_rights_in_de ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Digital Rights In DE</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {
                            mangaSummaryData?.digital_rights_in_de_publisher_name
                          }
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_print_run_1 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 1</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.jp_print_run_1}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_print_run_2 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 2</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.jp_print_run_2}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_print_run_3 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 3</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.jp_print_run_3}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_print_run_4 ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Print Run 4</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData?.jp_print_run_4}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={mangaSummaryData?.jp_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {mangaSummaryData?.jp_wikipedia_link?.length > 50
                              ? `${mangaSummaryData?.jp_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : mangaSummaryData?.jp_wikipedia_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.nautiljon ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Nautiljon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={mangaSummaryData?.nautiljon}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {mangaSummaryData?.nautiljon?.length > 50
                              ? `${mangaSummaryData?.nautiljon.slice(0, 40)}...`
                              : mangaSummaryData?.nautiljon}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_amazon_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Amazon</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={mangaSummaryData?.jp_amazon_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {mangaSummaryData?.jp_amazon_link?.length > 50
                              ? `${mangaSummaryData?.jp_amazon_link.slice(
                                  0,
                                  40
                                )}...`
                              : mangaSummaryData?.jp_amazon_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {mangaSummaryData?.jp_bookwalker_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP BookWalker</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={mangaSummaryData?.jp_bookwalker_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {mangaSummaryData?.jp_bookwalker_link?.length > 50
                              ? `${mangaSummaryData?.jp_bookwalker_link.slice(
                                  0,
                                  40
                                )}...`
                              : mangaSummaryData?.jp_bookwalker_link}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {permissions &&
                  permissions.map((permission) =>
                    permission === "view-manga-price" ? (
                      <>
                        {mangaSummaryData?.low_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">Low Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {mangaSummaryData?.low_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {mangaSummaryData?.medium_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">Medium Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {mangaSummaryData?.medium_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {mangaSummaryData?.high_sales ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">High Sales</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {mangaSummaryData?.high_sales}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}

                        {mangaSummaryData?.fr_consumer_price ? (
                          <>
                            <Row className="mt-1">
                              <Col lg="4">FR Consumer Price</Col>
                              <Col lg="8">
                                <span className="text-dark">
                                  {mangaSummaryData?.fr_consumer_price}
                                </span>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )
                  )}

                {mangaSummaryData?.summary ? (
                  <>
                    <Row className="mt-21">
                      {mangaSummaryData?.summary ? (
                        <>
                          <Col lg="4">Summary</Col>
                          <Col lg="8">
                            <span className="text-dark">
                              {mangaSummaryData?.summary}
                            </span>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}

                
              {permissions &&
                      permissions.map((permission) =>
                        permission === "view-manga-product-pl" ? (
                          <>
                              {mangaSummaryData?.product_pl ? (
                                <>
                                <Row className="mt-1">
                                  <Col lg="4">Product P&L</Col>
                                  <Col lg="8">
                                      <span className="link primary-text-color text-wrap">
                                          <a
                                            href={mangaSummaryData?.product_pl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {mangaSummaryData?.product_pl?.length >
                                            50
                                              ? `${mangaSummaryData?.product_pl.slice(
                                                  0,
                                                  40
                                                )}...`
                                              : mangaSummaryData?.product_pl}
                                          </a>
                                      </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                )}

                {mangaSummaryData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {mangaSummaryData.created_at
                            ? moment(mangaSummaryData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          {mangaSummaryData?.authors?.length ? (
            <Col>
              <Card className=" shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Authors</h3>
                  <Col>
                    {mangaSummaryData.authors.map((author) => (
                      <ul>
                        {!isLinkFunctionalityActive ? (
                          <li key={author.id}>{author.name}</li>
                        ) : (
                          currentAuthorId === author.id ? <li key={author.id}>{author.name}</li>:
                          <li
                            className="primary-text-color" style={{cursor: "pointer"}}
                            onClick={() =>
                              handleRedirectToViewAuthorPage(author.id)
                            }
                            key={author.id}
                          >
                            {author.name}
                          </li>
                        )}
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {mangaSummaryData?.genres?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Genre</h3>
                  <Col>
                    {mangaSummaryData.genres.map((genre, index) => (
                      <ul>
                        <li key={index}>{genre.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {mangaSummaryData?.illustrators?.length ? (
            <Col lg="12">
              <Card className=" shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Illustrator</h3>
                  <Col>
                    {mangaSummaryData.illustrators.map(
                      (illustrators, index) => (
                        <ul>
                          <li key={index}>{illustrators.name}</li>
                        </ul>
                      )
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {mangaSummaryData?.publics?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Public</h3>
                  <Col>
                    {mangaSummaryData.publics.map((publicsD, index) => (
                      <ul>
                        <li key={index}>{publicsD.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </Modal>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header";
import { getWebtoonById, webtoonList } from "utils/endpoint";
import { toast } from "react-toastify";
import { updateWebtoonStatus } from "utils/endpoint";
import {
  get_status_list,
  get_type_list,
  get_User_list,
} from "utils/Commen";
import Select from "react-select";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { Tooltip } from "react-tooltip";
import { colors } from "utils/enums";
import { getAuthorSummaryById, getIllustratorSummaryById,getUniverseSummaryById } from "utils/endpoint";
import { Pagination as Paginationes, Switch } from "antd";
import UniverseModal from "utils/universeModal";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { createWebtoonBookMark } from "utils/endpoint";
import { deleteWebtoonBookMark } from "utils/endpoint";
import { isBookMark } from "utils/enums";
import ImageModal from "../../../utils/ImageModal";
import { webtoonUpdateFlagStatus } from "utils/endpoint";
import { randomIndex } from "utils/Commen";
import RatingUserListModal from "utils/ratingUserListModal";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export const handleWebtoonBookMark = async ({isChecked,webtoonId}) => {
  try {
    if(isChecked){
      const response =  await createWebtoonBookMark({
        webtoon_id:webtoonId
      })

      if (response?.isSuccess) {
        toast.success("Add Bookmark successfully");
      } else {
        toast.error(response?.message);
      }
    }else {
      const response =  await deleteWebtoonBookMark(webtoonId)
      if (response?.isSuccess) {
        toast.success("Removed Bookmark successfully");
      } else {
        toast.error(response?.message);
      }
    }

  } catch (error) {
    toast.error(error?.message);
  }
}


// const onGoing = [
//   {
//     name: "Select",
//     id: "select",
//   },
//   {
//     name: "Yes",
//     id: true,
//   },
//   {
//     name: "No",
//     id: false,
//   },
// ];
// const isActive = [
//   {
//     name: "Select",
//     id: "select",
//   },
//   {
//     name: "Active",
//     id: true,
//   },
//   {
//     name: "InActive",
//     id: false,
//   },
// ];
function WebtoonList(args) {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const enTitle = params.enTitle;
  const userIdsArray = params.userIdsArray;
  const statusIdsArray = params.statusIdsArray;
  const typeIdsArray = params.typeIdsArray;
  const is_bookmark = params.is_bookmark;
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [show, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [webtoonLists, setwebtoonLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100);
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  // const [universeList, setUniverseList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterENTitle, setFilterENTitle] = useState(enTitle || "");
  // const [filterUniverse, setFilterUniverse] = useState("");
  const [filterIsBookMark, setFilterIsBookMark] = useState(is_bookmark || "");
  const [filterUser, setFilterUser] = useState(userIdsArray || "");
  const [filterStatus, setFilterStatus] = useState(statusIdsArray || "");
  const [filterType, setFilterType] = useState(typeIdsArray || "");
  // const [filterIsActive, setFilterIsActive] = useState("");
  // const [filterOnGoing, setFilterOnGoing] = useState("");
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  // const [universeSelected, setUniverseSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [webtoonFilter,setWebtoonFilter] = useState('')
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [ratingInfo, setRatingInfo] = useState([]);
  // const selectInputRefs = useRef(null);
  const selectInputRef = useRef(null);
  const imgRef = useRef(null);
  const [image, setImage] = useState("");

  const toggle = () => setIsOpen(!isOpen);
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: mySortKey,
      sort_by: mySortBy,
    };

    if (new Date(filterFromDate) <= new Date(filterToDate)) {
      filter.from_date = filterFromDate;
    }
    if (new Date(filterToDate) >= new Date(filterFromDate)) {
      filter.to_date = filterToDate;
    }
    // if (filterENTitle) {
    //   filter.en_title = filterENTitle;
    // }
    // if (filterUniverse) {
    //   filter.universe_id = filterUniverse;
    // }
    // if (filterUser) {
    //   filter.user_id = filterUser;
    // }
    // if (filterStatus) {
    //   filter.status_id = filterStatus;
    // }

    // if (filterType) {
    //   filter.type_id = filterType;
    // }
    // if (filterOnGoing === true) {
    //   filter.ongoing = "true";
    // } else if (filterOnGoing === false) {
    //   filter.ongoing = "false";
    // }
    // if (filterOnGoing === "select") {
    //   delete filter.ongoing;
    // }
    // if (filterIsActive === true) {
    //   filter.is_active = "true";
    // } else if (filterIsActive === false) {
    //   filter.is_active = "false";
    // }
    // if (filterIsActive === "select") {
    //   delete filter.is_active;
    // }
    if(webtoonFilter){
      let filter = webtoonFilter;
      filter.sort_by= mySortBy;
    }
    getWebtoonList(filter);
  };
  const getWebtoonList = async (webtoonFilter) => {
    setwebtoonLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await webtoonList(webtoonFilter);
      if (result?.isSuccess) {
        setwebtoonLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setwebtoonLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setwebtoonLists([]);
    }
  };
  // const prevPage = async () => {
  //   setPage(page - 1);
  // };

  // const nextPage = async () => {
  //   setPage(page + 1);
  // };
  // const getUniverseList = async () => {
  //   const allUniverses = await get_universes_list();
  //   setUniverseList(allUniverses);
  // };
  const getStatusList = async () => {
    const allStatus = await get_status_list();  
    setStatusList([{ id:'8f97245b-5d1a-4dfd-8158-7211baf22e82', name:'None'},...allStatus]);
  };
  const getTypeList = async () => {
    const allType = await get_type_list();
    setTypeList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...allType]);
  };
  const getUserList = async () => {
    const allUser = await get_User_list();
    setUserList(allUser);
  };

  const commonWebtoonFilterOptions = (webtoonFilterObject) => {

    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };
   
    const from_date = webtoonFilterObject?.from_date;
    const to_date = webtoonFilterObject?.to_date;
    const en_title = webtoonFilterObject?.en_title;
    const user_id =  webtoonFilterObject?.user_id;
    const status_id = webtoonFilterObject?.status_id;
    const type_id = webtoonFilterObject?.type_id;
    const is_bookmark = webtoonFilterObject?.is_bookmark;

    if(en_title){
      filter.en_title = en_title.replace(/'/g, "''");
    }

    if(new Date(from_date) <= new Date(to_date)){
      filter.from_date = from_date;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter.to_date = to_date;
    }

    if(user_id){
      filter.user_id = user_id;
    }

    if(status_id){
      filter.status_id = status_id;
    }

    if(type_id){
      filter.type_id = type_id;
    }

    if (is_bookmark === true || is_bookmark === 'true') {
      filter.is_bookmark = true;
    } else if (is_bookmark === false || is_bookmark === 'false') {
      filter.is_bookmark = false;
    }
    if (is_bookmark === "select") {
      delete filter.is_bookmark;
    }


      // if (filterUniverse) {
      //   filter.universe_id = filterUniverse;
      // }
     
      // if (filterOnGoing === true) {
      //   filter.ongoing = "true";
      // } else if (filterOnGoing === false) {
      //   filter.ongoing = "false";
      // }
      // if (filterOnGoing === "select") {
      //   delete filter.ongoing;
      // }
      // if (filterIsActive === true) {
      //   filter.is_active = "true";
      // } else if (filterIsActive === false) {
      //   filter.is_active = "false";
      // }
      // if (filterIsActive === "select") {
      //   delete filter.is_active;
      // }

    return filter;

  }

  const searchData = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        en_title: filterENTitle,
        user_id:filterUser,
        status_id:filterStatus,
        type_id: filterType,
        is_bookmark :filterIsBookMark
      }

      const filter  = commonWebtoonFilterOptions(filterObject)

      setWebtoonFilter(filter)
      getWebtoonList(filter);
      setFirstTimeFilterActive(false);
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const removeSearch = () => {
    setFilterLoading(true);
    showLoader();
    setFilterENTitle("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterIsBookMark("");
    // setFilterUniverse("");
    setFilterUser("");
    setFilterStatus("");
    setFilterType("");
    // setFilterIsActive("");
    // setFilterOnGoing("");
    // setUniverseSelected(null);
    setWebtoonFilter('')
    setUserSelected(null);
    setStatusSelected(null);
    setTypeSelected(null);
    setFirstTimeFilterActive(false)
    setIsRedirectFirstPage(1)
    setPage(1)
    // selectInputRefs.current.props.value.label = "select";
    selectInputRef.current.props.value.label = "select";
    const filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };
    navigate('/admin/webtoon/webtoon-list', { replace: true });
    getWebtoonList(filter);
    setFilterLoading(false);
    hideLoader();
  };

  const handleFilterWithPagination = async() => {

    const filter = commonWebtoonFilterOptions(webtoonFilter);

    const webtoonFilterValue = filter.from_date || filter.to_date || filter.en_title || filter.user_id ||  filter.status_id || filter.type_id||filter.is_bookmark || filter.is_bookmark == 'false';

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (webtoonFilterValue)){
          setPage(1)
          getWebtoonList(filter);
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (webtoonFilterValue)){
        setPage(1)
        getWebtoonList(filter);
        return;
      }
    }  

    getWebtoonList(filter);
      
  }

  useEffect(() => {
    // getUniverseList();
    getStatusList();
    getTypeList();
    getUserList();
    // eslint-disable-next-line
  }, [page, per_Page, sort_key, sort_by]);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])


  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateWebtoonStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };

  const fetchUniverseDetail = async (unvierseId) => {
    if (unvierseId && unvierseId !== null) {
      setOpenUniverseModel(true);
      const res = await getUniverseSummaryById(unvierseId);

      if (res && res.isSuccess) {
        setUniverseSummaryData(res.data);
      }
    }
  };

  const fetchAuthorsDetail = async (authorId) => {
    if (authorId && authorId !== null) {
      setOpenAuthorModel(true);
      const res = await getAuthorSummaryById(authorId);
      if (res && res.isSuccess) {
        setAuthorSummaryData(res.data);
      }
    }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
    if (illustratorId && illustratorId !== null) {
      setOpenIllustratorModel(true);
      const res = await getIllustratorSummaryById(illustratorId);
      if (res && res.isSuccess) {
        setIllustratorSummaryData(res.data);
      }
    }
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("webtoon",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
  }
  
  const handleLightNovelFilterSet = useCallback(()=> {
    if(enTitle|| fromDate|| toDate || filterStatus || filterType || filterUser || filterIsBookMark){
      setIsRedirectFirstPage(0)
    
      if(filterUser){
        const selectedUser = userList.filter((data)=> filterUser.includes(data.id));
        const finalSelectedUserResponse = selectedUser.map((x)=>{
          return {
            value: x.id,
            label: x.first_name,
          };
        })
  
        setUserSelected(finalSelectedUserResponse)
      }
  
      if(filterStatus){
        const selectedStatus = statusList.filter((data)=> filterStatus.includes(data.id));
        const finalSelectedStatusResponse = selectedStatus.map((x)=>{
          return {
            value: x.id,
            label: x.name,
          };
        })
        setStatusSelected(finalSelectedStatusResponse)
      }

      if(filterType){
        const selectedType = typeList.filter((data)=> filterType.includes(data.id));
        const finalSelectedTypeResponse = selectedType.map((x)=>{
          return {
            value: x.id,
            label: x.name,
          };
        })
  
        setTypeSelected(finalSelectedTypeResponse)
      }
      callAPI()
    }
  },[enTitle,filterFromDate,filterToDate,filterType,filterStatus,filterUser,filterIsBookMark])
  
  useEffect(()=>{
    handleLightNovelFilterSet()
  },[enTitle,fromDate,toDate,typeIdsArray,userIdsArray,statusIdsArray,is_bookmark])

  const getUrl = (baseUrl) => {
    if(page){
      baseUrl += `?page=${page}`
    }

    if(filterENTitle){
      baseUrl+=`&en_title=${filterENTitle}`
    }

    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }

    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterUser && filterUser.length > 0 ){
      const userIds = filterUser.join(',');
      baseUrl+= `&user_id=${userIds}`
    }

    if(filterType && filterType.length > 0 ){
      const typeIds = filterType.join(',');
      baseUrl += `&type_id=${typeIds}`
    }

    if(filterStatus && filterStatus.length > 0 ){
      const statusIds = filterStatus.join(',');
      baseUrl += `&status_id=${statusIds}`
    }

    if(filterIsBookMark || filterIsBookMark === false || filterIsBookMark === 'false' || filterIsBookMark === 'none'){
      baseUrl +=`&is_bookmark=${filterIsBookMark}`
    }

    return baseUrl;
  }

  const updateWebtoonFlagStatus = async (webtoonId) => {
    const filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };
  
    await webtoonUpdateFlagStatus({
      webtoon_id: webtoonId,
    });
  
    getWebtoonList(filter);
  };
  

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Webtoon List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-webtoon" ? (
                          <Button
                            color="primary"
                            onClick={() => {
                              navigate("/admin/webtoon/create-Webtoon");
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">
                                Add Webtoon
                              </span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}
                    <Button onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen} {...args}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              EN Title
                            </label>
                            <Input
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={filterENTitle}
                              onChange={(event) => {
                                setFilterENTitle(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">User</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="user_id"
                              id="user_id"
                              className="react-select"
                              options={Array.from(userList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.first_name,
                                };
                              })}
                              value={userSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUserSelected(selected);
                                setFilterUser(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {/*<Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Universe
                            </label>
                            <Select
                              name="universe_id"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              id="universe_id"
                              className="react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={universeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUniverseSelected(selected);
                                setFilterUniverse(value);
                              }}
                            />
                          </FormGroup>
                        </Col>*/}
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Status</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="status_id"
                              id="status_id"
                              className="react-select"
                              options={Array.from(statusList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={statusSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setStatusSelected(selected);
                                setFilterStatus(value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="type_id"
                              id="type_id"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={typeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setTypeSelected(selected);
                                setFilterType(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is BookMark
                            </label>
                            <Select
                              name="is_bookmark"
                              id="is_bookmark"
                              className="react-select"
                              defaultValue={{
                                label: filterIsBookMark == 'true' ? "Yes" : filterIsBookMark == 'false'  ? "No" : filterIsBookMark == 'none'?'None':'Select',
                              }}
                              options={Array.from([{id:"none",name:'None'},...isBookMark]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsBookMark(event.value);
                              }}
                              ref={selectInputRef}
                            />
                          </FormGroup>
                        </Col>
                        {/*<Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Ongoing
                            </label>
                            <Select
                              ref={selectInputRefs}
                              name="ongoing"
                              id="ongoing"
                              className="react-select"
                              defaultValue={{
                                label: "Select",
                                value: "select",
                              }}
                              options={Array.from(onGoing).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(e) => {
                                setFilterOnGoing(e.value);
                              }}
                            />
                          </FormGroup>
                        </Col>*/}
                      </Row>
                      {/*<Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              ref={selectInputRef}
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: "Select",
                                value: "select",
                              }}
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(e) => {
                                setFilterIsActive(e.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>*/}
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={searchData}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={removeSearch}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th
                              style={{ cursor: "pointer" }}
                              className="d-sm-none"
                              onClick={() =>
                                sortColumns("status_name", sort_by)
                              }>
                              Status
                              {sort_key === "status_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th title="Book Mark">BM</th>
                            <th title="Flag Status">FS</th>
                            <th>No.</th>
                            <th
                            className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("en_title", sort_by)}>
                              EN Title
                              {sort_key === "en_title" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th>Cover</th>

                            <th
                              style={{ cursor: "pointer" }}
                              className="d-none d-md-table-cell" 
                              onClick={() =>
                                sortColumns("status_name", sort_by)
                              }>
                              Status
                              {sort_key === "status_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>

                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} 
                                alt="rating-1" 
                                style={{ width: '20px' }} 
                              />
                            </th>
                            
                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} 
                                alt="rating-1" 
                                style={{ width: '20px' }} 
                              />
                            </th>

                            <th>
                              <img 
                                src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} 
                                alt="rating-1" 
                                style={{ width: '18px' }} 
                              />
                            </th>

                            <th
                            className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("universe_name", sort_by)
                              }>
                              Universe
                              {sort_key === "universe_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>

                            <th
                            className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("jp_title", sort_by)}>
                              JP Title
                              {sort_key === "jp_title" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("kr_title", sort_by)}>
                              KR Title
                              {sort_key === "kr_title" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                         
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("no_of_volumes", sort_by)
                              }>
                              No. of chpt
                              {sort_key === "no_of_volumes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("ongoing", sort_by)}>
                              Ongoing
                              {sort_key === "ongoing" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("type_name", sort_by)}>
                              Type
                              {sort_key === "type_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_platform", sort_by)
                              }>
                              JP PlateForm
                              {sort_key === "jp_platform" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("kr_platform", sort_by)
                              }>
                              KR PlateForm
                              {sort_key === "kr_platform" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("us_platform", sort_by)
                              }>
                              US PlateForm
                              {sort_key === "us_platform" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("fr_platform", sort_by)
                              }>
                              FR PlateForm
                              {sort_key === "fr_platform" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_platform_likes", sort_by)
                              }>
                              JP PlateForm Likes
                              {sort_key === "jp_platform_likes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("kr_platform_likes", sort_by)
                              }>
                              KR PlateForm Likes
                              {sort_key === "kr_platform_likes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("us_platform_likes", sort_by)
                              }>
                              US PlateForm Likes
                              {sort_key === "us_platform_likes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("fr_platform_likes", sort_by)
                              }>
                              FR PlateForm Likes
                              {sort_key === "fr_platform_likes" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-webtoon-product-pl" ? (
                                  <th
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      sortColumns("product_pl", sort_by)
                                    }
                                  >
                                    Product P&L
                                    {sort_key === "product_pl" &&
                                      (sort_by === "ASC" ? (
                                        <i className="fa fa-arrow-up ml-1"></i>
                                      ) : (
                                        <i className="fa fa-arrow-down ml-1"></i>
                                      ))}
                                  </th>
                                ) : (
                                  ""
                                )
                              )}
                            <th
                            className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_wikipedia_link", sort_by)
                              }>
                              JP Wikipedia
                              {sort_key === "jp_wikipedia_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                            className="link"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("kr_wikipedia_link", sort_by)
                              }>
                              KR Wikipedia
                              {sort_key === "kr_wikipedia_link" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th>ScanTrad</th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-webtoon-low-sale" ? (
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("low_sales", sort_by)
                                      }>
                                      Low Sales
                                      {sort_key === "low_sales" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}
                                    </th>
                                ) : (
                                  ""
                                )
                              )}

                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-webtoon-medium-sale" ? (
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("medium_sales", sort_by)
                                      }>
                                        Medium Sales
                                        {sort_key === "medium_sales" &&
                                          (sort_by === "ASC" ? (
                                            <i className="fa fa-arrow-up ml-1"></i>
                                          ) : (
                                            <i className="fa fa-arrow-down ml-1"></i>
                                          ))}
                                    </th>
                                ) : (
                                  ""
                                )
                              )}

                            {permissions &&
                              permissions.map((permission) =>
                                permission === "view-webtoon-high-sale" ? (
                                  <th
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    sortColumns("high_sales", sort_by)
                                  }>
                                  High Sales
                                  {sort_key === "high_sales" &&
                                    (sort_by === "ASC" ? (
                                      <i className="fa fa-arrow-up ml-1"></i>
                                    ) : (
                                      <i className="fa fa-arrow-down ml-1"></i>
                                    ))}
                                </th>
                                ) : (
                                  ""
                                )
                              )}
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns(
                                  "fr_consumer_price",
                                  sort_by
                                )
                              }>
                              FR Consumer Price
                              {sort_key === "fr_consumer_price" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("authors", sort_by)}>
                              Authors
                              {sort_key === "authors" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("illustrators", sort_by)
                              }>
                              Illustrator
                              {sort_key === "illustrators" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("genres", sort_by)}>
                              Genre
                              {sort_key === "genres" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("publics", sort_by)}>
                              Public
                              {sort_key === "publics" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-webtoon" ? (
                                  <>
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("is_active", sort_by)
                                      }>
                                      Is Active
                                      {sort_key === "is_active" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}{" "}
                                    </th>{" "}
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {webtoonLists.length > 0 ? (
                            webtoonLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              const randomIndexNumber = randomIndex();  
                              return (
                                <tr
                                  key={info.id}
                                  id={`webtoon-list-record-${info.id}`}
                                  style={{
                                    backgroundColor: info.flag_to_me
                                      ? colors[randomIndexNumber]
                                      : "",
                                  }}>
                                  <td className="d-sm-none">{info.status_name}</td>
                                  <td>
                                    <Checkbox
                                        {...label}
                                        icon={<BookmarkBorderIcon />}
                                        checkedIcon={<BookmarkIcon />}
                                        defaultChecked={info?.bookmark_id !== null ? true : false}
                                        onClick={(e)=>handleWebtoonBookMark({isChecked:e.target.checked,webtoonId:info?.id})}
                                    />
                                  </td>
                                  <td>
                                    {info?.flag_to_me ? (
                                      <Switch size="small" defaultChecked={info.flag_to_me ? true : false} onClick={()=>updateWebtoonFlagStatus(info.id)} disabled = {info.flag_to_me ? false : true}/>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{indexNo}</td>
                                  <td className="word_wrap" style={{ cursor: "pointer" }}>
                                    {info.flag_to_me ? (
                                      <>
                                        <i
                                          className={
                                            "mr-2 fa fa-info-circle tooltip" +
                                            info.id
                                          }
                                          aria-hidden="true"></i>

                                        <Tooltip
                                          anchorSelect={".tooltip" + info.id}>
                                          {info.flag_to_me?.map((flag) => (
                                            <li>
                                              {flag.first_name} {flag.last_name}
                                            </li>
                                          ))}
                                        </Tooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Link
                                      className="primary-text-color"
                                      onClick={async () => {
                                        const res = await getWebtoonById({
                                          webtoon_id: info.id,
                                        });

                                        let baseURL = `/admin/webtoon/view-webtoon/${info.id}`;
                                        const url = getUrl(baseURL);

                                        navigate(
                                          url,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                  </td>
                                  <td>
                                    {info.cover_image ? (
                                      <>
                                        <img
                                          className="cover_image"
                                          src={info.cover_image}
                                          onClick={async () => {
                                            setShowModal(true);
                                            setImage(info.cover_image);
                                          }}
                                          ref={imgRef}
                                          alt="book-cover"
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="d-none d-md-table-cell">{info.status_name}</td>
                                  <td 
                                    className={`text-center ${info.rating_one_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_one_counts !== "0"){
                                        setRatingType(1);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_one_counts}
                                  </td>
                                  <td 
                                    className={`text-center ${info.rating_two_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_two_counts !== "0"){
                                        setRatingType(2);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_two_counts}
                                  </td>
                                  <td 
                                    className={`text-center ${info.rating_three_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`} 
                                    onClick={()=>{
                                      if(info.rating_three_counts !== "0"){
                                        setRatingType(3);
                                        setRatingInfo(info);
                                        setOpenUserRatingListModel(true);
                                        }
                                    }} 
                                  >
                                    {info.rating_three_counts}
                                  </td>
                                  <td
                                  className="word_wrap primary-text-color"
                                    onClick={() =>
                                      fetchUniverseDetail(info.universe_id)
                                    }
                                    style={{
                                      cursor: `${
                                        info.universe_id !== null
                                          ? "pointer"
                                          : "auto"
                                      }`,
                                    }}>
                                    {info.universe_name}
                                  </td>
                                  <td className="word_wrap">{info.jp_title}</td>
                                  <td>{info.kr_title}</td>
                                  <td>{info.no_of_volumes}</td>
                                  <td>
                                    {info.ongoing === true
                                      ? "Yes"
                                      : info.ongoing === false
                                      ? "No"
                                      : ""}
                                  </td>
                                  <td>{info.type_name}</td>
                                  <td>{info.jp_platform}</td>
                                  <td>{info.kr_platform}</td>
                                  <td>{info.us_platform}</td>
                                  <td>{info.fr_platform}</td>
                                  <td>{info.jp_platform_likes}</td>
                                  <td>{info.kr_platform_likes}</td>
                                  <td>{info.us_platform_likes}</td>
                                  <td>{info.fr_platform_likes}</td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "view-webtoon-product-pl" ? (
                                        <td className="link primary-text-color">
                                            <a
                                              href={info.product_pl}
                                              target="_blank"
                                              rel="noreferrer">
                                              {info.product_pl}
                                            </a>
                                        </td>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  <td className="link primary-text-color">
                                    {" "}
                                    <a
                                      href={info?.jp_wikipedia_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info?.jp_wikipedia_link}
                                    </a>
                                  </td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info?.kr_wikipedia_link}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info?.kr_wikipedia_link}
                                    </a>
                                  </td>
                                  <td className="link primary-text-color">
                                    <a
                                      href={info?.scantrad}
                                      target="_blank"
                                      rel="noreferrer">
                                      {info?.scantrad}
                                    </a>
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "view-webtoon-low-sale" ? (
                                          <td>{info.low_sales}</td>
                                      ) : (
                                        ""
                                      )
                                    )}

                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "view-webtoon-medium-sale" ? (
                                          <td>{info.medium_sales}</td>
                                      ) : (
                                        ""
                                      )
                                    )}

                                 {permissions &&
                                    permissions.map((permission) =>
                                      permission === "view-webtoon-high-sale" ? (
                                          <td>{info.high_sales}</td>
                                      ) : (
                                        ""
                                      )
                                    )}
                                    
                                  <td>{info.fr_consumer_price}</td>
                                  <td
                                    className="primary-text-color"
                                    style={{
                                      cursor: "pointer",
                                    }}>
                                    {info.authors.map((author, index) => (
                                      <span
                                        key={index}
                                        onClick={() =>
                                          fetchAuthorsDetail(author.author_id)
                                        }>
                                        {author.name}
                                        {index !== info.authors.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td
                                    className="primary-text-color"
                                    style={{
                                      cursor: "pointer",
                                    }}>
                                    {info.illustrators.map(
                                      (illustrator, index) => (
                                        <span
                                          key={index}
                                          onClick={() =>
                                            fetchIllustratorDetail(
                                              illustrator.illustrator_id
                                            )
                                          }>
                                          {illustrator.name}
                                          {index !==
                                            info.illustrators.length - 1 &&
                                            ", "}
                                        </span>
                                      )
                                    )}
                                  </td>
                                  <td>
                                    {info.genres.map((genre, index) => (
                                      <span key={index}>
                                        {genre.name}
                                        {index !== info.genres.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td>
                                    {info.publics.map((publicsD, index) => (
                                      <span key={index}>
                                        {publicsD.name}
                                        {index !== info.publics.length - 1 &&
                                          ", "}
                                      </span>
                                    ))}
                                  </td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-webtoon" ? (
                                        <>
                                          <td>
                                            <label className="custom-toggle">
                                              <input
                                                onChange={() => {}}
                                                onClick={() => {
                                                  handleStatus({
                                                    id: info.id,
                                                    is_active: !info.is_active,
                                                  });
                                                }}
                                                value={info.is_active === true}
                                                defaultChecked={
                                                  info.is_active === true
                                                }
                                                type="checkbox"
                                              />
                                              <span className="custom-toggle-slider  rounded-circle" />
                                            </label>
                                          </td>
                                          <td
                                            className="action"
                                            style={{
                                              backgroundColor: info.flag_to_me
                                                ? colors[randomIndexNumber]
                                                : "",
                                            }}>
                                            <Button
                                              color="primary"
                                              onClick={async () => {
                                                const res =
                                                  await getWebtoonById({
                                                    webtoon_id: info.id,
                                                  });

                                                  let baseURL = `/admin/webtoon/update-webtoon/${info.id}`;
                                                  const url = getUrl(baseURL);
                                                 
                                                  navigate(
                                                    url,
                                                    { state: { row: res?.data } }
                                                  );
                                              }}>
                                              {isLoading && (
                                                <span
                                                  className="indicator-progress"
                                                  style={{ display: "block" }}>
                                                  Please Wait..
                                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                </span>
                                              )}
                                              {!isLoading && (
                                                <span className="indicator-label">
                                                  Edit
                                                </span>
                                              )}
                                            </Button>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink
                    className={"p-0 " + (page !== 1 ? "active-button" : "")}
                    disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      disabled={page === 1}
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink
                    className={
                      "p-0 " + (page !== totalPages ? "active-button" : "")
                    }
                    disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      disabled={page === totalPages}
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
        <ImageModal
          show={show}
          image={image}
          onClose={() => {
            setShowModal(false);
          }}
        />
        <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData}/>
        <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData }/>
        <IllustratorModal openIllustratorModel = {openIllustratorModel} setOpenIllustratorModel={setOpenIllustratorModel} illustratorSummaryData={illustratorSummaryData} />
        <RatingUserListModal openUserRatingListModel={openUserRatingListModel} setOpenUserRatingListModel={setOpenUserRatingListModel} ratingInfo={ratingInfo} ratingType = {ratingType} title = "Webtoon Rating Details" />
      </Container>
      {loader}
    </>
  );
}

export default WebtoonList;

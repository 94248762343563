const originalMedium = [
    {
      name: "LIGHT NOVEL",
      id: "LIGHT_NOVEL",
    },
    {
      name: "MANGA",
      id: "MANGA",
    },
    {
      name: "WEBTOON",
      id: "WEBTOON",
    },
    {
      name: "ANIME",
      id: "ANIME",
    },
    {
      name: "VIDEO GAME",
      id: "VIDEO_GAME",
    },
  ];

export {originalMedium}
const isActive = [
  {
    name: "Yes",
    id: true,
  },
  {
    name: "No",
    id: false,
  },
];

export {isActive}

const publisherType = [
  {
    name: "JP",
    id: "JP",
  },
  {
    name: "FR",
    id: "FR",
  },
  {
    name: "US",
    id: "US",
  },
  {
    name: "DE",
    id: "DE",
  }
];
export {publisherType}
const onGoing = [
  {
    name: "None",
    id: "none",
  },
  {
    name: "Yes",
    id: true,
  },
  {
    name: "No",
    id: false,
  },
];
export {onGoing}


const colors = [
  "#F0FFF0",
  "#F0FFFF",
  "#FFFFF0",
  "#FFFAFA",
  "#F8F8FF",
  "#F0F8FF",
  "#F4F3F0",
  "#EDEBF9",
  "#FFF0F5",
  "#E8FDF1"

];
export {colors}

const isBookMark = [
  {
    name: "Yes",
    id: true,
  },
  {
    name: "No",
    id: false,
  },
];

export {isBookMark}

const isPageReload = {  
    LIGHT_NOVEL: "LIGHT NOVEL",
    MANGA: "MANGA",
    WEBTOON: "WEBTOON",
    AUTHOR:"AUTHOR",
    UNIVERSE:"UNIVERSE"
}
  
export default isPageReload
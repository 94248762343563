import Header from "components/Headers/Header";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Select from "react-select";
import { createAuthor } from "utils/endpoint";
import * as yup from "yup";
import clsx from "clsx";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
});

function CreateAuthor() {
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();

  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        jp_name: "",
        jp_wikipedia: "",
        is_active: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const $inputData = {
          name: values.name,
          jp_name: values.jp_name,
          jp_wikipedia: values.jp_wikipedia,
          is_active: values.is_active,
        };
        const result = await createAuthor($inputData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          navigate("/admin/author/author-list");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />
            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Create Author</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/author/author-list");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">EN Name</label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">JP Name</label>
                            <Input
                              className="form-control"
                              id="jp_name"
                              name="jp_name"
                              placeholder="JP Name"
                              type="text"
                              value={values.jp_name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col> 
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">JP Wikipedia</label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia"
                              name="jp_wikipedia"
                              placeholder="JP Wikipedia"
                              type="text"
                              value={values.jp_wikipedia}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className=""
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("is_active", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Create Author
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default CreateAuthor;

import { ErrorMessage, Formik } from "formik";
import React from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useEffect, useState } from "react";
import { get_type_list } from "utils/Commen";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import clsx from "clsx";
import Header from "components/Headers/Header";
import { createPrePublication } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";

const validationSchema = yup.object({
  en_title: yup.string().required("EN Title is required"),
});

const CreatePrePublication = () => {
  const [typeList, setTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const navigate = useNavigate();

  const getTypeList = async () => {
    const allTYpe = await get_type_list();
    setTypeList([{ id: "", name: "None" }, ...allTYpe]);
  };

  useEffect(() => {
    getTypeList();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        en_title: "",
        jp_title: "",
        label: "",
        company: "",
        type: "",
        link: "",
        is_active: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const $inputData = {
          en_title: values.en_title,
          jp_title: values.jp_title,
          label: values.label,
          company: values.company,
          type: values.type !== 'None' ? values.type : null,
          link: values.link,
          is_active: values.is_active,  
        };
       
        const result = await createPrePublication($inputData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          navigate("/admin/prePublication/Prepublication-list");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Create Pre-Publication</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/prePublication/Prepublication-list");
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                     
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                              EN Title
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.en_title) && touched.en_title,
                              })}
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={values.en_title}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="en_title" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Japanese Title
                            </label>
                            <Input
                              className="form-control"
                              id="jp_title"
                              name="jp_title"
                              placeholder="Japanese Title"
                              type="text"
                              value={values.jp_title}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Label</label>
                            <Input
                              className="form-control"
                              id="label"
                              name="label"
                              placeholder="Label"
                              type="text"
                              value={values.label}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Company</label>
                            <Input
                              className="form-control"
                              id="company"
                              name="company"
                              placeholder="Company"
                              type="text"
                              value={values.company}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                       
                        </Row>
                        <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Type</label>
                            <Select
                              name="type"
                              id="type"
                              className="react-select"
                              options={Array.from(typeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("type", event.label);
                              }}
                            />
                          </FormGroup>
                        </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label">Link</label>
                              <Input
                                className="form-control"
                                id="link"
                                name="link"
                                placeholder="Link"
                                type="text"
                                value={values.link}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className=""
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("is_active", event.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <Button
                              type="submit"
                              className="my-3 w-100"
                              color="primary">
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}>
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!loading && (
                                <span className="indicator-label">
                                  Create Pre-Publication
                                </span>
                              )}
                            </Button>
                          </Col>
                        </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default CreatePrePublication;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header";
import { videoGameList, getVideoGameById } from "utils/endpoint";
import { updateVideoGameStatus } from "utils/endpoint";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  get_universes_list,
  get_publisher_list,
  get_User_list,
} from "utils/Commen";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { getUniverseSummaryById } from "utils/endpoint";
import { Pagination as Paginationes } from "antd";
import UniverseModal from "utils/universeModal";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
const isActive = [
  {
    name: "None",
    id: 'none',
  },
  {
    name: "Active",
    id: true,
  },
  {
    name: "InActive",
    id: false,
  },
];
function VideoGameList(args) {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const enTitle = params.enTitle;
  const is_active = params.is_active;
  const userIdsArray = params.userIdsArray;
  const universeIdsArray = params.universeIdsArray;
  const jpPublisherIdsArray = params.jpPublisherIdsArray;
  const frPublisherIdsArray = params.frPublisherIdsArray;
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [isLoading, setIsLoading] = useState(false);
  const [videoGameLists, setVideoGameLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [isOpen, setIsOpen] = React.useState(false);
  const [universeList, setUniverseList] = useState([]);
  const [frPublisherList,setFRPublisherList] = useState([]);
  const [jpPublisherList,setJPPublisherList] = useState([]);

  const [userList, setUserList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterENTitle, setFilterENTitle] = useState(enTitle || "");
  const [filterUniverse, setFilterUniverse] = useState(universeIdsArray || "");
  const [filterUser, setFilterUser] = useState(userIdsArray || "");
  const [filterIsActive, setFilterIsActive] = useState(is_active || "");
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  const [filterFRPublisher, setFilterFRPublisher] = useState(frPublisherIdsArray || "");
  const [filterJPPublisher, setFilterJPPublisher] = useState(jpPublisherIdsArray || "");
  const [universeSelected, setUniverseSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [frPublisherSelected, setFRPublisherSelected] = useState(null);
  const [jPPublisherSelected, setJPPublisherSelected] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [videoGameFilter,setVideoGameFilter] = useState('')
  const selectInputRef = useRef(null);

  const toggle = () => setIsOpen(!isOpen);
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: mySortKey,
      sort_by: mySortBy,
    };

    if (new Date(filterFromDate) <= new Date(filterToDate)) {
      filter.from_date = filterFromDate;
    }
    if (new Date(filterToDate) >= new Date(filterFromDate)) {
      filter.to_date = filterToDate;
    }
    // if (filterENTitle) {
    //   filter.en_title = filterENTitle;
    // }
    // if (filterUniverse) {
    //   filter.universe_id = filterUniverse;
    // }
    // if (filterUser) {
    //   filter.user_id = filterUser;
    // }

    // if (filterJPPublisher) {
    //   filter.jp_publisher_id = filterJPPublisher;
    // }
    // if (filterFRPublisher) {
    //   filter.jp_publisher_id = filterFRPublisher;
    // }
    // if (filterIsActive === true) {
    //   filter.is_active = "true";
    // } else if (filterIsActive === false) {
    //   filter.is_active = "false";
    // }
    // if (filterIsActive === "select") {
    //   delete filter.is_active;
    // }

    if(videoGameFilter){
      let filter = videoGameFilter;
      filter.sort_by= mySortBy;
    }
    getVideoGameList(filter);
  };
  const getVideoGameList = async (filter) => {
    setVideoGameLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await videoGameList(filter);
      if (result?.isSuccess) {
        setVideoGameLists(result?.data?.data?.records);

        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setIsLoading(false);
        hideLoader();
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
      } else {
        setIsLoading(false);
        hideLoader();
        setVideoGameLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setVideoGameLists([]);
    }
  };
  // const prevPage = async () => {
  //   setPage(page - 1);
  // };

  // const nextPage = async () => {
  //   setPage(page + 1);
  // };
  const getUniverseList = async () => {
    const allUniverses = await get_universes_list();
    setUniverseList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...allUniverses]);
  };
  const getPublisherList = async () => {
    const allPublisher = await get_publisher_list();
    const FRPublisherList = allPublisher.filter((publisher) => {
      return publisher.publisher_type === "FR";
    });
    const JPPublisherList = allPublisher.filter((publisher) => {
      return publisher.publisher_type === "JP";
    });

    setFRPublisherList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...FRPublisherList])
    setJPPublisherList([{id:'8f97245b-5d1a-4dfd-8158-7211baf22e82',name:'None'},...JPPublisherList])
  };
  const getUserList = async () => {
    const allUser = await get_User_list();
    setUserList(allUser);
  };

  const commonVideoGameFilterOptions = (videoGameFilterObject) => {

    let filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    };
   
    const from_date = videoGameFilterObject?.from_date;
    const to_date = videoGameFilterObject?.to_date;
    const en_title = videoGameFilterObject?.en_title;
    const user_id =  videoGameFilterObject?.user_id;
    const universe_id = videoGameFilterObject?.universe_id;
    const jp_publisher_id = videoGameFilterObject?.jp_publisher_id;
    const french_publisher_id = videoGameFilterObject?.french_publisher_id;
    const is_active = videoGameFilterObject?.is_active;

    if(en_title){
      filter.en_title = en_title.replace(/'/g, "''");
    }

    if(new Date(from_date) <= new Date(to_date)){
      filter.from_date = from_date;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter.to_date = to_date;
    }

    if(user_id){
      filter.user_id = user_id;
    }

    if (universe_id) {
       filter.universe_id = universe_id;
    }

    if (jp_publisher_id) {
      filter.jp_publisher_id = jp_publisher_id;
    }
    if (french_publisher_id) {
      filter.french_publisher_id = french_publisher_id;
    }

    if (is_active === true) {
      filter.is_active = is_active ;
    } else if (is_active === false) {
      filter.is_active = is_active ;
    }
    if (is_active === "select") {
      delete filter.is_active;
    }


    return filter;

  }


  const searchData = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        en_title: filterENTitle,
        user_id:filterUser,
        universe_id: filterUniverse,
        jp_publisher_id:filterJPPublisher,
        french_publisher_id:filterFRPublisher,
        is_active : filterIsActive
      }

      const filter  = commonVideoGameFilterOptions(filterObject)
      setVideoGameFilter(filter)
      getVideoGameList(filter);
      setFirstTimeFilterActive(false);
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const removeSearch = () => {
    setFilterLoading(true);
    showLoader();
    setFilterENTitle("");
    setFilterUniverse("");
    setFilterUser("");
    setFilterJPPublisher("");
    setFilterFRPublisher("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterIsActive("");
    setVideoGameFilter('')
    setUniverseSelected(null);
    setUserSelected(null);
    setJPPublisherSelected(null);
    setFRPublisherSelected(null);
    setFirstTimeFilterActive(false)
    setPage(1)
    setIsRedirectFirstPage(1)
    selectInputRef.current.props.value.label = "select";
    const filter = {
      page: page,
      per_page: per_Page,
      sort_key: sort_key,
      sort_by: sort_by,
    }
    navigate('/admin/videoGame/videoGame-list', { replace: true });
    getVideoGameList(filter);
    setFilterLoading(false);
    hideLoader();
  };

  const handleFilterWithPagination = async() => {

    const filter = commonVideoGameFilterOptions(videoGameFilter);

    const videoGameFilterValue = filter.from_date || filter.to_date || filter.jp_publisher_id || filter.french_publisher_id || filter.en_title || filter.user_id ||  filter.universe_id || filter.is_active || filter.is_active === false ;

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (videoGameFilterValue)){
          setPage(1)
          getVideoGameList(filter);;
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (videoGameFilter)){
        setPage(1)
        getVideoGameList(filter);;
        return;
      }
    } 

    getVideoGameList(filter);;
      
  }

  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateVideoGameStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };
  useEffect(() => {
    getUniverseList();
    getPublisherList()
    getUserList();
    // eslint-disable-next-line
  }, [page, per_Page,sort_key,sort_by]);

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  const fetchUniverseDetail = async (unvierseId) => {
    if (unvierseId && unvierseId !== null) {
      setOpenUniverseModel(true);
      const res = await getUniverseSummaryById(unvierseId);

      if (res && res.isSuccess) {
        setUniverseSummaryData(res.data);
      }
    }
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("video-game",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
  }
  
  const handleVideoGameFilterSet = useCallback(()=> {
    if(enTitle|| fromDate|| toDate || filterUniverse || filterUser || filterJPPublisher || filterFRPublisher || filterIsActive){
      setIsRedirectFirstPage(0)

      if(filterUser){
        const selectedUser = userList.filter((data)=> filterUser.includes(data.id));
        const finalSelectedUserResponse = selectedUser.map((x)=>{
          return {
            value: x.id,
            label: x.first_name,
          };
        })
  
        setUserSelected(finalSelectedUserResponse)
      }
  
      if(filterUniverse){
        const selectedUniverse = universeList.filter((data)=> filterUniverse.includes(data.id));
        const finalSelectedUniverseResponse = selectedUniverse.map((x)=>{
          return {
            value: x.id,
            label: x.name,
          };
        })
        setUniverseSelected(finalSelectedUniverseResponse)
      }

      if(filterJPPublisher){
        const selectedJPPublisher = jpPublisherList.filter((data)=> filterJPPublisher.includes(data.id));
        const finalSelectedJPPublisherResponse = selectedJPPublisher.map((x)=>{
          return {
            value: x.id,
            label: x.name,
          };
        })
        setJPPublisherSelected(finalSelectedJPPublisherResponse)
      }

      if(filterFRPublisher){
        const selectedFRPublisher = frPublisherList.filter((data)=> filterFRPublisher.includes(data.id));
        const finalSelectedFRPublisherResponse = selectedFRPublisher.map((x)=>{
          return {
            value: x.id,
            label: x.name,
          };
        })
        setFRPublisherSelected(finalSelectedFRPublisherResponse)
      }
  
      callAPI()
    }
  },[enTitle,filterFromDate,filterToDate,filterUniverse,filterIsActive,filterUser,filterFRPublisher,filterJPPublisher])
  
  useEffect(()=>{
    handleVideoGameFilterSet()
  },[enTitle,fromDate,toDate,userIdsArray,is_active,universeIdsArray,frPublisherIdsArray,jpPublisherIdsArray])

  const getUrl = (baseUrl) => {

    if(page){
      baseUrl += `?page=${page}`
    }
  
    if(filterENTitle){
      baseUrl+=`&en_title=${filterENTitle}`
    }
  
    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }
  
    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterUser && filterUser.length >0 ){
      const userIds = filterUser.join(',');
      baseUrl+= `&user_id=${userIds}`
    }

    if(filterIsActive || filterIsActive === false || filterIsActive === 'none'){
      baseUrl +=`&is_active=${filterIsActive}`
    }
  
    if(filterUniverse && filterUniverse.length >0) {
      const universeIds = filterUniverse.join(',');
      baseUrl += `&universe_id=${universeIds}`
    }

    if(filterJPPublisher && filterJPPublisher.length > 0 ) {
      const jpPublisherIds = filterJPPublisher.join(',');
      baseUrl += `&jp_publisher_id=${jpPublisherIds}`
    }

    if(filterFRPublisher && filterFRPublisher.length >0 ) {
      const frPublisherIds = filterFRPublisher.join(',');
      baseUrl += `&fr_publisher_id=${frPublisherIds}`
    }
    
    return baseUrl;
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Video Game List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                  {permissions &&
                    permissions.map((permission) =>
                      permission === "create-video-game" ? (
                      <Button
                      color="primary"
                      onClick={() => {
                        navigate("/admin/videoGame/create-videoGame");
                      }}>
                      {isLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}>
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!isLoading && (
                        <span className="indicator-label">Add Video Game</span>
                      )}
                      </Button>
                      ) : (
                        ""
                      )
                    )}
                    <Button className="filter_btn" onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen} {...args}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              // ref={resetTo}
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              EN Title
                            </label>
                            <Input
                              id="en_title"
                              name="en_title"
                              placeholder="EN Title"
                              type="text"
                              value={filterENTitle}
                              onChange={(event) => {
                                setFilterENTitle(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Universe
                            </label>
                            <Select
                              name="universe_id"
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              id="universe_id"
                              className="react-select"
                              options={Array.from(universeList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              value={universeSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUniverseSelected(selected);
                                setFilterUniverse(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">User</label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="universe_id"
                              id="universe_id"
                              className="react-select"
                              options={Array.from(userList).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.first_name,
                                };
                              })}
                              value={userSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setUserSelected(selected);
                                setFilterUser(value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              JP Publisher
                            </label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="jp_publisher_id"
                              id="jp_publisher_id"
                              className="react-select"
                              options={Array.from(jpPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              value={jPPublisherSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setJPPublisherSelected(selected);
                                setFilterJPPublisher(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              FR Publisher
                            </label>
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              name="french_publisher_id"
                              id="french_publisher_id"
                              className="react-select"
                              options={Array.from(frPublisherList).map(
                                (value) => {
                                  return {
                                    value: value.id,
                                    label: value.name,
                                  };
                                }
                              )}
                              value={frPublisherSelected}
                              onChange={(selected) => {
                                const value = selected?.map((e) => e.value);
                                setFRPublisherSelected(selected);
                                setFilterFRPublisher(value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              ref={selectInputRef}
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: filterIsActive === "true" ? "Active" : filterIsActive === "false"  ? "InActive" : filterIsActive == 'none'?'None':'Select'
                              }}
                              options={Array.from(isActive).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(e) => {
                                setFilterIsActive(e.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={searchData}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={removeSearch}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th className="word_wrap" style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("en_title", sort_by)
                              }>EN Title
                              {sort_key === "en_title" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} </th>
                             <th
                             className="word_wrap"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("universe_name", sort_by)
                              }>
                              Universe
                              {sort_key === "universe_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("jp_publisher_name", sort_by)
                              }>
                              JP publisher
                              {sort_key === "jp_publisher_name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("jp_release_date", sort_by)
                              }>JP Release Date 
                              {sort_key === "jp_release_date" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("french_publisher_name", sort_by)
                              }> French publisher 
                              {sort_key === "french_publisher_name" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("french_release_date", sort_by)
                              }>French Release Date 
                              {sort_key === "french_release_date" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}</th>
                            <th className="link" style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("link", sort_by)
                              }>Link 
                              {sort_key === "link" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}</th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-video-game" ? (
                                  <>
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("is_active", sort_by)
                                      }>
                                      Is Active
                                      {sort_key === "is_active" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}{" "}
                                    </th>{" "}
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {videoGameLists.length > 0 ? (
                            videoGameLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id} id={`video-game-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td className="word_wrap">{info.en_title}</td>
                                  <td
                                  className="word_wrap primary-text-color"
                                    onClick={() =>
                                      fetchUniverseDetail(info.universe_id)
                                    }
                                    style={{
                                      cursor: `${
                                        info.universe_id !== null
                                          ? "pointer"
                                          : "auto"
                                      }`,
                                    }}
                                  >
                                    {info.universe_name}
                                  </td>
                                  <td>{info.jp_publisher_name}</td>
                                  <td>
                                    {info.jp_release_date
                                      ? moment(info.jp_release_date).format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""}
                                  </td>
                                  <td>{info.french_publisher_name}</td>
                                  <td>
                                    {info.french_release_date
                                      ? moment(info.french_release_date).format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""}
                                  </td>
                                  <td className="link primary-text-color"><a href={info.link} target="_blank" rel="noreferrer">{info.link}</a></td>

                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-video-game" ? (
                                        <>
                                        <td>
                                        <label className="custom-toggle">
                                          <input
                                            onChange={() => {}}
                                            onClick={() => {
                                              handleStatus({
                                                id: info.id,
                                                is_active: !info.is_active,
                                              });
                                            }}
                                            value={info.is_active === true}
                                            defaultChecked={info.is_active === true}
                                            type="checkbox"
                                          />
                                          <span className="custom-toggle-slider  rounded-circle" />
                                        </label>
                                      </td>
                                      <td className="action">
                                        <Button
                                          color="primary"
                                          onClick={async () => {
                                            const res = await getVideoGameById({
                                              videoGame_id: info.id,
                                            });
                                            let baseURL = `/admin/videoGame/update-videoGame/${info.id}`;
                                            const url = getUrl(baseURL);
                                            navigate(
                                              url,
                                              { state: { row: res?.data } }
                                            );
                                          }}>
                                          {isLoading && (
                                            <span
                                              className="indicator-progress"
                                              style={{ display: "block" }}>
                                              Please Wait..
                                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                            </span>
                                          )}
                                          {!isLoading && (
                                            <span className="indicator-label">
                                              Edit
                                            </span>
                                          )}
                                        </Button>
                                      </td>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (page !== 1 ? 'active-button' : '')} disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      disabled={page === 1}
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (page !== totalPages ? 'active-button' : '')} disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      disabled={page === totalPages}
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
        <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData}/>
      </Container>
      {loader}
    </>
  );
}

export default VideoGameList;

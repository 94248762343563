import moment from "moment";
import React, { forwardRef } from "react";
import { Card, CardBody, CardHeader, CardImg, Col, Row } from "reactstrap";

const WebtoonPreview = forwardRef(({ webtoonPreviewData }, ref) => {
  const currentDateTime = moment().format("YYYY-MM-DD, h:mm:ss");

  return (
    <div ref={ref}>
      <Card style={{padding:'30px'}}>
        <h3 className="text-right mx-5 text-dark mt-4">{currentDateTime}</h3>
        <Row>
          <Col lg="8">
            <Card className="shadow m-2 detail_card">
              <CardBody className="custom-margin-top">
                <Row>
                  {webtoonPreviewData?.en_title ? (
                    <>
                      <Col lg="4"> EN Title</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {webtoonPreviewData.en_title}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mt-1">
                  {webtoonPreviewData?.universe_name ? (
                    <>
                      <Col lg="4">Universe Name</Col>
                      <Col lg="8">
                        <span className="primary-text-color">
                          {webtoonPreviewData?.universe_name}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                <Row className="mt-1">
                  {webtoonPreviewData?.jp_title ? (
                    <>
                      <Col lg="4">JP Title</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.jp_title}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="mt-1">
                  {webtoonPreviewData?.kr_title ? (
                    <>
                      <Col lg="4">KR Title</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.kr_title}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {webtoonPreviewData?.status_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Status</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.status_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.no_of_volumes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">No. of chpt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.no_of_volumes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.ongoing ||
                webtoonPreviewData?.ongoing == false ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Ongoing</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData.ongoing === true ? "Yes" : "No"}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.type_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Type</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.type_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.jp_platform ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Platform</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.jp_platform}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.kr_platform ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">KR Platform</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData.kr_platform}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.fr_platform ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">FR Platform</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.fr_platform}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.us_platform ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">US Platform</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.us_platform}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.jp_platform_likes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Platform Likes</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.jp_platform_likes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.kr_platform_likes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">KR Platform Likes</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.kr_platform_likes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.fr_platform_likes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">FR Platform Likes</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.fr_platform_likes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.us_platform_likes ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">US Platform Likes</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.us_platform_likes}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.jp_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <div className="link primary-text-color text-wrap">
                          <a
                            href={webtoonPreviewData?.jp_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {webtoonPreviewData?.jp_wikipedia_link?.length > 50
                              ? `${webtoonPreviewData?.jp_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : webtoonPreviewData?.jp_wikipedia_link}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.kr_wikipedia_link ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">KR Wikipedia</Col>
                      <Col lg="8">
                        <div className="link primary-text-color text-wrap">
                          <a
                            href={webtoonPreviewData?.kr_wikipedia_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {webtoonPreviewData?.kr_wikipedia_link?.length > 50
                              ? `${webtoonPreviewData?.kr_wikipedia_link.slice(
                                  0,
                                  40
                                )}...`
                              : webtoonPreviewData?.kr_wikipedia_link}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {webtoonPreviewData?.scantrad ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">ScanTrad</Col>
                      <Col lg="8">
                        <span className="link primary-text-color">
                          <a
                            href={webtoonPreviewData?.scantrad}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {webtoonPreviewData?.scantrad?.length > 50
                              ? `${webtoonPreviewData?.scantrad.slice(
                                  0,
                                  40
                                )}...`
                              : webtoonPreviewData?.scantrad}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.low_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Low Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.low_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.medium_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Medium Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.medium_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.high_sales ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">High Sales</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.high_sales}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.fr_consumer_price ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">FR Consumer Price</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData?.fr_consumer_price}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.product_pl ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">Product P&L</Col>
                      <Col lg="8">
                        <span className="link primary-text-color text-wrap">
                          <a
                            href={webtoonPreviewData?.product_pl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {webtoonPreviewData?.product_pl?.length > 50
                              ? `${webtoonPreviewData?.product_pl.slice(
                                  0,
                                  40
                                )}...`
                              : webtoonPreviewData?.product_pl}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {webtoonPreviewData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {webtoonPreviewData.created_at
                            ? moment(webtoonPreviewData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>

          <Col lg="4">
            <Card className="text-right shadow m-2 view_cards">
              <CardBody>
                {webtoonPreviewData?.cover_image ? (
                  <>
                    <CardImg
                      src={webtoonPreviewData.cover_image}
                      alt="Upload"
                      className="overflow-hidden"
                      style={{ width: '5cm', height: '7cm', objectFit: 'cover',marginRight:'37px' }}
                    />
                  </>
                ) : (
                  <CardImg
                    src={require("../assets/images/no-image.png")}
                    alt="Upload"
                    className=" overflow-hidden"
                    style={{ width: '5cm', height: '7cm', objectFit: 'cover',marginRight:'37px' }}
                  />
                )}
              </CardBody>
            </Card>
            {webtoonPreviewData?.rating ||
            webtoonPreviewData.rating === null ? (
              <Card className="text-left shadow m-2 view_cards">
                <CardBody>
                  <h4>Ratings</h4>

                  <div className="d-inline-flex">
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`}
                        alt="rating-1"
                        style={{ width: "20%" }}
                      />
                      <span className="m-1">
                        {webtoonPreviewData?.rating_one_counts || 0}
                      </span>
                    </div>

                    <div className="pe-auto">
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`}
                        alt="rating-2"
                        style={{ width: "20%" }}
                      />
                      <span className="m-1">
                        {webtoonPreviewData?.rating_two_counts || 0}
                      </span>
                    </div>

                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`}
                        alt="rating-3"
                        style={{ width: "17%" }}
                      />
                      <span className="m-2">
                        {webtoonPreviewData?.rating_three_counts || 0}
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          {webtoonPreviewData?.authors?.length ? (
            <Col lg="3">
              <Card className="shadow m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Authors</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {webtoonPreviewData.authors.map((author) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={author.id}>{author.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {webtoonPreviewData?.genres?.length ? (
            <Col lg="3">
              <Card className="shadow me-2 m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Genre</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {webtoonPreviewData.genres.map((genre, index) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={index}>{genre.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {webtoonPreviewData?.illustrators?.length ? (
            <Col lg="3">
              <Card className="shadow me-2 m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Illustrator</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {webtoonPreviewData.illustrators.map(
                      (illustrators, index) => (
                        <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                          <li key={index}>{illustrators.name}</li>
                        </ul>
                      )
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {webtoonPreviewData?.publics?.length ? (
            <Col lg="3">
              <Card className="shadow m-2 view_cards">
                <CardBody style={{ paddingTop: 10, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                  <h3 className="fw-bolder dot_border">Public</h3>
                  <Col style={{ padding: 0, margin: 0, lineHeight: 0.5 }}>
                    {webtoonPreviewData.publics.map((publicsD, index) => (
                      <ul style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 20 }}>
                        <li key={index}>{publicsD.name}</li>
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          {webtoonPreviewData.comment !== '' || webtoonPreviewData.all_comments.length  !== 0  ? (
            <Col lg="12">
              <Card className="me-2 m-2 shadow">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Comments</h3>
                  <>
                    <Row>
                      {webtoonPreviewData.comment ? (
                        <Col lg="3">
                          <Card className="shadow mt-1 ">
                            <CardHeader className="h4" style={{ padding: 10 }}>
                              {webtoonPreviewData.user_first_name}{" "}
                              {webtoonPreviewData.user_last_name}
                            </CardHeader>
                            <CardBody style={{ padding: 10 }}>{webtoonPreviewData.comment}</CardBody>
                          </Card>
                        </Col>
                      ) : (
                        ""
                      )}
                      {webtoonPreviewData?.all_comments?.length > 0
                        ? webtoonPreviewData.all_comments.map((x) => (
                            <>
                              <Col lg="3">
                                <Card className="shadow mt-1 ">
                                  <CardHeader className="h4" style={{ padding: 10 }}>
                                    {x.first_name} {x.last_name}
                                  </CardHeader>
                                  <CardBody style={{ padding: 10 }}>{x.comment}</CardBody>
                                </Card>
                              </Col>
                            </>
                          ))
                        : ""}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </div>
  );
});

export default WebtoonPreview;

import Header from "components/Headers/Header";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { updateUniverse } from "utils/endpoint";
import * as yup from "yup";
import clsx from "clsx";
import Select from "react-select";
import { originalMedium } from "utils/enums";
import UseFullPageLoader from "utils/UseFullPageLoader";
import useSearchParams from "utils/useSearchParams";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  original_medium: yup.string().required("Original medium is required"),
});

function UpdateUniverse() {
  const { state } = useLocation();
  const [universeQueryParams ,setUniverseQueryParams] = useSearchParams();
  const currentPage = universeQueryParams.currentPage;
  const fromDate = universeQueryParams.fromDate;
  const toDate = universeQueryParams.toDate;
  const name = universeQueryParams.name;
  const original_medium = universeQueryParams.original_medium;
  const noOfMediaCount = universeQueryParams.noOfMediaCount;
  const is_active = universeQueryParams.is_active;
  const redirect = universeQueryParams.redirect == null || undefined ? 2 : universeQueryParams.redirect;
  const navigate = useNavigate();
  const [universe] = useState(state.row);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { id } = params;

  const handleSearchParams = (updatedObj) => {
    let url = `/admin/universe/universe-list?id=${id}&page=${currentPage}`;
    if(name) url+= `&name=${updatedObj?.name || name}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(original_medium) url += `&original_medium=${updatedObj?.original_medium || original_medium}`
    if(noOfMediaCount) url+= `&no_of_media_count=${noOfMediaCount}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return url;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: universe?.name,
        original_medium: universe?.original_medium,
        jp_wikipedia_link: universe?.jp_wikipedia_link,
        is_active: universe?.is_active ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
            name: values.name,
            original_medium: values.original_medium,
            jp_wikipedia_link: values.jp_wikipedia_link,
            is_active: values.is_active,
        };
        const result = await updateUniverse(id, updateData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams(updateData)
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Universe</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const isSameUniverseViewPage = localStorage.getItem('is_same_universe_view_page');
                              
                              if(isSameUniverseViewPage == 'true'){
                                navigate(-1)
                              }

                              if(redirect == 1){
                                navigate(-1);
                              }

                              if(redirect == 2){
                                const url =  handleSearchParams()
                                navigate(url);
                              }
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                    <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                            Name
                            </label>
                            <Input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                            />
                             <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="name" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                            Original Medium
                            </label>
                            <Select
                             defaultValue={{
                              label: values.original_medium,
                            }}
                              name="original_medium"
                              id="original_medium"
                              className=""
                              options={Array.from(originalMedium).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFieldValue("original_medium", event.value);
                              }}
                            />
                             <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="original_medium" />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">JP Wikipedia Link</label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia_link"
                              name="jp_wikipedia_link"
                              placeholder="jp_wikipedia_link"
                              type="text"
                              value={values.jp_wikipedia_link == null ? '' : values.jp_wikipedia_link }
                              // value={values.jp_wikipedia_link}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                     
                      <Row>
                        <Col lg="3">
                        <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Universe
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default UpdateUniverse;

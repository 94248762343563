import { useState } from "react";
import { NavLink as NavLinkRRD, useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import ChangePasswordModal from "utils/ChangePasswordModal";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const user = localStorage.getItem("User");
  const navigate = useNavigate()
  const logout = () => {
    localStorage.clear()
    navigate('/auth/login')
    window.location.reload()
  }
  const [show, setShowModal] = useState(false);
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const createLinks = (routes) => {
    if(routes?.key !== null){
      return routes?.filter(x => x.visibleOnMenu).map((prop, key) => {
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}>
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      });
    }
    return;
  };

  const { routes, logo } = props;

  if (logo && logo.innerLink) {
   
  } else if (logo && logo.outterLink) {
    
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main">
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <img className="navbar-brand-img" alt="..." src={require("../../../src/assets/images/vega-side-menu-logo.png")}/>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
        
          <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                 
                    <img
                      alt="..."
                      src={require("../../../src/assets/images/default-avatar.png")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                     {user?.first_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={async () => {
                                    setShowModal(true);
                                  }}>
                                   <i className="fa-solid fa-key"></i>
                  <span>Change PassWord</span>
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault()
                    logout()
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            
            </UncontrolledDropdown>
        </Nav>
        <ChangePasswordModal
                show={show}
                onClose={() => {
                  setShowModal(false);
                }}
              />
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                   <div className="navbar-brand-img text-center">Book Review</div>
                 
                </Col>
               
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form> */}
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Col,
  Container,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import Header from "components/Headers/Header";
import { roleList } from "utils/endpoint";
import { getRoleById } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { Pagination as Paginationes } from "antd";
import { parseURLParams } from "views/universe/UniverseList";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
function RoleList() {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const name = params.name;
  const [isLoading, setIsLoading] = useState(false);
  const [roleLists, setRoleLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterName, setFilterName] = useState(name || "");
  const [permissions, setPermissions] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [roleFilter,setRoleFilter] = useState('')
  const toggle = () => setIsOpen(!isOpen);

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${mySortKey}&sort_by=${mySortBy}`;

    if(roleFilter){
      let filter = roleFilter;
      filter.sort_by= mySortBy;
    }

    getRoleList(filter);
  };
  const getRoleList = async (roleFilter) => {
    setRoleLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await roleList(roleFilter);
      if (result?.isSuccess) {
        setRoleLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setRoleLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setRoleLists([]);
    }
  };
  const commonRoleFilterOptions = (roleFilterObject) => {

    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
  
    const name = roleFilterObject?.name;

    if (name && name.trim()) {
      filter += `&name=${name}`.replace(/'/g, "''");
    }

    return filter;
  }

  const handleRoleListAddFilter = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        name: filterName
      }

      const filter  = commonRoleFilterOptions(filterObject)
      const response = parseURLParams(filter);

      setRoleFilter(response);
      getRoleList(filter);
      setFirstTimeFilterActive(false);
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }


  const handleRoleListCancelFilter = async () => {
    setFilterLoading(true);
    showLoader();
    setFilterName("");
    setRoleFilter('')
    setFirstTimeFilterActive(false)
    setPage(1)
    setIsRedirectFirstPage(1)
    const filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
    navigate('/admin/role/role-list', { replace: true });
    getRoleList(filter);
    setFilterLoading(false);
    hideLoader();
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("role",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
   }

  const handleFilterWithPagination = async() => {

    const response = commonRoleFilterOptions(roleFilter);

    const filter = parseURLParams(response);

    const roleFilterValue = filter.name;

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (roleFilterValue !== 'undefined')){
          setPage(1)
          getRoleList(response);
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (roleFilterValue)){
        setPage(1)
        getRoleList(response);
        return;
      }
    }
    getRoleList(response);
      
  }
  
  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  useEffect(()=>{
    if(filterName){
      setIsRedirectFirstPage(0)
      callAPI()
    }
  },[name])

  const getUrl = (baseUrl) => {

    if(filterName){
      baseUrl+=`&name=${filterName}`
    }

    return baseUrl;
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Role List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-role" ? (
                          <Button
                            
                            color="primary"
                            onClick={() => {
                              navigate("/admin/role/create-role");
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">Add Role</span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}

                    <Button onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Name</label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={filterName}
                              onChange={(event) => {
                                setFilterName(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleRoleListAddFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleRoleListCancelFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th style={{ cursor: "pointer" }} onClick={() =>
                                sortColumns("name", sort_by)
                              }>Name
                            {sort_key === "name" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-role" ? (
                                  <th className="action">Action</th>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {roleLists.length > 0 ? (
                            roleLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id} id={`role-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td>{info.name}</td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-role" ? (
                                        <td className="action">
                                          {info.slug !== "admin" && (
                                            <Button
                                              
                                              color="primary"
                                              onClick={async () => {
                                                const res = await getRoleById({
                                                  role_id: info.id,
                                                });

                                                let baseURL = `/admin/role/update-role/${info.id}?page=${page}`;
                                                const url = getUrl(baseURL); 
                                                navigate(
                                                  url,
                                                  { state: { row: res?.data } }
                                                );
                                              }}>
                                              {isLoading && (
                                                <span
                                                  className="indicator-progress"
                                                  style={{ display: "block" }}>
                                                  Please Wait..
                                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                </span>
                                              )}
                                              {!isLoading && (
                                                <span className="indicator-label">
                                                  Edit
                                                </span>
                                              )}
                                            </Button>
                                          )}
                                        </td>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink
                    className={"p-0 " + (page !== 1 ? "active-button" : "")}
                    disabled={page === 1}>
                    <PaginationItem aria-label="Previous" onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink
                    className={
                      "p-0 " + (page !== totalPages ? "active-button" : "")
                    }
                    disabled={page === totalPages}>
                    <PaginationItem aria-label="Next" onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {loader}
    </>
  );
}

export default RoleList;

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

const ForgotPassword = () => {
  const [emailId, setEmailId] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      if (emailId.trim().length === 0)
        return toast.error("Email Id is required!");

      setLoader(true);
      // const result = await forgotPassword({ emailId })
      // if (result && result.status === 200) {
      //   alert(result.data)
      //   navigate('/resetPassword', { state: { emailId: emailId } })
      // } else {
      //   setLoader(false)
      //   return toast.error(result?.data || 'Server error')
      // }
    } catch (error) {
      setLoader(false);
      return toast.error("User not found!");
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 mt-4">
          <CardHeader className="text-center">
            <h1>Forgot Password?</h1>
          </CardHeader>
          <CardBody className="px-lg-4 py-lg-4">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    value={emailId}
                    name="emailId"
                    onChange={(e) => setEmailId(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-3"
                  color="primary"
                  type="button"
                  onClick={handleForgotPassword}>
                  Submit
                </Button>
                <Link to="/auth/login">
                  <Button type="button" className="btn btn-light fs-6">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;

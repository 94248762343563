import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getWebtoonById } from "utils/endpoint";
import { getMangaById } from "utils/endpoint";
import { getLightNovelById } from "utils/endpoint";
import  isPageReload  from "utils/enums";
import LightNovelModal from "utils/lightNovelModal";
import MangaModal from "utils/mangaModal";
import useSearchParams from "utils/useSearchParams";
import WebtoonModal from "utils/webtoonModal";

function ViewAuthor() {
  const { state } = useLocation();
  const [authorQueryParams ,setAuthorQueryParams] = useSearchParams();
  const currentPage = authorQueryParams.currentPage;
  const fromDate = authorQueryParams.fromDate;
  const toDate = authorQueryParams.toDate;
  const name = authorQueryParams.name;
  const jpName = authorQueryParams.jpName;
  const is_active = authorQueryParams.is_active;
  const redirect = authorQueryParams.redirect !== null || undefined && authorQueryParams.redirect;
  const [author,setAuthor] = useState(state.row);
  const [lightNovelSummaryData, setLightNovelSummaryData] = useState([]);
  const [openLightNovelModel, setOpenLightNovelModel] = useState(false);
  const [mangaSummaryData, setMangaSummaryData] = useState([]);
  const [openMangaModel, setOpenMangaModel] = useState(false);
  const [webtoonSummaryData, setWebtoonSummaryData] = useState([]);
  const [openWebtoonModel, setOpenWebtoonModel] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const authorId = state.row.id;
  const isSameAuthorViewPage = localStorage.getItem('is_same_author_view_page');
  const navigate = useNavigate();

  const fetchLightNovelDetail = async (lightnovelId) => {
    setOpenLightNovelModel(true);
    const res = await getLightNovelById({
      lightNovel_id: lightnovelId,
    });
    if (res && res.isSuccess) {
      setLightNovelSummaryData(res.data);
    }
  };

  const fetchMangaDetail = async (mangaId) => {
    setOpenMangaModel(true);
    const res = await getMangaById({
      manga_id: mangaId,
    });
    if (res && res.isSuccess) {
      setMangaSummaryData(res.data);
    }
  };

  const fetchWebtoonDetail = async (webtoonId) => {
    setOpenWebtoonModel(true);
    const res = await getWebtoonById({
      webtoon_id: webtoonId,
    });
    if (res && res.isSuccess) {
      setWebtoonSummaryData(res.data);
    }
  };

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  const handleSearchParams = (baseurl) => {
    if(name) baseurl+= `&name=${name}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(jpName) baseurl += `&jp_name=${jpName}`
    if(is_active || is_active === "false") baseurl += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return baseurl; 
  }


  useEffect(()=>{
    setAuthor(state.row);
  },[state.row])

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="mb-0">Author Detail</h1>
                    </Col>
                    <Col lg="6" className="add_btn text-right">
                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() => {
                          if(isSameAuthorViewPage == 'true'){
                            navigate(-1)
                            localStorage.setItem('is_same_author_view_page',false);
                          }
                    
                          if(redirect == 1){
                            navigate(-1)
                          }
                          
                          if(redirect == 2 && redirect !== 1 || !redirect){
                            let baseURL = `/admin/author/author-list?id=${state.row.id}&page=${currentPage}`;
                            const url = handleSearchParams(baseURL);
                            navigate(url);
                            localStorage.setItem('is_same_author_view_page',false);
                          }
                        }}>
                        <span className="me-2">Back</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row>
                    {author?.name ? (
                      <>
                        <Col lg="4"> Name</Col>
                        <Col lg="8">
                          <div className="text-dark h2">{author.name}</div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {author?.original_medium ? (
                      <>
                        <Col lg="4">Original Medium</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {author?.original_medium}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  {author?.jp_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Name</Col>
                        <Col lg="8">
                          <span className="text-dark">{author?.jp_name}</span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {author?.jp_wikipedia ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <span className="link primary-text-color">
                            <a
                              href={author?.jp_wikipedia}
                              target="_blank"
                              rel="noreferrer">
                              {author?.jp_wikipedia?.length > 50 ? `${author?.jp_wikipedia.slice(0, 40)}...` : author?.jp_wikipedia}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  <Row className="mt-1">
                    {author?.is_active || !author?.is_active ? (
                      <>
                        <Col lg="4">Is Active</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {author?.is_active ? "Yes" : "No"}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {author?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {author.created_at
                              ? moment(author.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {author?.manga ? (
              <Col lg="4">
                <Card className="me-2 shadow  m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Manga</h3>
                    <Col>
                      {author?.manga?.map((manga) => (
                        <li className="primary-text-color" style={{cursor: "pointer"}}
                        onClick={() =>
                          fetchMangaDetail(manga.manga_id)
                        } key={manga.manga_id}>{manga.en_title}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {author?.manga ? <Col lg="2"></Col> : ""}
            {author?.light_novel ? (
              <Col lg="4">
                <Card className="me-2 shadow  m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Light Novel</h3>
                    <Col>
                      {author?.light_novel?.map((lightnovel) => (
                        <li className="primary-text-color" style={{cursor: "pointer"}}
                        onClick={() =>
                          fetchLightNovelDetail(lightnovel.light_novel_id)
                        }  key={lightnovel.light_novel_id}>
                          {lightnovel.en_title}
                        </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            {author?.webtoon ? (
              <Col lg="4">
                <Card className="me-2 shadow  m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Webtoon</h3>
                    <Col>
                      {author.webtoon.map((webtoon) => (
                        <li className="primary-text-color" style={{cursor: "pointer"}}
                        onClick={() =>
                          fetchWebtoonDetail(webtoon.webtoon_id)
                        } key={webtoon.webtoon_id}>{webtoon.en_title}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Card>
        <LightNovelModal openLightNovelModel = {openLightNovelModel} setOpenLightNovelModel = {setOpenLightNovelModel} lightNovelSummaryData={lightNovelSummaryData} permissions={permissions} currentAuthorId = {authorId}  isLinkFunctionalityActive = {true} isPageReload = {isPageReload.AUTHOR}/>
        <MangaModal openMangaModel = {openMangaModel} setOpenMangaModel={setOpenMangaModel} mangaSummaryData={mangaSummaryData} permissions={permissions} currentAuthorId = {authorId}  isLinkFunctionalityActive = {true} isPageReload = {isPageReload.AUTHOR}/>
        <WebtoonModal openWebtoonModel = {openWebtoonModel} setOpenWebtoonModel={setOpenWebtoonModel} webtoonSummaryData={webtoonSummaryData} permissions={permissions} currentAuthorId = {authorId}  isLinkFunctionalityActive = {true} isPageReload = {isPageReload.AUTHOR}/>
      </Container>
    </>
  );
}

export default ViewAuthor;

import { Modal } from 'antd';
import { Card, CardBody, Col, Row } from 'reactstrap';

export default function RatingUserListModal({
  openUserRatingListModel,
  setOpenUserRatingListModel,
  ratingInfo,
  ratingType,
  title
}) {

  return (
    <>
      <Modal
        title={title}
        centered
        open={openUserRatingListModel}
        onOk={() => setOpenUserRatingListModel(false)}
        onCancel={() => setOpenUserRatingListModel(false)}
        okButtonProps={{ className:"model-bg-color" }}
        cancelButtonProps={{ className:"model-border-color" }}  
      >
        {ratingType == 1 && (<Card>
            <Row>
                <Col lg="12">
                <Card className="shadow m-4 detail_card">
                    <CardBody className="custom-margin-top">
                      {ratingInfo.rating_one_user_list.map((user,index)=>
                        <>
                          <Row>
                            <Col lg="2">{index+1}</Col>
                            <Col lg="10">
                                <div className="text-dark h4">
                                {user.first_name} {user.last_name}
                                </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                </Card>
                </Col>
            </Row>
         </Card>
        )}

        {ratingType == 2 && (<Card>
            <Row>
                <Col lg="12">
                <Card className="shadow m-4 detail_card">
                    <CardBody className="custom-margin-top">
                      {ratingInfo.rating_two_user_list.map((user,index)=>
                        <>
                          <Row>
                            <Col lg="2">{index+1}</Col>
                            <Col lg="10">
                                <div className="text-dark h4">
                                {user.first_name} {user.last_name}
                                </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                </Card>
                </Col>
            </Row>
         </Card>
        )}

        {ratingType == 3 && (<Card>
            <Row>
                <Col lg="12">
                <Card className="shadow m-4 detail_card">
                    <CardBody className="custom-margin-top">
                      {ratingInfo.rating_three_user_list.map((user,index)=>
                        <>
                          <Row>
                            <Col lg="2">{index+1}</Col>
                            <Col lg="10">
                                <div className="text-dark h4">
                                {user.first_name} {user.last_name}
                                </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                </Card>
                </Col>
            </Row>
         </Card>
        )}    
      </Modal>
    </>
  )
}

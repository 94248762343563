import { getAuthors, getGenres, getIllustrators, getPermission, getPrePublications, getPublics, getPublishers, getStatus, getTypes, getUniverses, getUser } from "./endpoint"

export const get_universes_list = async () => {
    try {
      const result = await getUniverses()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_status_list = async () => {
    try {
      const result = await getStatus()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_publisher_list = async () => {
    try {
      const result = await getPublishers()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_author_list = async () => {
    try {
      const result = await getAuthors()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_Illustrator_list = async () => {
    try {
      const result = await getIllustrators()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_type_list = async () => {
    try {
      const result = await getTypes()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_genre_list = async () => {
    try {
      const result = await getGenres()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_public_list = async () => {
    try {
      const result = await getPublics()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_PrePublication_list = async () => {
    try {
      const result = await getPrePublications()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_Permission_list = async () => {
    try {
      const result = await getPermission()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }
  export const get_User_list = async () => {
    try {
      const result = await getUser()
      if (result.isSuccess) {
        return result?.data
      } else {
        return []
      }
    } catch (error) {
      return []
    }
  }

  export const backToPreviousClickedRecord = (name,id) => { 
    const element = document.getElementById(`${name}-list-record-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' }); 
    }
    return element;  
  }

  export const genrateCsvFileName = (name) => {
    const currentDate = new Date();
    const dateString = currentDate.toISOString().slice(0,10).replace(/-/g,"");
    const fileName = `${name}_${dateString}.csv`;
 
    return fileName
  }
  
  export const randomIndex = () => {
   return Math.floor(Math.random() * 10);
  }
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";

const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const userId = state.userId;
      if (!userId) return toast.error("Something went wrong!");
      if (!otp) return toast.error("OTP is required!");
      if (!newPassword) return toast.error("Password is required!");
      if (!confirmPassword) return toast.error("Confirm password is required!");
      if (newPassword !== confirmPassword) {
        return toast.error("Password and confirm password do not match!");
      }

      setLoader(true);
      // const result = await changePassword({ userId, otp, password: newPassword, confirmPassword })
      // if (result && result.status === 200) {
      //   setLoader(false)
      //   navigate('/login')
      // } else {
      //   setLoader(false)
      //   return toast.error(result?.data || 'Server error')
      // }
    } catch (error) {
      setLoader(false);
      return toast.error("Invalid OTP!");
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 mt-4">
          <CardHeader className="text-center">
            <h1>Reset Password</h1>
          </CardHeader>
          <CardBody className="px-lg-4 py-lg-4">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-bold-right" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="otp"
                    placeholder="OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    autoComplete="off"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm-Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-3" color="primary" type="button" onClick={handleSignIn}>
                Reset Password
                </Button>
                <Link to="/auth/login">
                  <Button type="button" className="btn btn-light fs-6">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ResetPassword;

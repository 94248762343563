import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Row,
} from "reactstrap";
import Select from "react-select";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";
import { typeList, updateTypeStatus, getTypeById } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import { Pagination as Paginationes } from "antd";
import { parseURLParams } from "views/universe/UniverseList";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
function TypeList() {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const name = params.name;
  const is_active = params.is_active;
  const [isLoading, setIsLoading] = useState(false);
  const [typeLists, setTypeLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterName, setFilterName] = useState(name || "");
  const [filterIsActive, setFilterIsActive] = useState(is_active || "");
  const [permissions, setPermissions] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const selectInputRef = useRef(null);
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [typeFilter,setTypeFilter] = useState('');
  const toggle = () => setIsOpen(!isOpen);

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${mySortKey}&sort_by=${mySortBy}`;

    if(typeFilter){
      let filter = typeFilter;
      filter.sort_by= mySortBy;
    }
    getTypeList(filter);
  };
  const getTypeList = async (typeFilter) => {
    setTypeLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await typeList(typeFilter);
      if (result?.isSuccess) {
        setTypeLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setTypeLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setTypeLists([]);
    }
  };

  const commonTypeFilterOptions = (typeFilterObject) => {

    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
  
    const from_date = typeFilterObject?.from_date;
    const to_date = typeFilterObject?.to_date;
    const name = typeFilterObject?.name;
    const is_active = typeFilterObject?.is_active;

    if(new Date(from_date) <= new Date(to_date)){
      filter += `&from_date=${filterFromDate}`;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter += `&to_date=${filterToDate}`;
    }

    if (name && name.trim()) {
      filter += `&name=${name.replace(/'/g, "''")}`;
    }

    if (is_active) {
      filter += `&is_active=${is_active}`;
    } else {
      filter += `&is_active=${is_active}`;
    }
     
   return filter;
  }
  
  const handleTypeListAddFilter = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        name: filterName,
        is_active : filterIsActive
      }

      const filter  = commonTypeFilterOptions(filterObject)
      const response = parseURLParams(filter);

      setTypeFilter(response);
      getTypeList(filter)
      setFirstTimeFilterActive(false)
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const handleTypeListCancelFilter = async () => {
    setFilterLoading(true);
    showLoader();
    setFilterName("");
    setFilterFromDate("");
    setTypeFilter('')
    setFilterToDate("");
    setFilterIsActive("");
    setIsRedirectFirstPage(1)
    selectInputRef.current.props.value.label = "";
    selectInputRef.current.props.value.label = "select";
    setFirstTimeFilterActive(false)
    setPage(1)
    const filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
    navigate('/admin/type/type-list', { replace: true });
    getTypeList(filter)
    setFilterLoading(false);
    hideLoader();
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("type",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
   }

  const handleFilterWithPagination = async() => {

    const response = commonTypeFilterOptions(typeFilter);

    const filter = parseURLParams(response);

    const typeFilterValue = filter.from_date || filter.to_date || filter.name || filter.is_active || filter.is_active === 'false' ;

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (typeFilterValue !== 'undefined')){
          setPage(1)
          getTypeList(response);
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (typeFilterValue)){
        setPage(1)
        getTypeList(response);
        return;
      }
    }  

    getTypeList(response);
      
  }

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);

  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateTypeStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };

  useEffect(()=>{
    if(filterName|| filterFromDate|| filterToDate|| filterIsActive ){
      setIsRedirectFirstPage(0)
      callAPI()
    }
  },[name,fromDate,toDate,is_active])

  const getUrl = (baseUrl) => {

    if(filterName){
      baseUrl+=`&name=${filterName}`
    }

    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }

    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterIsActive || filterIsActive === false || filterIsActive === 'none'){
      baseUrl +=`&is_active=${filterIsActive}`
    }

    return baseUrl;
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> Type List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-type" ? (
                          <Button
                            
                            color="primary"
                            onClick={() => {
                              navigate("/admin/type/create-type");
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">Add Type</span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}

                    <Button onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Name</label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={filterName}
                              onChange={(event) => {
                                setFilterName(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: filterIsActive === "true" ? "Yes" : filterIsActive === "false"  ? "No" : filterIsActive == 'none' ? 'None': 'Select',
                              }}
                              options={Array.from([{id:'none',name:'None'},...isActive]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsActive(event.value);
                              }}
                              ref={selectInputRef}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleTypeListAddFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleTypeListCancelFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("name", sort_by)}>
                              {" "}
                              Name
                              {sort_key === "name" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}{" "}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" &&
                                (sort_by === "ASC" ? (
                                  <i className="fa fa-arrow-up ml-1"></i>
                                ) : (
                                  <i className="fa fa-arrow-down ml-1"></i>
                                ))}{" "}
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-type" ? (
                                  <>
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("is_active", sort_by)
                                      }>
                                      Is Active
                                      {sort_key === "is_active" &&
                                        (sort_by === "ASC" ? (
                                          <i className="fa fa-arrow-up ml-1"></i>
                                        ) : (
                                          <i className="fa fa-arrow-down ml-1"></i>
                                        ))}{" "}
                                    </th>
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {typeLists.length > 0 ? (
                            typeLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id} id={`type-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td>{info.name}</td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-type" ? (
                                        <>
                                          <td>
                                            <label className="custom-toggle">
                                              <input
                                                onChange={() => {}}
                                                onClick={() => {
                                                  handleStatus({
                                                    id: info.id,
                                                    is_active: !info.is_active,
                                                  });
                                                }}
                                                value={info.is_active === true}
                                                defaultChecked={
                                                  info.is_active === true
                                                }
                                                type="checkbox"
                                              />
                                              <span className="custom-toggle-slider  rounded-circle" />
                                            </label>
                                          </td>

                                          <td className="action">
                                            <Button
                                              
                                              color="primary"
                                              onClick={async () => {
                                                const res = await getTypeById({
                                                  type_id: info.id,
                                                });
                                                let baseURL = `/admin/type/update-type/${info.id}?page=${page}`;
                                                const url = getUrl(baseURL);
                                                navigate(
                                                  url,
                                                  { state: { row: res?.data } }
                                                );
                                              }}>
                                              {isLoading && (
                                                <span
                                                  className="indicator-progress"
                                                  style={{ display: "block" }}>
                                                  Please Wait..
                                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                </span>
                                              )}
                                              {!isLoading && (
                                                <span className="indicator-label">
                                                  Edit
                                                </span>
                                              )}
                                            </Button>
                                          </td>
                                        </>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink
                    className={"p-0 " + (page !== 1 ? "active-button" : "")}
                    disabled={page === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      disabled={page === 1}
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink
                    className={
                      "p-0 " + (page !== totalPages ? "active-button" : "")
                    }
                    disabled={page === totalPages}>
                    <PaginationItem
                      aria-label="Next"
                      disabled={page === totalPages}
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {loader}
    </>
  );
}

export default TypeList;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Container,
  Pagination,
  Input,
  PaginationItem,
  PaginationLink,
  Row,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { userList } from "utils/endpoint";
import Header from "components/Headers/Header";
import { toast } from "react-toastify";
import { updateUserStatus } from "utils/endpoint";
import { getUserById, getRoleList } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { isActive } from "utils/enums";
import { Pagination as Paginationes } from "antd";
import { parseURLParams } from "views/universe/UniverseList";
import useSearchParams from "utils/useSearchParams";
import { backToPreviousClickedRecord } from "utils/Commen";
function UserList() {
  const navigate = useNavigate();
  const [params ,setParams] = useSearchParams();
  const id = params.id;
  const currentPage = params.currentPage;
  const fromDate = params.fromDate;
  const toDate = params.toDate;
  const firstName = params.firstName;
  const lastName = params.lastName;
  const email = params.email;
  const is_active = params.is_active;
  const [isLoading, setIsLoading] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [page, setPage] = useState(currentPage || 1);
  const [per_Page, setPer_Page] = useState(100); 
  const [isRedirectFirstPage,setIsRedirectFirstPage] = useState(1)
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterFromDate, setFilterFromDate] = useState(fromDate || "");
  const [filterToDate, setFilterToDate] = useState(toDate || "");
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterFirstName, setFilterFirstName] = useState(firstName || "");
  const [filterLastName, setFilterLastName] = useState(lastName ||"");
  const [filterEmail, setFilterEmail] = useState(email || "");
  const [filterIsActive, setFilterIsActive] = useState(is_active || "");
  const [permissions, setPermissions] = useState([]);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [sort_key, setSortKey] = useState("created_at");
  const [sort_by, setSortBy] = useState("DESC");
  const [firstTimeFilterActive,setFirstTimeFilterActive] = useState(false);
  const [userFilter,setUserFilter] = useState('')
  const selectInputRef = useRef(null);

  const toggle = () => setIsOpen(!isOpen);

  const sortColumns = (currentSortKey, currentSortBy) => {
    let mySortKey = currentSortKey;
    let mySortBy = currentSortBy;
    if (!sort_by) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    if (currentSortKey === sort_key) {
      mySortKey = currentSortKey;
      mySortBy = currentSortBy === "ASC" ? "DESC" : "ASC";
      setSortBy(currentSortBy === "ASC" ? "DESC" : "ASC");
    }
    if (currentSortKey !== sort_key) {
      mySortKey = currentSortKey;
      mySortBy = "ASC";
      setSortKey(currentSortKey);
      setSortBy("ASC");
    }
    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${mySortKey}&sort_by=${mySortBy}`;
    // if (filterFromDate) {
    //   filter += `&from_date=${filterFromDate}`;
    // }

    // if (filterToDate) {
    //   filter += `&to_date=${filterToDate}`;
    // }

    // if (filterFirstName && filterFirstName.trim()) {
    //   filter += `&first_name=${filterFirstName}`;
    // }

    // if (filterLastName && filterLastName.trim()) {
    //   filter += `&last_name=${filterLastName}`;
    // }

    // if (filterEmail && filterEmail.trim()) {
    //   filter += `&email=${filterEmail}`;
    // }

    // if (filterIsActive) {
    //   filter += `&is_active=${filterIsActive}`;
    // } else {
    //   filter += `&is_active=${filterIsActive}`;
    // }

    if(userFilter){
      let filter = userFilter;
      filter.sort_by= mySortBy;
    }

    getUserList(filter);
  };

  const getUserList = async (userFilter) => {
    setUserLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await userList(userFilter);
      const roleList = await getRoleList();
      if (result?.isSuccess && roleList.isSuccess) {
        setRoleList(roleList.data);
        setUserLists(result?.data?.data?.records);
        // setTotalPages(
        //   Math.ceil(
        //     result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        //   )
        // );
        setTotalPages(result?.data?.data?.totalRecords);
        setPer_Page(result?.data?.pagination?.limit || 10);
        setIsLoading(false);
        hideLoader();
      } else {
        setIsLoading(false);
        hideLoader();
        setUserLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setUserLists([]);
    }
  };
  const commonUserFilterOptions = (userFilterObject) => {

    let filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
  
    const from_date = userFilterObject?.from_date;
    const to_date = userFilterObject?.to_date;
    const first_name = userFilterObject?.first_name;
    const last_name = userFilterObject?.last_name;
    const email = userFilterObject?.email;
    const is_active = userFilterObject?.is_active;

    if(new Date(from_date) <= new Date(to_date)){
      filter += `&from_date=${filterFromDate}`;
    }

    if (new Date(to_date) >= new Date(from_date)) {
      filter += `&to_date=${filterToDate}`;
    }

    if (first_name && first_name.trim()) {
      filter += `&first_name=${first_name}`.replace(/'/g, "''");
    }

    if (last_name && last_name.trim()) {
      filter += `&last_name=${last_name}`.replace(/'/g, "''");
    }

    if (email && email.trim()) {
      filter += `&email=${email}`;
    }

    if (is_active) {
      filter += `&is_active=${is_active}`;
    } else {
      filter += `&is_active=${is_active}`;
    }
     
   return filter;
  }

  const handleUserListAddFilter = async () => {
    try {
      setFilterLoading(true);
      showLoader();
      setPage(1)
      callAPI()
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  };

  const callAPI = function() {
    try {
      const filterObject = {
        from_date:filterFromDate,
        to_date : filterToDate,
        first_name: filterFirstName,
        last_name : filterLastName,
        email:filterEmail,
        is_active : filterIsActive
      }

      const filter  = commonUserFilterOptions(filterObject)
      const response = parseURLParams(filter);
     
      setUserFilter(response);
      getUserList(filter)
      setFirstTimeFilterActive(false);
      setFilterLoading(false);
      hideLoader();
    } catch (error) {
      setFilterLoading(false);
      hideLoader();
    }
  }

  const handleUserListCancelFilter = async () => {
    setFilterLoading(true);
    showLoader();
    setFilterFirstName("");
    setFilterLastName("");
    setUserFilter('')
    setFilterEmail("");
    setFilterFromDate("");
    setFilterToDate("");
    setFilterIsActive("");
    setIsRedirectFirstPage(1)
    selectInputRef.current.props.value.label = "";
    selectInputRef.current.props.value.label = "select";
    setFirstTimeFilterActive(false)
    setPage(1)
    const filter = `?page=${page}&per_page=${per_Page}&sort_key=${sort_key}&sort_by=${sort_by}`;
    navigate('/admin/user/UserList', { replace: true });
    getUserList(filter);
    setFilterLoading(false);
    hideLoader();
  };

  if(id !== null){
    const response = backToPreviousClickedRecord("user",id);
    if(response && response !== null || undefined){
      setParams({search:null})  
     }
   }

  const handleFilterWithPagination = async() => {

    const response = commonUserFilterOptions(userFilter);

    const filter = parseURLParams(response);

    const userFilterValue = filter.from_date || filter.to_date || filter.email || filter.first_name || filter.last_name ||filter.is_active || filter.is_active === 'false' ;

    if(isRedirectFirstPage){
      if(!firstTimeFilterActive && (userFilterValue !== 'undefined')){
          setPage(1)
          getUserList(response);
          setFirstTimeFilterActive(true);
          return;
      } 

      if(firstTimeFilterActive && totalPages <= filter.per_page && (userFilterValue)){
        setPage(1)
        getUserList(response);
        return;
      }
    }  

    getUserList(response);
      
  }

  useEffect(()=>{
    handleFilterWithPagination();
  },[totalPages,page, per_Page, sort_key, sort_by])
  
  const handleStatus = async (data) => {
    setIsLoading(true);
    const res = await updateUserStatus(data);
    toast.success(`${res.message}`);
    setIsLoading(false);
  };

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
    }
  }, []);


  useEffect(()=>{
    if(filterFirstName || filterLastName || filterEmail || filterFromDate|| filterToDate|| filterIsActive ){
      setIsRedirectFirstPage(0)
      callAPI()
    }
  },[firstName,lastName,email,fromDate,toDate,is_active])

  const getUrl = (baseUrl) => {

    if(filterFirstName){
      baseUrl+=`&first_name=${filterFirstName}`
    }

    if(filterLastName){
      baseUrl+=`&last_name=${filterLastName}`
    }

    if(filterEmail){
      baseUrl+=`&email=${filterEmail}`
    }

    if(filterFromDate){
      baseUrl+=`&from_date=${filterFromDate}`
    }

    if(filterToDate){
      baseUrl+=`&to_date=${filterToDate}`
    }
    
    if(filterIsActive || filterIsActive === false || filterIsActive === 'none'){
      baseUrl +=`&is_active=${filterIsActive}`
    }

    return baseUrl;
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <Row>
                  <Col lg="6">
                    <h1 className="mb-0"> User List</h1>
                  </Col>
                  <Col lg="6" className="add_btn text-right">
                    {permissions &&
                      permissions.map((permission) =>
                        permission === "create-user" ? (
                          <Button
                            
                            color="primary"
                            onClick={() => {
                              navigate("/admin/user/createUser");
                            }}>
                            {isLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!isLoading && (
                              <span className="indicator-label">Add User</span>
                            )}
                          </Button>
                        ) : (
                          ""
                        )
                      )}
                    <Button onClick={toggle}>
                      <i className="fa-solid fa-filter"></i>
                      <span className="me-2">Filter</span>
                    </Button>
                  </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                  <Card className=" mt-2">
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              From Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="from_date"
                              name="from_date"
                              type="date"
                              value={filterFromDate}
                              onChange={(e) => {
                                setFilterFromDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              To Date
                            </label>
                            <Input
                              closeonselect="true"
                              className="form-control"
                              id="to_date"
                              name="to_date"
                              type="date"
                              value={filterToDate}
                              onChange={(e) => {
                                setFilterToDate(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              First Name
                            </label>
                            <Input
                              id="first_name"
                              name="first_name"
                              placeholder="First Name"
                              type="text"
                              value={filterFirstName}
                              onChange={(event) => {
                                setFilterFirstName(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Last Name
                            </label>
                            <Input
                              id="last_name"
                              name="last_name"
                              placeholder="Last Name"
                              type="text"
                              value={filterLastName}
                              onChange={(event) => {
                                setFilterLastName(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">Email</label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="text"
                              value={filterEmail}
                              onChange={(event) => {
                                setFilterEmail(event.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label">
                              Is Active
                            </label>
                            <Select
                              name="is_active"
                              id="is_active"
                              className="react-select"
                              defaultValue={{
                                label: filterIsActive === "true" ? "Yes" : filterIsActive === "false"  ? "No" : filterIsActive == 'none'?'None':'Select',
                              }}
                              options={Array.from([{id:'none',name:'None'},...isActive]).map((value) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              })}
                              onChange={(event) => {
                                setFilterIsActive(event.value);
                              }}
                              ref={selectInputRef}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleUserListAddFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                ADD FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                        <Col lg="3">
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handleUserListCancelFilter}
                            className="filter my-3 w-100">
                            {filterLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!filterLoading && (
                              <span className="indicator-label">
                                RESET FILTER
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th >No.</th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("first_name", sort_by)
                              }>
                              First Name
                              {sort_key === "first_name" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("last_name", sort_by)}>
                              Last Name
                              {sort_key === "last_name" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            <th
                               style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("email", sort_by)}>
                              Email
                              {sort_key === "email" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            <th
                               style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("mobile_no", sort_by)}>
                              Mobile No.
                              {sort_key === "mobile_no" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumns("role_id", sort_by)}>
                              Role
                              {sort_key === "role_id" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                sortColumns("created_at", sort_by)
                              }>
                              CreatedAt
                              {sort_key === "created_at" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1"
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                            </th>
                            {permissions &&
                              permissions.map((permission) =>
                                permission === "update-user" ? (
                                  <>
                                    <th
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sortColumns("is_active", sort_by)
                                      }>
                                      Is Active
                                      {sort_key === "is_active" && 
                                (sort_by === "ASC" ?  
                                <i
                                className="fa fa-arrow-up ml-1 "
                                ></i> : <i
                                className="fa fa-arrow-down ml-1"
                                ></i>)} 
                                    </th>
                                    <th className="action">Action</th>
                                  </>
                                ) : (
                                  ""
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {userLists.length > 0 ? (
                            userLists.map((info, index) => {
                              const indexNo =
                                (page - 1) * per_Page + (index + 1);
                              return (
                                <tr key={info.id} id={`user-list-record-${info.id}`}>
                                  <td>{indexNo}</td>
                                  <td>{info.first_name}</td>
                                  <td>{info.last_name}</td>
                                  <td>{info.email}</td>
                                  <td>{info.mobile_no}</td>
                                  <td>
                                    {" "}
                                    {roleList.map((response) =>
                                      response.id === info.role_id
                                        ? response.name
                                        : ""
                                    )}
                                  </td>
                                  <td>
                                    {info.created_at
                                      ? moment(info.created_at).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      : ""}{" "}
                                  </td>
                                  <td>
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-user" && info.id !== '265a5026-9fd4-40cb-b192-119254c8387d' ? (
                                        <label className="custom-toggle">
                                          <input
                                            onChange={() => {}}
                                            onClick={() => {
                                              handleStatus({
                                                id: info.id,
                                                is_active: !info.is_active,
                                              });
                                            }}
                                            value={info.is_active === true}
                                            defaultChecked={
                                              info.is_active === true
                                            }
                                            type="checkbox"
                                          />
                                          <span className="custom-toggle-slider  rounded-circle" />
                                        </label>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </td>
                                  <td className="action">
                                  {permissions &&
                                    permissions.map((permission) =>
                                      permission === "update-user" && info.id !== '265a5026-9fd4-40cb-b192-119254c8387d' ? (
                                        <Button
                                          color="primary"
                                          onClick={async () => {
                                            const res = await getUserById({
                                              user_id: info.id,
                                            });
                                            const role = roleList.filter(
                                              (role) =>
                                                role.id === info.role_id
                                            );
                                            const data = {
                                              userInfo: res?.data,
                                              selectedRole: role[0],
                                            };

                                            let baseURL = `/admin/user/updateUser/${info.id}?page=${page}`;
                                            const url = getUrl(baseURL); 
                                            navigate(
                                              url,
                                              { state: { row: data } }
                                            );
                                          }}>
                                          {isLoading && (
                                            <span
                                              className="indicator-progress"
                                              style={{ display: "block" }}>
                                              Please Wait..
                                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                            </span>
                                          )}
                                          {!isLoading && (
                                            <span className="indicator-label">
                                              Edit
                                            </span>
                                          )}
                                        </Button>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={page}
                  pageSize={per_Page}
                  total={totalPages}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink
                    className={"p-0 " + (page !== 1 ? "active-button" : "")}
                    disabled={page === 1}>
                    <PaginationItem aria-label="Previous" onClick={prevPage}>
                      <span >&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    <PaginationItem>{page}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink
                    className={
                      "p-0 " + (page !== totalPages ? "active-button" : "")
                    }
                    disabled={page === totalPages}>
                    <PaginationItem aria-label="Next" onClick={nextPage}>
                      <span >&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {loader}
    </>
  );
}

export default UserList;

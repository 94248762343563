import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:[]
}
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardInfo: (state, action) => {
            state.data = action.payload;
        },
    },
})
export const { setDashboardInfo } = dashboardSlice.actions
export default dashboardSlice.reducer
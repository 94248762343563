import React from "react";
import { Modal } from "reactstrap";

function ImageModal({ show, onClose, image }) {
  return (
    <Modal
      className="modal-dialog-centered "
      isOpen={show}
      toggle={onClose}
    >
      {image != '' && image != null ? (
        <img src={image} alt="book-cover" height="100%" width="100%" />
      ) : (
        <h3 className="m-2">
          Cover not available...
        </h3>
      )}
    </Modal>
  );
}

export default ImageModal;

import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getLightNovelById, getMangaById, getWebtoonById } from "./endpoint";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pageReloadOptions from "./enums";

export default function IllustratorModal({
  openIllustratorModel,
  setOpenIllustratorModel,
  illustratorSummaryData,
  currentMangaId,
  currentWebtoonId,
  currentLightNovelId,
  isPageReload,
  isLinkFunctionalityActive
}) {

  const navigate = useNavigate()

  const handleRedirectToViewMangaPage = async (manga_id) => {
    try {
      const res = await getMangaById({manga_id});
      let url = `/admin/manga/view-manga/${manga_id}?redirect=1`

      if(isPageReload === pageReloadOptions.MANGA){
        setOpenIllustratorModel(false);
        localStorage.setItem('is_same_manga_view_page',true);
        url = `/admin/manga/view-manga/${manga_id}`
      }

      navigate(url,{
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  const handleRedirectToViewLightNovelPage = async (lightNovel_id) => {
    try {
      const res = await getLightNovelById({lightNovel_id});
      let url = `/admin/lightNovel/view-Light-Novel/${lightNovel_id}?redirect=1`

      if(isPageReload === pageReloadOptions.LIGHT_NOVEL){
        setOpenIllustratorModel(false);
        localStorage.setItem('is_same_light_novel_view_page',true);
        url = `/admin/lightNovel/view-Light-Novel/${lightNovel_id}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }


  const handleRedirectToViewWebtoonPage = async (webtoon_id) => {
    try {
      const res = await getWebtoonById({webtoon_id});
      let url = `/admin/webtoon/view-webtoon/${webtoon_id}?redirect=1`

      if(isPageReload === pageReloadOptions.WEBTOON){
        setOpenIllustratorModel(false);
        localStorage.setItem('is_same_webtoon_view_page',true);
        url = `/admin/webtoon/view-webtoon/${webtoon_id}`
      }

      navigate(url, {
        state: { row: res?.data },
      });
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }


  return (
    <Modal
      title="Illustrator Detail"
      centered
      open={openIllustratorModel}
      onOk={() => setOpenIllustratorModel(false)}
      onCancel={() => setOpenIllustratorModel(false)}
      okButtonProps={{ className:"model-bg-color" }}
      cancelButtonProps={{ className:"model-border-color" }}  
    >
      <Card>
        <Row>
          <Col lg="12">
            <Card className="shadow  m-4 detail_card">
              <CardBody className="custom-margin-top">
                <Row>
                  {illustratorSummaryData?.name ? (
                    <>
                      <Col lg="4"> Name</Col>
                      <Col lg="8">
                        <div className="text-dark h4">
                          {illustratorSummaryData.name}
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {illustratorSummaryData?.jp_name ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Name</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {illustratorSummaryData?.jp_name}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                {illustratorSummaryData?.jp_wikipedia ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">JP Wikipedia</Col>
                      <Col lg="8">
                        <span className="text-dark text-wrap">
                          <a
                            href={illustratorSummaryData?.jp_wikipedia}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {illustratorSummaryData?.jp_wikipedia?.length > 50
                              ? `${illustratorSummaryData?.jp_wikipedia.slice(
                                  0,
                                  40
                                )}...`
                              : illustratorSummaryData?.jp_wikipedia}
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                <Row className="mt-1">
                  {illustratorSummaryData?.is_active ||
                  !illustratorSummaryData?.is_active ? (
                    <>
                      <Col lg="4">Is Active</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {illustratorSummaryData?.is_active ? "Yes" : "No"}
                        </span>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                {illustratorSummaryData?.created_at ? (
                  <>
                    <Row className="mt-1">
                      <Col lg="4">CreatedAt</Col>
                      <Col lg="8">
                        <span className="text-dark">
                          {illustratorSummaryData.created_at
                            ? moment(illustratorSummaryData.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : ""}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {illustratorSummaryData?.manga?.length ? (
            <Col lg="12">
              <Card className="shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Manga</h3>
                  <Col>
                    {illustratorSummaryData?.manga?.map((manga) => (
                      <ul>
                        {!isLinkFunctionalityActive ? 
                         <li key={manga.manga_id}>{manga.en_title}</li> : currentMangaId === manga.manga_id ?  <li key={manga.manga_id}>{manga.en_title}</li> : <li className="primary-text-color" style={{cursor: "pointer"}}  onClick={()=>handleRedirectToViewMangaPage(manga.manga_id)} key={manga.manga_id}>{manga.en_title}</li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}

          {illustratorSummaryData?.light_novel?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Light Novel</h3>
                  <Col>
                    {illustratorSummaryData?.light_novel?.map((lightnovel) => (
                      <ul>
                       {!isLinkFunctionalityActive ? 
                         <li key={lightnovel.light_novel_id}>{lightnovel.en_title}</li> :  currentLightNovelId === lightnovel.light_novel_id ? <li key={lightnovel.light_novel_id}>{lightnovel.en_title}</li> : <li className="primary-text-color" style={{cursor: "pointer"}}   onClick={()=>handleRedirectToViewLightNovelPage(lightnovel.light_novel_id)}  key={lightnovel.light_novel_id}>
                         {lightnovel.en_title}
                         </li> 
                        }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
          {illustratorSummaryData?.webtoon?.length ? (
            <Col lg="12">
              <Card className="me-2 shadow  m-4 view_cards">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Webtoon</h3>
                  <Col>
                    {illustratorSummaryData.webtoon.map((webtoon) => (
                      <ul>
                          {!isLinkFunctionalityActive ? 
                            <li key={webtoon.webtoon_id}>{webtoon.en_title}</li> : currentWebtoonId === webtoon.webtoon_id ? <li key={webtoon.webtoon_id}>{webtoon.en_title}</li> : <li className="primary-text-color" style={{cursor: "pointer"}}  onClick={()=>handleRedirectToViewWebtoonPage(webtoon.webtoon_id)} key={webtoon.webtoon_id}>{webtoon.en_title}</li> 
                          }
                      </ul>
                    ))}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Card>
    </Modal>
  );
}

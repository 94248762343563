import Header from "components/Headers/Header";
import moment from "moment";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useSearchParams from "utils/useSearchParams";

function ViewPrePublication() {
  const { state } = useLocation();
  const [prePublicationQueryParams ,setPrePublicationQueryParams] = useSearchParams();
  const currentPage = prePublicationQueryParams.currentPage;
  const fromDate = prePublicationQueryParams.fromDate;
  const toDate = prePublicationQueryParams.toDate;
  const enTitle = prePublicationQueryParams.enTitle;
  const label = prePublicationQueryParams.label;
  const company = prePublicationQueryParams.company;
  const is_active = prePublicationQueryParams.is_active;
  const [prePublication] = useState(state.row);
  const navigate = useNavigate()

  const handleSearchParams = (baseurl) => {
    if(enTitle) baseurl+= `&en_title=${enTitle}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(label) baseurl += `&label=${label}`
    if(company) baseurl+= `&company=${company}`
    if(is_active || is_active === "false") baseurl += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return baseurl;
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card">
                <CardHeader className="border-0">
                  <Row>
                  <Col lg="6">
                  <h1 className="mb-0">Pre-Publication Detail</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className="m-2"
                            onClick={() => {
                              let baseURL = `/admin/prePublication/Prepublication-list?id=${state.row.id}&page=${currentPage}`;
                              const url = handleSearchParams(baseURL);
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row>
                    {prePublication?.en_title ? (
                      <>
                        <Col lg="4">EN Title</Col>
                        <Col lg="8">
                          <div className="text-dark h2">
                            {prePublication.en_title}
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {prePublication?.jp_title ? (
                      <>
                        <Col lg="4">JP Title</Col>
                        <Col lg="8">
                          <div className="text-dark">
                            {prePublication.jp_title}
                          </div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  {prePublication?.jp_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Name</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {prePublication?.jp_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                  {prePublication?.jp_wikipedia ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <span className="text-dark">
                          <a href={prePublication?.jp_wikipedia} target="_blank" rel="noreferrer">
                            {prePublication?.jp_wikipedia?.length > 50 ? `${prePublication?.jp_wikipedia.slice(0, 40)}...` : prePublication?.jp_wikipedia}
                          </a>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  <Row className="mt-1">
                    {prePublication?.is_active || !prePublication?.is_active ? (
                      <>
                        <Col lg="4">Is Active</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {prePublication?.is_active ? "Yes" : "No"}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {prePublication?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {prePublication.created_at
                              ? moment(prePublication.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {prePublication?.authors  ? (
              <Col lg="4">
                  <Card className=" shadow  m-4 view_cards">
                    <CardBody>
                      <h3 className="fw-bolder dot_border">Authors</h3>
                      <Col>
                        {prePublication.authors.map((author, index) => (
                          <li key={index}>{author.name}</li>
                        ))}
                      </Col>
                    </CardBody>
                  </Card>
              </Col>
            ) : (
             ""
            )}
           
            {prePublication?.authors  ? (
              <Col lg="2"></Col>
            ) : (
             ""
            )}

              {prePublication?.manga ? (
            <Col lg="4">
                <Card className="me-2 shadow  m-4 view_cards ">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Manga</h3>
                    <Col>
                      {prePublication?.manga?.map((manga) => (
                        <li key={manga.manga_id}>{manga.en_title}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
            </Col>
              ) : (
               ""
              )}
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default ViewPrePublication;

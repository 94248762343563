import { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { getLightNovelById } from "utils/endpoint";
import { lightNovelFlagList } from "utils/endpoint";
import { Tooltip } from "react-tooltip";
import { webtoonFlagList } from "utils/endpoint";
import { mangaFlagList } from "utils/endpoint";
import { getMangaById } from "utils/endpoint";
import { getWebtoonById } from "utils/endpoint";
import { Pagination as Paginationes, Switch } from "antd";
import { lightNovelUpdateFlagStatus } from "utils/endpoint";
import { mangaUpdateFlagStatus } from "utils/endpoint";
import { webtoonUpdateFlagStatus } from "utils/endpoint";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [totalLightNovelPages, setTotalLightNovelPages] = useState(0);
  const [lightNovelPage, setLightNovelPage] = useState(1);
  const [per_LightNovelPage, setPer_LightNovelPage] = useState(10);
  const [lightNovelFlagLists, setLightNovelFlagLists] = useState([]);
  const [mangaFlagLists, setMangaFlagLists] = useState([]);
  const [webtoonFlagLists, setWebtoonFlagLists] = useState([]);
  const [totalMangaPages, setTotalMangaPages] = useState(0);
  const [mangaPage, setMangaPage] = useState(1);
  const [per_MangaPage, setPer_MangaPage] = useState(10);
  const [totalWebtoonPages, setTotalWebtoonPages] = useState(0);
  const [webtoonPage, setWebtoonPage] = useState(1);
  const [per_WebtoonPage, setPer_WebtoonPage] = useState(10);
  const [lightNovelId,setLightNovelId] = useState('');
  const [mangaId,setMangaId] = useState('');
  const [webtoonId,setWebtoonId] = useState('');
  
  const lightNovelFilter = {
    page: lightNovelPage,
    per_page: per_LightNovelPage,
  };
  const getLightNovelFlagList = async () => {
    setLightNovelFlagLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await lightNovelFlagList(lightNovelFilter);
      if (result?.isSuccess) {
        setIsLoading(false);
        hideLoader();
        setLightNovelFlagLists(result?.data?.data?.records);
        // const totalPages =  Math.ceil(
        //   result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        // )
        // setTotalLightNovelPages(
        //   totalPages === 0 ? 1 : totalPages 
        // );
        setTotalLightNovelPages(result?.data?.data?.totalRecords);
        setPer_LightNovelPage(result?.data?.pagination?.limit || 10);
      } else {
        setIsLoading(false);
        hideLoader();
        setLightNovelFlagLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setLightNovelFlagLists([]);
    }
  };
  const prevPage = async () => {
    setLightNovelPage(lightNovelPage - 1);
  };

  const nextPage = async () => {
    setLightNovelPage(lightNovelPage + 1);
  };
  const mangaFilter = {
    page: mangaPage,
    per_page: per_MangaPage,
  };

  const getMangaFlagList = async () => {
    setMangaFlagLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await mangaFlagList(mangaFilter);
      if (result?.isSuccess) {
        setIsLoading(false);
        hideLoader();
        setMangaFlagLists(result?.data?.data?.records);
        // const totalPages =  Math.ceil(
        //   result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        // )
        // setTotalMangaPages(
        //   totalPages === 0 ? 1 : totalPages 
        // );
        setTotalMangaPages(result?.data?.data?.totalRecords);
        setPer_MangaPage(result?.data?.pagination?.limit || 10);
      } else {
        setIsLoading(false);
        hideLoader();
        setMangaFlagLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setMangaFlagLists([]);
    }
  };
  const prevMangaPage = async () => {
    setMangaPage(mangaPage - 1);
  };

  const nextMangaPage = async () => {
    setMangaPage(mangaPage + 1);
  };

  const webtoonFilter = {
    page: webtoonPage,
    per_page: per_WebtoonPage,
  };
  const getWebtoonFlagList = async () => {
    setWebtoonFlagLists([]);
    setIsLoading(true);
    showLoader();
    try {
      const result = await webtoonFlagList(webtoonFilter);
      if (result?.isSuccess) {
        setIsLoading(false);
        hideLoader();
        setWebtoonFlagLists(result?.data?.data?.records);
        // const totalPages =  Math.ceil(
        //   result?.data?.data?.totalRecords / result?.data?.pagination?.limit
        // )
        // setTotalWebtoonPages(
        //   totalPages === 0 ? 1 : totalPages 
        // );
        setTotalWebtoonPages(result?.data?.data?.totalRecords);

        setPer_WebtoonPage(result?.data?.pagination?.limit || 10);
      } else {
        setIsLoading(false);
        hideLoader();
        setWebtoonFlagLists([]);
      }
    } catch (error) {
      setIsLoading(false);
      hideLoader();
      setWebtoonFlagLists([]);
    }
  };
  const prevWebtoonPage = async () => {
    setWebtoonPage(webtoonPage - 1);
  };

  const nextWebtoonPage = async () => {
    setWebtoonPage(webtoonPage + 1);
  };

  const updateLightNovelFlagStatus = async (lightNovelId) => {
    await lightNovelUpdateFlagStatus({
      light_novel_id: lightNovelId,
    });

    setTimeout(() => {
      setLightNovelId(lightNovelId);
    }, 300);
  };

  const updateMangaFlagStatus = async (mangaId) => {
    await mangaUpdateFlagStatus({
        manga_id: mangaId,
    });

    setTimeout(() => {
      setMangaId(mangaId);
    }, 300);
  };

  const updateWebtoonFlagStatus = async (webtoonId) => {
    await webtoonUpdateFlagStatus({
      webtoon_id: webtoonId,
    });
    setTimeout(() => {
      setWebtoonId(webtoonId);
    }, 300);
  };

  useEffect(()=> {
    getLightNovelFlagList(lightNovelFilter);
  },[lightNovelPage,per_LightNovelPage,lightNovelId])

  useEffect(() => {
    getWebtoonFlagList(webtoonFilter);
  },[webtoonPage,per_WebtoonPage,webtoonId]);
  
  useEffect(()=> {
    getMangaFlagList(mangaFilter);
  },[mangaPage,per_MangaPage,mangaId])

  return (
    <>
      <div className="header bg-gradient-info-new dashboard_Header pt-0">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="6" className="mt-4">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <h1 className="mb-0"> Light Novel Flag List</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th className="" title="Flag Status">FS</th>
                            <th className="">Flag By</th>
                            <th className="">EN Title</th>
                            <th className="">JP Title</th>
                            <th className="">Universe</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lightNovelFlagLists.length > 0 ? (
                            lightNovelFlagLists.map((info, index) => {
                              return (
                                <tr key={info.id}>

                                  <td>
                                    <Switch size="small" defaultChecked onClick={()=>updateLightNovelFlagStatus(info.id)} />
                                  </td>
                                  
                                  <td>
                                    <i
                                      className={
                                        "fa fa-info-circle tooltip" + info.id
                                      }
                                      aria-hidden="true"></i>

                                    <Tooltip
                                      anchorSelect={".tooltip" + info.id}>
                                      {info.flag_users.map((flag, index) => (
                                        <li key={index}>
                                          {flag.first_name} {flag.last_name}
                                        </li>
                                      ))}
                                    </Tooltip>
                                  </td>

                                  <td className="primary-text-color" style={{ cursor: "pointer" }}>
                                    <Link
                                      className="indicator-label"
                                      onClick={async () => {
                                        const res = await getLightNovelById({
                                          lightNovel_id: info.id,
                                        });
                                        navigate(
                                          `/admin/lightNovel/view-Light-Novel/${info.id}?redirect=1&is_form_dashboard=1`,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                  </td>

                                  <td>{info.jp_title}</td>
                                  <td>{info.universe_name}</td>
                                  
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={lightNovelPage}
                  pageSize={per_LightNovelPage}
                  total={totalLightNovelPages}
                  onChange={(value) => setLightNovelPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (lightNovelPage !== 1 ? 'active-button' : '')} disabled={lightNovelPage === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      onClick={prevPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{lightNovelPage}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (lightNovelPage !== totalLightNovelPages ? 'active-button' : '')} disabled={lightNovelPage === totalLightNovelPages}>
                    <PaginationItem
                      aria-label="Next"
                      onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="6" className="mt-4">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <h1 className="mb-0"> Manga Flag List</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th className="" title="Flag Status">FS</th>
                            <th className="">Flag By</th>
                            <th className="">EN Title</th>
                            <th className="">JP Title</th>
                            <th className="">Universe</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mangaFlagLists.length > 0 ? (
                            mangaFlagLists.map((info, index) => {
                              return (
                                <tr key={info.id}>
                                  <td>
                                    <Switch size="small" defaultChecked onClick={()=>updateMangaFlagStatus(info.id)} />
                                  </td>
                                  <td>
                                    <i
                                      className={
                                        "fa fa-info-circle tooltip" + info.id
                                      }
                                      aria-hidden="true"></i>

                                    <Tooltip
                                      anchorSelect={".tooltip" + info.id}>
                                      {info.flag_users.map((flag, index) => (
                                        <li key={index}>
                                          {flag.first_name} {flag.last_name}
                                        </li>
                                      ))}
                                    </Tooltip>
                                  </td>

                                  <td className="primary-text-color" style={{ cursor: "pointer" }}>
                                    <Link
                                      className="indicator-label"
                                      onClick={async () => {
                                        const res = await getMangaById({
                                          manga_id: info.id,
                                        });
                                        navigate(
                                          `/admin/manga/view-manga/${info.id}?redirect=1&is_form_dashboard=1`,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                  </td>

                                  <td>{info.jp_title}</td>
                                  <td>{info.universe_name}</td>
                                 
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={mangaPage}
                  pageSize={per_MangaPage}
                  total={totalMangaPages}
                  onChange={(value) => setMangaPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (mangaPage !== 1 ? 'active-button' : '')} disabled={mangaPage === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      onClick={prevMangaPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{mangaPage}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (mangaPage !== totalMangaPages ? 'active-button' : '')} disabled={mangaPage === totalMangaPages}>
                    <PaginationItem
                      aria-label="Next"
                      onClick={nextMangaPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mt-4">
            <Card className="shadow ">
              <CardHeader className="border-0">
                <h1 className="mb-0"> Webtoon Flag List</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <div
                      className="table-responsive p-0 pb-2 fs-6"
                      id="data-table">
                      <table className="table align-items-center display justify-content-center mb-0 w-100">
                        <thead>
                          <tr>
                            <th className="" title="Flag Status">FS</th>
                            <th className="">Flag By</th>
                            <th className="">EN Title</th>
                            <th className="">JP Title</th>
                            <th className="">Universe</th>
                          </tr>
                        </thead>
                        <tbody>
                          {webtoonFlagLists.length > 0 ? (
                            webtoonFlagLists.map((info, index) => {
                              return (
                                <tr key={info.id}>
                                  <td>
                                    <Switch size="small" defaultChecked onClick={()=>updateWebtoonFlagStatus(info.id)}/>
                                  </td>
                                  <td>
                                    <i
                                      className={
                                        "fa fa-info-circle tooltip" + info.id
                                      }
                                      aria-hidden="true"></i>

                                    <Tooltip
                                      anchorSelect={".tooltip" + info.id}>
                                      {info.flag_users.map((flag, index) => (
                                        <li key={index}>
                                          {flag.first_name} {flag.last_name}
                                        </li>
                                      ))}
                                    </Tooltip>
                                  </td>

                                  <td className="primary-text-color" style={{ cursor: "pointer" }}>
                                    <Link
                                      className="indicator-label"
                                      onClick={async () => {
                                        const res = await getWebtoonById({
                                          webtoon_id: info.id,
                                        });
                                        navigate(
                                          `/admin/webtoon/view-webtoon/${info.id}?redirect=1&is_form_dashboard=1`,
                                          { state: { row: res?.data } }
                                        );
                                      }}>
                                      {info.en_title}
                                    </Link>
                                  </td>

                                  <td>{info.jp_title}</td>
                                  <td>{info.universe_name}</td>
                                  
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={13}>
                                No Result Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="py-4">
              <Paginationes
                  className="pagination justify-content-end mb-0"
                  current={webtoonPage}
                  pageSize={per_WebtoonPage}
                  total={totalWebtoonPages}
                  onChange={(value) => setWebtoonPage(value)}
                  showSizeChanger={false}
                />
                {/* <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationLink className={"p-0 " + (webtoonPage !== 1 ? 'active-button' : '')} disabled={webtoonPage === 1}>
                    <PaginationItem
                      aria-label="Previous"
                      onClick={prevWebtoonPage}>
                      <span aria-hidden="true">&laquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                  <PaginationLink>
                    {" "}
                    <PaginationItem>{webtoonPage}</PaginationItem>
                  </PaginationLink>
                  <PaginationLink className={"p-0 " + (webtoonPage !== totalWebtoonPages ? 'active-button' : '')} disabled={webtoonPage === totalWebtoonPages}>
                    <PaginationItem
                      aria-label="Next"
                      onClick={nextWebtoonPage}>
                      <span aria-hidden="true">&raquo;</span>
                    </PaginationItem>
                  </PaginationLink>
                </Pagination> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      {loader}
    </>
  );
};

export default Dashboard;

import axios from "axios";
import { url } from "./host.js";
export const Get = async (path, data, sendAuthHeader = false) => {
  try {
    const headers = {};
    if (sendAuthHeader) {
      
      const loggedInUser = JSON.parse(localStorage.getItem("User"));
      headers["Authorization"] = `Bearer ${loggedInUser.access_token}`;
    }
    const response = await axios.get(`${url}${path}${data}`, {
      headers: headers,
    });
    return response?.data;
  } catch (err) {
    if (err.response?.status === 401) {
      window.location = "auth/login";
      localStorage.setItem("User", null);
      return;
    }
    throw new Error(err.message);
  }
};
export const Post = async (path, data, sendAuthHeader = false) => {
  try {
    const headers = {};
    if (sendAuthHeader) {
      const loggedInUser = JSON.parse(localStorage.getItem("User"));
      headers["Authorization"] = `Bearer ${loggedInUser.access_token}`;
    }
    const response = await axios.post(`${url}${path}`, data, {
      headers: headers,
    });

    return response?.data;
  } catch (err) {
    if (err.response?.status === 401) {
      window.location = "auth/login";
      localStorage.setItem("User", null);
      return;
    }
    throw new Error(err.message);
  }
};
export const Put = async (path, data, sendAuthHeader = false) => {
  try {
    const headers = {};
    if (sendAuthHeader) {
      const loggedInUser = JSON.parse(localStorage.getItem("User"));
      headers["Authorization"] = `Bearer ${loggedInUser.access_token}`;
    }
    const response = await axios.put(`${url}${path}`, data, {
      headers: headers,
    });

    return response?.data;
  } catch (err) {
    if (err.response?.status === 401) {
      window.location = "auth/login";
      localStorage.setItem("User", null);
      return;
    }
    throw new Error(err.message);
  }
};
export const GetCustom = async (path, sendAuthHeader = false, userData) => {
  try {
    const headers = {};
    if (sendAuthHeader) {
      const loggedInUser = userData;
      headers["Authorization"] = `Bearer ${loggedInUser.token}`;
    }
    const response = await axios.get(`${url}${path}`, { headers: headers });
    return {
      data: response?.data,
      success: response?.status === 200,
    };
  } catch (err) {
    if (err.response?.status === 401) {
      window.location = "auth/login";
      return;
    }
    throw new Error(err.message);
  }
};
export const PostCustom = async (
  path,
  data,
  userData,
  sendAuthHeader = false
) => {
  try {
    const headers = {};
    if (sendAuthHeader) {
      const loggedInUser = userData;
      headers["Authorization"] = `Bearer ${loggedInUser?.access_token}`;
    }
    const response = await axios.post(`${url}${path}`, data, {
      headers: headers,
    });
    return response?.data;
  } catch (err) {
    if (err.response?.status === 401) {
      window.location = "auth/login";
      return;
    }
    throw new Error(err.message);
  }
};
export const formReducer = (state, event) => {
  if (!event.target) {
    return {
      ...state,
      ...event,
    };
  }
  return {
    ...state,
    [event.target?.name]: event.target?.value,
  };
};

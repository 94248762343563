import clsx from "clsx";
import Header from "components/Headers/Header";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { updateIllustrator } from "utils/endpoint";
import UseFullPageLoader from "utils/UseFullPageLoader";
import useSearchParams from "utils/useSearchParams";
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
});
function UpdateIllustrator() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [illustratorQueryParams ,setIllustratorQueryParams] = useSearchParams();
  const currentPage = illustratorQueryParams.currentPage;
  const fromDate = illustratorQueryParams.fromDate;
  const toDate = illustratorQueryParams.toDate;
  const name = illustratorQueryParams.name;
  const jpName = illustratorQueryParams.jpName;
  const is_active = illustratorQueryParams.is_active;
  const [illustrator] = useState(state.row);
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] =UseFullPageLoader();
  const params = useParams();
  const { id } = params;

  const handleSearchParams = (updatedObj) => {
    let url = `/admin/illustrator/illustrator-list?id=${id}&page=${currentPage}`;
    if(name) url+= `&name=${updatedObj?.name || name}`
    if(fromDate) url+= `&from_date=${fromDate}`
    if(toDate) url+= `&to_date=${toDate}`
    if(jpName) url += `&jp_name=${updatedObj?.jp_name || jpName}`
    if(is_active || is_active === "false") url += `&is_active=${is_active === "true" ? "true" : is_active === "none" ? "none" :"false"}`;
    return url;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: illustrator?.name,
        jp_name: illustrator?.jp_name,
        jp_wikipedia: illustrator?.jp_wikipedia,
        is_active: illustrator?.is_active ?? "",
      }}
      validationSchema={validationSchema}

      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        showLoader()
        const updateData = {
            name: values.name,
            jp_name: values.jp_name,
            jp_wikipedia: values.jp_wikipedia,
            is_active: values.is_active,
        };
        const result = await updateIllustrator(id, updateData);
        if (result?.isSuccess) {
          toast.success(`${result.message}`);
          setLoading(false);
          hideLoader()
          resetForm();
          const url =  handleSearchParams(updateData)
          navigate(url);
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader()
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                    <Row>
                        <Col lg="6">
                        <h1 className="mb-0">Update Illustrator</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              const url =  handleSearchParams()
                              navigate(url);
                            }}>
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                      
                    </CardHeader>
                    <CardBody>
                    <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label required">
                            Name
                            </label>

                            <Input
                               className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                            />
                             <div className="text-danger text-align-top-center mt-1">
                              <ErrorMessage name="name" />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">JP Name</label>
                            <Input
                              className="form-control"
                              id="jp_name"
                              name="jp_name"
                              placeholder="JP Name"
                              type="text"
                              value={values.jp_name === null ? '' : values.jp_name }
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col> 
                        <Col lg="3">
                          <FormGroup>
                            <label className="form-control-label ">JP Wikipedia</label>
                            <Input
                              className="form-control"
                              id="jp_wikipedia"
                              name="jp_wikipedia"
                              placeholder="JP Wikipedia"
                              type="text"
                              value={values.jp_wikipedia === null ? '' : values.jp_wikipedia }
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      
                       
                      </Row>
                     
                      <Row>
                        <Col lg="3">
                        <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary">
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}>
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                Update Illustrator
                              </span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
}

export default UpdateIllustrator;

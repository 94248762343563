import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from "reactstrap";
import { login } from "utils/endpoint";
import { verify2faOtp } from "utils/endpoint";
import { generate2FaQrCode } from "utils/endpoint";
import { setAuth } from "../../redux/authSlice";
import { toast } from "react-toastify";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [actionType, setActionType] = useState(1);
  const [userData, setUserData] = useState([]);
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      if (!email) return toast.error("User Id is required!");
      if (!password) return toast.error("Password is required!");

      setLoader(true);
      const res = await login({ email, password });
      if (res?.isSuccess) {
        setUserData(res?.data);
        if (res?.data?.user?.is_otp_verified === true) {
          setActionType(4);
        } else {
          setActionType(2);
        }
        setLoader(false);
      } else {
        setLoader(false);
        return toast.error(res?.message || "Server error");
      }
    } catch (err) {
      setLoader(false);
      return toast.error("Something went wrong, Please try again letter!");
    }
  };

  const generate2faOtpCb = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const res = await generate2FaQrCode({}, userData);
      if (res?.isSuccess) {
        userData.otpAuthUrl = res?.data?.otp_auth_url;
        userData.secret = res?.data?.otp_base32;
        userData.qr_code = res?.data?.qr_code;
        setqrCodeUrl(res?.data?.qr_code);
        setActionType(3);
        setUserData(userData);
        setLoader(false);
      } else {
        setError(
          res?.message || "Something went wrong, Please try again latter!"
        );
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      setError("Something went wrong, Please try again latter!");
    }
  };

  const verify2faOtpCb = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      if (otp && otp !== "") {
        if (otp.length !== 6) {
          setError("Authentication code must consist of 6 numbers!");
          setLoader(false);
          return;
        }
        const res = await verify2faOtp({ token: otp }, userData);
        if (res.isSuccess) {
          setUserData(userData);
          localStorage.setItem(
            "permissions",
            JSON.stringify(userData?.permissions || [])
          );
          localStorage.setItem("User", JSON.stringify(userData));
          localStorage.setItem('is_same_manga_view_page',false);
          localStorage.setItem('is_same_light_novel_view_page',false);
          localStorage.setItem('is_same_webtoon_view_page',false);
          dispatch(setAuth(userData));
          window.location.reload();
          navigate("/admin/dashboard");
          setLoader(false);
        } else {
          setError(
            res?.message || "Something went wrong, Please try again latter!"
          );
          setLoader(false);
        }
      } else {
        setLoader(false);
        setError("Authentication code is required!");
      }
    } catch (err) {
      setLoader(false);
      setError("Something went wrong, Please try again latter!");
    }
  };

  useEffect(() => {
    if (userData?.user?.qr_code) {
      setqrCodeUrl(userData?.user?.qr_code);
      // QRCode.toDataURL(userData?.user?.qr_code).then(setqrCodeUrl);
    }
  }, [userData?.user?.qr_code]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="text-center">
            <h1>Sign In</h1>
          </CardHeader>
          {actionType === 1 && (
            <CardBody className="px-lg-4 py-lg-4">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      onInput={(e) => {
                        setEmail(e.target.value);
                        setError("");
                      }}
                      // value={email}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                      onInput={(e) => {
                        setPassword(e.target.value);
                        setError("");
                      }}
                      // value={password}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="position-relative  my-3 mb-4">
                  <small className="text-primary position-absolute">
                    {error}
                  </small>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    disabled={loader}
                    onClick={loginUser}
                    color="primary"
                    type="submit">
                    Sign in{" "}
                    {loader ? (
                      <Spinner
                        className="spinnerLoader"
                        type="grow"
                        color="white"
                      />
                    ) : null}
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
          {actionType === 2 && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <h3 className="text-center text-3xl font-semibold text-[#142149]">
                  2FA Authentication
                </h3>
                <p className="text-center text-sm">
                  Setup your two-factor authentication.
                </p>
                <div className="position-relative  my-3 mb-4">
                  <small className="text-primary position-absolute">
                    {error}
                  </small>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    disabled={loader}
                    onClick={generate2faOtpCb}
                    color="primary"
                    type="submit">
                    Enable 2FA{" "}
                    {loader ? (
                      <Spinner
                        className="spinnerLoader"
                        type="grow"
                        color="white"
                      />
                    ) : null}
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
          {actionType === 3 && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <h1 className="text-center">Scan QR Code</h1>
                <p className="text-center text-sm">
                  Open the two-step verification app on your mobile device and
                  scan given QR code to get your verification code.
                </p>
                <div className="mb-3">
                  <div className="text-center">
                    <img className="w-100" src={qrcodeUrl} alt="qrcode url" />
                  </div>
                </div>
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bold-right" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Authentication Code"
                      type="text"
                      maxLength={6}
                      onInput={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="position-relative  my-3 mb-4">
                  <small className="text-primary position-absolute">
                    {error}
                  </small>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    disabled={loader}
                    onClick={verify2faOtpCb}
                    color="primary"
                    type="submit">
                    Verify & Login{" "}
                    {loader ? (
                      <Spinner
                        className="spinnerLoader"
                        type="grow"
                        color="white"
                      />
                    ) : null}
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
          {actionType === 4 && (
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <h3 className="text-center">2FA Verification</h3>
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bold-right" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Authentication Code"
                      type="text"
                      maxLength={6}
                      onInput={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="position-relative  my-3 mb-4">
                  <small className="text-primary position-absolute">
                    {error}
                  </small>
                </div>
                <div className="text-center">
                  <Button
                    className="my-4"
                    disabled={loader}
                    onClick={verify2faOtpCb}
                    color="primary"
                    type="submit">
                    Verify & Sign in{" "}
                    {loader ? (
                      <Spinner
                        className="spinnerLoader"
                        type="grow"
                        color="white"
                      />
                    ) : null}
                  </Button>
                </div>
              </Form>
            </CardBody>
          )}
        </Card>
      </Col>
    </>
  );
};

export default Login;

import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";

// core components


import routes from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("main-bg2");
    return () => {
      document.body.classList.remove("main-bg2");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    if(routes?.key !== null){
      return routes?.map((prop, key) => {
        if (prop.layout === "/auth") {
          return (
            <Route path={prop.path} element={prop.component} key={key} exact />
          );
        } else {
          return null;
        }
      });
    }
    return;
  
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header main-bg-color py-8 py-lg-9">
          <Container>
            <div className="header-body text-center mb-7">
            </div>
          </Container>
          <div className="separator  separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"

            >
              <polygon
                class="fill-default"  
                points="2560 0 2560 100 0 100"
                style={{fill: "#e62a2e"}}  
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;

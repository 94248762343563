import Header from "components/Headers/Header";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardImg,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import ImageModal from "utils/ImageModal";
import { getWebtoonById,deleteWebtoonComment } from "utils/endpoint";
import { webtoonUpdateFlagStatus } from "utils/endpoint";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { RiDeleteBin6Line } from "react-icons/ri";
import { getAuthorSummaryById } from "utils/endpoint";
import { getUniverseSummaryById } from "utils/endpoint";
import { getIllustratorSummaryById } from "utils/endpoint";
import UniverseModal from "utils/universeModal";
import AuthorModal from "utils/authorModal";
import IllustratorModal from "utils/illustratorModal";
import useSearchParams from "utils/useSearchParams";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { handleWebtoonBookMark } from "./WebtoonList";
import { CSVLink } from "react-csv";
import { genrateCsvFileName } from "utils/Commen";
import  isPageReload  from "utils/enums";
import RatingUserListModal from "utils/ratingUserListModal";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const headers = [
  { label: "EN Title", key: "en_title" },
  { label: "Universe Name", key: "universe_name" },
  { label: "Status", key: "status_name" },
  { label: "Cover Image", key: "cover_image" },
  { label: "JP Title", key: "jp_title" },
  { label: "KR Title", key: "kr_title" },
  { label: "Authors", key: "authors" },
  { label: "Illustrators", key: "illustrators" },
  { label: "No. of chpt", key: "no_of_volumes" },
  { label: "Ongoing", key: "ongoing" },
  { label: "Type", key: "type_name" },
  { label: "Genres", key: "genres" },
  { label: "Publics", key: "publics" },
  { label: "JP Platform", key: "jp_platform" },
  { label: "KR Platform", key: "kr_platform" },
  { label: "US Platform", key: "us_platform"},
  { label: "FR Platform", key: "fr_platform"},
  { label: "JP Platform Likes Views Subscribers", key: "jp_platform_likes"},
  { label: "KR Platform Likes Views Subscribers", key: "kr_platform_likes"},
  { label: "US Platform Likes Views Subscribers", key: "us_platform_likes"},
  { label: "FR Platform Likes Views Subscribers", key: "fr_platform_likes"},
  { label: "Low Sales", key: "low_sales" },
  { label: "Medium Sales", key: "medium_sales" }, 
  { label: "High Sales", key: "high_sales" },
  { label: "FR Consumer Price", key: "fr_consumer_price" },
  { label: "Product P&L", key: "product_pl" },
  { label: "JP Wikipedia", key: "jp_wikipedia_link" },
  { label: "KR Wikipedia", key: "kr_wikipedia_link" },
  { label: "ScanTrad", key: "scantrad" },
  { label: "Flag To Users", key: "flag_to_users" },
  { label: "CreatedAt", key: "created_at" },
];

export const getData = (data) => {
  const webtoonData = [{
    en_title:data?.en_title,
    universe_name:data?.universe_name,
    status_name:data?.status_name,
    jp_title:data?.jp_title,
    kr_title:data?.kr_title,
    cover_image:data?.cover_image,
    authors:data?.authors.map((author)=>author.name.split(', ')),
    illustrators:data?.illustrators.map((illustrator)=>illustrator.name.split(', ')),
    no_of_volumes:data?.no_of_volumes,
    ongoing:data?.ongoing === true ? "Yes" : "No",
    type_name:data?.type_name,
    genres:data?.genres?.map((genre)=>genre.name.split(', ')),
    publics:data?.publics?.map((publicsD)=> publicsD.name.split(', ')),
    jp_wikipedia_link:data?.jp_wikipedia_link,
    kr_wikipedia_link:data?.kr_wikipedia_link,
    low_sales:data?.low_sales,
    medium_sales:data?.medium_sales,
    high_sales:data?.high_sales,
    fr_consumer_price:data?.fr_consumer_price,
    jp_platform:data?.jp_platform,
    kr_platform:data?.kr_platform,
    us_platform:data?.us_platform,
    fr_platform:data?.fr_platform,
    jp_platform_likes:data?.jp_platform_likes,
    kr_platform_likes:data?.kr_platform_likes,
    us_platform_likes:data?.us_platform_likes,
    fr_platform_likes:data?.fr_platform_likes,
    scantrad:data?.scantrad,
    flag_to_users:data?.flag_to_users?.map((flaguser)=>flaguser.first_name.split(', ')),
    product_pl:data?.product_pl,
    created_at:moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")
  }];

  return webtoonData
}

function ViewWebtoon() {
  const { state } = useLocation();
  const [webtoonQueryParams ,setWebtoonQueryParams] = useSearchParams();
  const currentPage = webtoonQueryParams.currentPage;
  const enTitle = webtoonQueryParams.enTitle;
  const fromDate = webtoonQueryParams.fromDate;
  const toDate = webtoonQueryParams.toDate;
  const userIdString = webtoonQueryParams.userIdString;
  const statusIdString = webtoonQueryParams.statusIdString;
  const typeIdString = webtoonQueryParams.typeIdString;
  const is_bookmark = webtoonQueryParams.is_bookmark;
  const is_form_dashboard = webtoonQueryParams.is_form_dashboard !== null || undefined && webtoonQueryParams.is_form_dashboard ? true : false;
  const redirect = webtoonQueryParams.redirect !== null || undefined && webtoonQueryParams.redirect;
  const [webtoon,setWebtoon] = useState(state.row);
  const [show, setShowModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [currentComment, setCurrentComment] = useState(state.row.comment);
  const [currentLoggedInUser,setCurrentLoggedInUser] = useState({})
  const [universeSummaryData, setUniverseSummaryData] = useState([]);
  const [illustratorSummaryData, setIllustratorSummaryData] = useState([]);
  const [authorSummaryData, setAuthorSummaryData] = useState([]);
  const [openUniverseModel, setOpenUniverseModel] = useState(false);
  const [openAuthorModel, setOpenAuthorModel] = useState(false);
  const [openIllustratorModel, setOpenIllustratorModel] = useState(false);
  const [image, setImage] = useState("");
  const [csvData,setCsvData] = useState([])
  const [openUserRatingListModel, setOpenUserRatingListModel] = useState(false);
  const [ratingType, setRatingType] = useState('');
  const [ratingInfo, setRatingInfo] = useState([]);
  const navigate = useNavigate();

  const { confirm } = Modal;
  const webtoonId = state.row.id;
  const isSameWebtoonViewPage = localStorage.getItem('is_same_webtoon_view_page');

  const handleDeleteWebtoonComment = async () => {
    try {
      setCurrentComment('')
      const response = await deleteWebtoonComment(webtoonId);
      if (response.isSuccess) {
        toast.success(`${response.message}`);
      }
    } catch (error) {
      setCurrentComment(state.row.comment)
      toast.error(`${error.message}`);
    }
  };

  const fetchUniverseDetail = async (unvierseId) => {
    setOpenUniverseModel(true);
    const res = await getUniverseSummaryById(unvierseId);

    if (res && res.isSuccess) {
      setUniverseSummaryData(res.data);
    }
  };

  const fetchAuthorsDetail = async (authorId) => {
    setOpenAuthorModel(true);
    const res = await getAuthorSummaryById(authorId);
    if (res && res.isSuccess) {
      setAuthorSummaryData(res.data);
    }
  };

  const fetchIllustratorDetail = async (illustratorId) => {
    setOpenIllustratorModel(true);
    const res = await getIllustratorSummaryById(illustratorId);
    if (res && res.isSuccess) {
      setIllustratorSummaryData(res.data);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this comment ?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        handleDeleteWebtoonComment();
      },
    });
  };

  // const updateFlagStatus = () => {
  //   webtoonUpdateFlagStatus({
  //     webtoon_id: state.row.id,
  //   });
  // };
  useEffect(() => {
    // updateFlagStatus();
    const data = getData(webtoon)
    setCsvData(data)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("User"));
    if (loginData) {
      setPermissions(loginData.permissions);
      setCurrentLoggedInUser(loginData.user);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getWebtoonById({
          webtoon_id: webtoonId,
        });
        if(response && response.isSuccess){
          setCurrentComment(response.data.comment)
        }
      } catch (error) {
        toast.error(`${error.message}`);
      }
    })();
  }, []);

  const handleSearchParams = (baseurl,isback,isRedirect) => {
    if(currentPage) {
      isback ?  baseurl+=`&page=${currentPage}` : baseurl+=`?page=${currentPage}`
    }
    if(enTitle) baseurl+= `&en_title=${ enTitle}`
    if(fromDate) baseurl+= `&from_date=${fromDate}`
    if(toDate) baseurl+= `&to_date=${toDate}`
    if(userIdString) baseurl+= `&user_id=${userIdString}`
    if(typeIdString) baseurl += `&type_id=${typeIdString}`
    if(statusIdString) baseurl += `&status_id=${statusIdString}`    
    if(is_bookmark || is_bookmark === "false" || is_bookmark === "none") baseurl += `&is_bookmark=${is_bookmark === "true" ? "true" : is_bookmark === "none" ? "none" :"false"}`;
    if(isRedirect && isSameWebtoonViewPage == 'false' && !is_form_dashboard){
      baseurl += `&redirect=1`
    };

    return baseurl;
  }

  useEffect(()=>{
    setWebtoon(state.row);
  },[state.row])

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <Row>
            <Col lg="7">
              <Card className="shadow m-4 detail_card">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg="5">
                      {" "}
                      <h1 className="mb-0"> Webtoon Detail
                      <Checkbox
                              {...label}
                              icon={<BookmarkBorderIcon />}
                              checkedIcon={<BookmarkIcon />}
                              defaultChecked={webtoon?.bookmark_id !== null ? true : false}
                              onClick={(e)=>handleWebtoonBookMark({isChecked:e.target.checked,webtoonId:webtoon?.id})}
                          />
                      </h1>
                    </Col>
                    <Col lg="7" className="add_btn text-right">
                      {permissions &&
                        permissions.map((permission) =>
                          permission === "update-webtoon" ? (
                            <Button
                              color="primary"
                              onClick={async () => {
                                const res = await getWebtoonById({
                                  webtoon_id: state.row.id,
                                });

                                let isSetRedirect = true;

                                let baseURL = `/admin/webtoon/update-webtoon/${webtoonId}`
                                
                                if(redirect == 1 || is_form_dashboard){
                                  baseURL += '?redirect=1'
                                  isSetRedirect = false
                                }
     
                                if(redirect == 2){
                                  baseURL += '?redirect=2'
                                }

                                const url = handleSearchParams(baseURL,false,isSetRedirect);
                                navigate(
                                  url,
                                  {
                                    state: { row: res?.data },
                                  }
                                );
                              }}
                            >
                              <span className="indicator-label">Edit</span>
                            </Button>
                          ) : (
                            ""
                          )
                        )}

                      <CSVLink
                          data={csvData}
                          headers={headers}
                          filename={genrateCsvFileName(`webtoon_${webtoon.en_title}`)}
                          className="btn btn-primary"
                          target="_blank"
                        >
                          Export to CSV
                      </CSVLink>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() =>{
                          navigate(`/admin/webtoon/view-Webtoon-Print-Selection/${webtoonId}`,{state:{row:webtoon}});
                        }}
                      >
                        <span className="me-2">Print</span>
                      </Button>

                      <Button
                        color="primary"
                        className="m-2"
                        onClick={() => {
                          if(isSameWebtoonViewPage == 'true'){
                            navigate(-1)
                            localStorage.setItem('is_same_webtoon_view_page',false);
                          }
                          
                          if(redirect == 1){
                            navigate(-1);
                          }

                          if(redirect == 2 && redirect !== 1 || !redirect){
                            let baseURL = `/admin/webtoon/webtoon-list?id=${webtoonId}`;
                            const url = handleSearchParams(baseURL,true);
                            navigate(url);
                            localStorage.setItem('is_same_webtoon_view_page',false);
                          }

                        }}
                      >
                        <span className="me-2">Back</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="custom-margin-top">
                  <Row>
                    {webtoon?.en_title ? (
                      <>
                        <Col lg="4"> EN Title</Col>
                        <Col lg="8">
                          <div className="text-dark h2">{webtoon.en_title}</div>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="mt-1">
                    {webtoon?.universe_name ? (
                      <>
                        <Col lg="4">Universe Name</Col>
                        <Col lg="8">
                          <span
                            className="primary-text-color"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              fetchUniverseDetail(webtoon.universe_id)
                            }
                          >
                            {webtoon?.universe_name}
                          </span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row className="mt-1">
                    {webtoon?.jp_title ? (
                      <>
                        <Col lg="4">JP Title</Col>
                        <Col lg="8">
                          <span className="text-dark">{webtoon?.jp_title}</span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row className="mt-1">
                    {webtoon?.kr_title ? (
                      <>
                        <Col lg="4">KR Title</Col>
                        <Col lg="8">
                          <span className="text-dark">{webtoon?.kr_title}</span>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>

                  {webtoon?.status_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Status</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.status_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.no_of_volumes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">No. of chpt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.no_of_volumes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.ongoing || !webtoon?.ongoing && webtoon?.ongoing !== null ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Ongoing</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon.ongoing === true ? "Yes" : "No"}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.type_name ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">Type</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.type_name}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.jp_platform ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Platform</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.jp_platform}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.kr_platform ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">KR Platform</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.kr_platform}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.fr_platform ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">FR Platform</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.fr_platform}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.us_platform ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">US Platform</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.us_platform}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.jp_platform_likes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Platform Likes</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.jp_platform_likes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.kr_platform_likes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">KR Platform Likes</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.kr_platform_likes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.fr_platform_likes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">FR Platform Likes</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.fr_platform_likes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.us_platform_likes ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">US Platform Likes</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.us_platform_likes}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.jp_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">JP Wikipedia</Col>
                        <Col lg="8">
                          <div className="link primary-text-color">
                            <a
                              href={webtoon?.jp_wikipedia_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {webtoon?.jp_wikipedia_link?.length > 50
                                ? `${webtoon?.jp_wikipedia_link.slice(
                                    0,
                                    40
                                  )}...`
                                : webtoon?.jp_wikipedia_link}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.kr_wikipedia_link ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">KR Wikipedia</Col>
                        <Col lg="8">
                          <div className="link primary-text-color">
                            <a
                              href={webtoon?.kr_wikipedia_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {webtoon?.kr_wikipedia_link?.length > 50
                                ? `${webtoon?.kr_wikipedia_link.slice(
                                    0,
                                    40
                                  )}...`
                                : webtoon?.kr_wikipedia_link}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {webtoon?.scantrad ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">ScanTrad</Col>
                        <Col lg="8">
                          <div className="link primary-text-color">
                            <a
                              href={webtoon?.scantrad}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {webtoon?.scantrad?.length > 50
                                ? `${webtoon?.scantrad.slice(
                                    0,
                                    40
                                  )}...`
                                : webtoon?.scantrad}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {permissions &&
                    permissions.map((permission) =>
                      permission === "view-webtoon-low-sale" ? (
                        <>
                          {webtoon?.low_sales ? (
                              <Row className="mt-1">
                                <Col lg="4">Low Sales</Col>
                                <Col lg="8">
                                  <span className="text-dark">
                                    {webtoon?.low_sales}
                                  </span>
                                </Col>
                              </Row>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                  )}

                {permissions &&
                    permissions.map((permission) =>
                      permission === "view-webtoon-medium-sale" ? (
                        <>
                          {webtoon?.medium_sales ? (
                            <>
                              <Row className="mt-1">
                                <Col lg="4">Medium Sales</Col>
                                <Col lg="8">
                                  <span className="text-dark">
                                    {webtoon?.medium_sales}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                       </>
                      ) : (
                        ""
                      )
                   )}

                {permissions &&
                    permissions.map((permission) =>
                      permission === "view-webtoon-high-sale" ? (
                        <>
                          {webtoon?.high_sales ? (
                            <>
                              <Row className="mt-1">
                                <Col lg="4">High Sales</Col>
                                <Col lg="8">
                                  <span className="text-dark">
                                    {webtoon?.high_sales}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )
                  )}

                {webtoon?.fr_consumer_price ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">FR Consumer Price</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon?.fr_consumer_price}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                {permissions &&
                      permissions.map((permission) =>
                        permission === "view-webtoon-product-pl" ? (
                          <>
                              {webtoon?.product_pl ? (
                                <>
                                <Row className="mt-1">
                                  <Col lg="4">Product P&L</Col>
                                  <Col lg="8">
                                      <span className="link primary-text-color text-wrap">
                                          <a
                                            href={webtoon?.product_pl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {webtoon?.product_pl?.length >
                                            50
                                              ? `${webtoon?.product_pl.slice(
                                                  0,
                                                  40
                                                )}...`
                                              : webtoon?.product_pl}
                                          </a>
                                      </span>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )
                )}



                  {webtoon?.created_at ? (
                    <>
                      <Row className="mt-1">
                        <Col lg="4">CreatedAt</Col>
                        <Col lg="8">
                          <span className="text-dark">
                            {webtoon.created_at
                              ? moment(webtoon.created_at).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg="2" className="columns"></Col>
            <Col lg="3">
              <Card className="text-right shadow m-4 view_cards">
                <CardBody>
                  {webtoon?.cover_image ? (
                    <>
                      <CardImg
                        src={webtoon?.cover_image}
                        width="180"
                        height="200"
                        alt="Upload"
                        className="overflow-hidden"
                        onClick={async () => {
                          setShowModal(true);
                          setImage(webtoon.cover_image);
                        }}
                      />
                    </>
                  ) : (
                    <CardImg
                      src={require("../../../assets/images/no-image.png")}
                      width="180"
                      height="200"
                      alt="Upload"
                      className=" overflow-hidden"
                      onClick={async () => {
                        setShowModal(true);
                        setImage(
                          require("../../../assets/images/no-image.png")
                        );
                      }}
                    />
                  )}
                </CardBody>
              </Card>
              <Card className="text-left shadow m-4 view_cards">
                <CardBody>
                   <h4>Ratings</h4>

                   <div className="d-inline-flex">
                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/1.jpg`} 
                              alt="rating-1" 
                              style={{ width: '30%' }} 
                              />
                            <span 
                              className={`m-1 ${webtoon?.rating_one_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(webtoon?.rating_one_counts !== "0"){
                                  setRatingType(1);
                                  setRatingInfo(webtoon);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                             > 
                             {webtoon?.rating_one_counts || 0}
                            </span>  
                        </div>

                        <div className="pe-auto">
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/2.jpg`} 
                              alt="rating-2" 
                              style={{ width: '30%' }} 
                            />
                            <span 
                            className={`m-1 ${webtoon?.rating_two_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                            onClick={()=>{
                              if(webtoon?.rating_two_counts !== "0"){
                                setRatingType(2);
                                setRatingInfo(webtoon);
                                setOpenUserRatingListModel(true);
                                }
                            }} 
                            >
                            {webtoon?.rating_two_counts || 0}
                           </span> 
                        </div>

                        <div>
                            <img 
                              src={`${process.env.PUBLIC_URL}/rating-images/3.jpg`} 
                              alt="rating-3" 
                              style={{ width: '27%'}} 
                            />
                             <span 
                              className={`m-2 ${webtoon?.rating_three_counts !== "0" ? 'primary-text-color cursor-pointer' :''}`}
                              onClick={()=>{
                                if(webtoon?.rating_three_counts !== "0"){
                                  setRatingType(3);
                                  setRatingInfo(webtoon);
                                  setOpenUserRatingListModel(true);
                                  }
                              }} 
                            >
                              {webtoon?.rating_three_counts || 0}
                            </span>  
                        </div>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {webtoon?.authors.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Authors</h3>
                    <Col>
                      {webtoon.authors.map((author, index) => (
                         <li
                         className="primary-text-color" style={{cursor: "pointer"}}
                         onClick={() =>
                           fetchAuthorsDetail(author.id)
                         }
                         key={index}
                       >
                         {author.name}
                       </li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {webtoon?.authors.length ? <Col lg="2"></Col> : ""}
            {webtoon?.genres.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Genre</h3>
                    <Col>
                      {webtoon.genres.map((genre, index) => (
                        <li key={index}>{genre.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {webtoon?.illustrators.length ? (
              <Col lg="4">
                <Card className=" shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Illustrator</h3>
                    <Col>
                      {webtoon.illustrators.map((illustrators, index) => (
                         <li className="primary-text-color" style={{cursor: "pointer"}}  onClick={() =>
                          fetchIllustratorDetail(
                            illustrators.id
                          )
                        } key={index}>{illustrators.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {webtoon?.illustrators.length ? <Col lg="2"></Col> : ""}

            {webtoon?.publics.length ? (
              <Col lg="4">
                <Card className="me-2 shadow m-4 view_cards">
                  <CardBody>
                    <h3 className="fw-bolder dot_border">Public</h3>
                    <Col>
                      {webtoon.publics.map((publicsD, index) => (
                        <li key={index}>{publicsD.name}</li>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col lg="12">
              <Card className="me-2 shadow m-4 ">
                <CardBody>
                  <h3 className="fw-bolder dot_border">Comments</h3>
                  {currentComment  || webtoon.all_comments ? (
                    <>
                      <Row>
                        {currentComment  ? (
                          <Col lg="3">
                            <Card className=" shadow mt-2 ">
                              <CardHeader className="h4">
                              {currentLoggedInUser.first_name} {currentLoggedInUser.last_name}
                              <RiDeleteBin6Line
                                  onClick={showDeleteConfirm}
                                  className="ml-7"
                                  style={{ cursor: "pointer" }}
                                  size={26}
                                  color="#e62a2e"
                                />
                              </CardHeader>
                              <CardBody>{currentComment}</CardBody>
                            </Card>
                          </Col>
                        ) : (
                          ""
                        )}
                        {webtoon?.all_comments?.length > 0
                          ? webtoon.all_comments.map((x) => (
                              <>
                                <Col lg="3">
                                  <Card className="shadow mt-2 ">
                                    <CardHeader className="h4">
                                      {x.first_name} {x.last_name}
                                    </CardHeader>
                                    <CardBody>{x.comment}</CardBody>
                                  </Card>
                                </Col>
                              </>
                            ))
                          : ""}
                      </Row>
                    </>
                  ) : (
                    "No Comments"
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageModal
            show={show}
            image={image}
            onClose={() => {
              setShowModal(false);
            }}
          />
          <UniverseModal openUniverseModel = {openUniverseModel} setOpenUniverseModel={setOpenUniverseModel} universeSummaryData = {universeSummaryData} currentWebtoonId = {webtoonId} isPageReload = {isPageReload.WEBTOON} isLinkFunctionalityActive={true}/>
          <AuthorModal openAuthorModel = {openAuthorModel} setOpenAuthorModel={setOpenAuthorModel} authorSummaryData={authorSummaryData } currentWebtoonId = {webtoonId} isPageReload = {isPageReload.WEBTOON} isLinkFunctionalityActive={true}/>
          <IllustratorModal openIllustratorModel = {openIllustratorModel} setOpenIllustratorModel={setOpenIllustratorModel} illustratorSummaryData={illustratorSummaryData} currentWebtoonId = {webtoonId} isPageReload = {isPageReload.WEBTOON} isLinkFunctionalityActive={true}/>
          <RatingUserListModal openUserRatingListModel={openUserRatingListModel} setOpenUserRatingListModel={setOpenUserRatingListModel} ratingInfo={ratingInfo} ratingType = {ratingType} title = "Webtoon Rating Details" />
        </Card>
      </Container>
    </>
  );
}

export default ViewWebtoon;
